import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'drive-motor',
  templateUrl: './drive-motor.component.html',
  styleUrls: ['./drive-motor.component.less'],
})
export class DriveMotorComponent implements OnInit, OnDestroy, OnChanges {
  qddjData: any = [
    [
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
    ],
  ];
  qddjHeadData: any = [
    { title: '状态' },
    { title: '转速(R/min)' },
    { title: '转矩' },
    { title: '温度(℃)' },
    { title: '控制器输入电压(V)' },
    { title: '控制器温度(℃)' },
    { title: '控制器直流母线电流(A)' },
  ];

  qddjData_1: any = [
    [
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
    ],
  ];
  qddjHeadData_1: any = [
    { title: '状态' },
    { title: '转速(R/min)' },
    { title: '转矩' },
    { title: '温度(℃)' },
  ];

  qddjData_2: any = [
    [
      { content: '' },
      { content: '' },
      { content: '' },
      { content: '' },
    ],
  ];
  qddjHeadData_2: any = [
    { title: '控制器输入电压(V)' },
    { title: '控制器温度(℃)' },
    { title: '控制器直流母线电流(A)' },
    { title: '' },
  ];

  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_DR_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    this.qddjData=[];
    this.qddjData_1=[];
    this.qddjData_2=[];
    for(let i=0;i<oriDate.length;i++){
      this.qddjData_1.push(
        [
          { content: convertDate(oriDate[i].MDT_DR_STA,'MDT_DR_STA',rule) },
          { content: convertDate(oriDate[i].MDT_DR_ROTATE_SPEED,'MDT_DR_ROTATE_SPEED',rule) },
          { content: convertDate(oriDate[i].MDT_DR_ROTATE_ROTQUE,'MDT_DR_ROTATE_ROTQUE',rule) },
          { content: convertDate(oriDate[i].MDT_DR_TEM,'MDT_DR_TEM',rule) },
        ]
      );
      this.qddjData_2.push(
        [
          { content: convertDate(oriDate[i].MDT_DR_CTRL_IN_VOL,'MDT_DR_CTRL_IN_VOL',rule) },
          { content: convertDate(oriDate[i].MDT_DR_CTRL_TEM,'MDT_DR_CTRL_TEM',rule) },
          { content: convertDate(oriDate[i].MDT_DR_CTRL_IN_CUR,'MDT_DR_CTRL_IN_CUR',rule) },
          { content: '' },
        ]
      );
    }
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.qddjData = [
          [
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
          ],
        ];
        this.qddjData_1 = [
          [
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
          ],
        ];
        this.qddjData_2 = [
          [
            { content: '' },
            { content: '' },
            { content: '' },
            { content: '' },
          ],
        ];
        return
      }
      let oriDate=this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }
}
