import { Injectable, Renderer2, ElementRef } from '@angular/core';
import { NzModalService,NzNotificationService ,NzMessageService } from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';

@Injectable()
export class NoticeService {

  constructor(
    private notification: NzNotificationService,
    private translate: TranslateService,
    private message: NzMessageService
  ) {}

  open(title,content){
    let t=this.translate.get(title);
    let c=this.translate.get(content);
    forkJoin(t,c).subscribe((res)=>{
      this.notification.config({
        nzPlacement: 'bottomRight'
      });
      this.notification.blank(res[0], res[1], {
        nzStyle: {
          width     : '300px',
          marginLeft: '100px',
          marginBottom:'10px'
        },
        nzClass: 'test-class'
      });
    });

  }

  info(content,type='warning', params = {}){
    this.translate.get(content, params).subscribe((res)=>{
      this.message.create(type,res);
    });
  }

}
