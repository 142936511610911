import { NgModule } from '@angular/core';

import { ModalComponent } from './modal.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ModalService } from './modal.service';
import {DialogModule} from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DialogModule,
    TranslateModule,
    NgZorroAntdModule,
  ],
  exports: [],
  declarations: [
    ModalComponent,
    ConfirmDialogComponent,
  ],
  providers : [
    { provide: ModalService, useClass: ModalService },
  ],
  entryComponents: [
    ModalComponent,
    ConfirmDialogComponent,
  ]
})
export class ModalModule {
  constructor(router: Router, modalService: ModalService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        modalService.closeAll();
      }
    });
  }
}
