import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBreadcrumbComponent } from './header-breadcrumb.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
  ],
  declarations: [HeaderBreadcrumbComponent],
  exports: [HeaderBreadcrumbComponent]
})
export class HeaderBreadcrumbModule { }
