import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfTableComponent } from './sf-table.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
  ],
  declarations: [SfTableComponent],
  exports: [SfTableComponent]
})
export class SfTableModule { }
