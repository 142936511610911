import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {BMonitoringTrackService, LoadingService, NoticeService, SharedService} from "@service";
import {formatDateTime} from "@shared/utils";

declare var AMap: any;

@Component({
  selector: 'track-replay',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.less']
})
export class TrackComponent implements OnInit {
  @Input() routerName = [];
  @Input() vin: any;
  public queryParam: any = {
    vin: '',
    startDate: '',
    endDate: '',
  };
  djdyRotate;
  djzsRotate;
  public routesData: any;
  public currentRoute: any;
  public vehicleStatus = {};
  public tabList = [];
  public lineArrays = [];
  public passedArrays = [];
  public queryList = [];
  //过滤无效点的数据
  public originalData = [];
  private moveIndex = 0;
  private endIndex = 0;
  private nativeMap: any;
  private carMarker: any;
  playState = false;
  public startPoint = [];
  public endPoint = [];
  public sliderValue = 0;
  public currentLocation: any = {};
  lineChart1: any = {};
  lineChart2: any = {};
  lineChart3: any = {};
  lineChart4: any = {};
  xAxis = [];
  speedList = [];
  pressureList = [];
  currentList = [];
  resistanceList = [];
  offset = new AMap.Pixel(-14, -26);
  showWin: boolean = false;
  playSpeed = 500;
  speedFlod = 1;
  showSpeed = false;
  warningList = [];
  dictionaryMap = {};
  detailInfo = {};

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private noticeService: NoticeService,
              private loadingService: LoadingService,
              private sharedService: SharedService,
              private bMonitoringTrackService: BMonitoringTrackService,) {

  }

  formatter(value: number): string {
    return `${value}%`;
  }

  showWindow() {
    this.showWin = true;
  }

  hideWindow() {
    this.showWin = false;
  }

  onMapReady(map) {
    this.nativeMap = map;
  }

  ngOnInit() {
    this.queryParam.vin = this.vin;
    this.initLineChart1();
    this.initLineChart2();
    this.initLineChart3();
    this.initLineChart4();
    this.sharedService.getOptionsByCodes(["MDT_O_DA_TYPE", "MDT_DR_DA_TYPE", "MDT_EN_DA_TYPE", "MDT_P_DA_TYPE", "MDT_A_DA_TYPE", "CM_A_DA", "MDT_PO_DA_TYPE", "MDT_BA_DA_TYPE", "MDT_FBA_DA_TYPE"]).subscribe(data => {
      this.dictionaryMap = data;
    });
  }

  onMarkerReady(map: any, marker: any) {
    this.carMarker = marker;
    map.setFitView();
  }

  //车速图表生成
  initLineChart1() {
    let data = {
      color: ['#FF4040'],
      gridTop: '30px',
      unit: '车速(km/h)',
      legend: {
        data: ['车速']
      },
      xAxisName: '时间',
      xAxis: this.xAxis,
      grid: {
        right: 80,
      },
      series: [
        {
          type: 'line',
          name: '车速',
          data: this.speedList
        },
      ]
    };
    this.lineChart1 = _.cloneDeep(data);
  }

  //电压图标生成
  initLineChart2() {
    let data = {
      color: ['#66FFFF'],
      gridTop: '30px',
      unit: '电压(V)',
      legend: {
        data: ['电压']
      },
      xAxisName: '时间',
      xAxis: this.xAxis,
      grid: {
        right: 80,
      },
      series: [
        {
          type: 'line',
          name: '电压',
          data: this.pressureList
        },
      ]
    };
    this.lineChart2 = _.cloneDeep(data);
  }

  //电流图标生成
  initLineChart3() {
    let data = {
      color: ['#9966FF'],
      gridTop: '30px',
      unit: '电流(A)',
      legend: {
        data: ['电流']
      },
      xAxisName: '时间',
      xAxis: this.xAxis,
      grid: {
        right: 80,
      },
      series: [
        {
          type: 'line',
          name: '电流',
          data: this.currentList
        },
      ]
    };
    this.lineChart3 = _.cloneDeep(data);
  }

  //电阻图标生成
  initLineChart4() {
    let data = {
      color: ['#FF799C'],
      gridTop: '30px',
      unit: '电阻(KΩ)',
      legend: {
        data: ['电阻']
      },
      xAxisName: '时间',
      xAxis: this.xAxis,
      grid: {
        right: 80,
      },
      series: [
        {
          type: 'line',
          name: '电阻',
          data: this.resistanceList
        },
      ]
    };
    this.lineChart4 = _.cloneDeep(data);
  }

  //查询分段轨迹数据并展示
  query() {
    if (this.queryParam.startDate == null || this.queryParam.startDate == undefined || this.queryParam.startDate == '') {
      this.noticeService.info('start_can_not_be_empty', 'success');
      return;
    }
    if (this.queryParam.endDate == null || this.queryParam.endDate == undefined || this.queryParam.endDate == '') {
      this.noticeService.info('end_can_not_be_empty', 'success');
      return;
    }
    if (new Date(this.queryParam.startDate).getTime() >= new Date(this.queryParam.endDate).getTime()) {
      this.noticeService.info('start_can_not_greater_then_end', 'success');
      return;
    }
    const tmp = _.cloneDeep(this.queryParam);
    tmp.startDate = new Date(tmp.startDate).getTime();
    tmp.endDate = new Date(tmp.endDate).getTime();
    this.routesData = [];
    this.currentRoute = null;
    this.loadingService.show();
    this.initData();
    this.bMonitoringTrackService.analyzeRoute(this.queryParam.vin, tmp).subscribe({
      next: result => {
        for (let i = 0; i < result.length; i++) {
          result[i].timeDuration = this.parseTime(result[i].timeDuration);
        }
        this.routesData = result;
        this.changeDetectorRef.markForCheck();
        this.loadingService.hide();
        //查询时打开信息窗体
        this.showWindow();
      },
      error: err => {
        this.loadingService.hide();
      }
    });
  }

  //点到点移动完成数据
  onMoveEnd() {
    this.moveIndex = this.endIndex;
    this.moveTo();
  }

  //开始播放数据
  play() {
    if (this.lineArrays.length == 0) {
      return;
    }
    if (this.endIndex == this.lineArrays.length - 1) {
      let startPosition = new AMap.LngLat(this.lineArrays[0].lng, this.lineArrays[0].lat)
      this.carMarker.setPosition(startPosition);
      this.nativeMap.setCenter(startPosition);
      this.moveIndex = 0;
      this.sliderValue = 0;
      this.passedArrays = [];
      this.generatePointData(0);
    }
    this.playState = !this.playState;
    this.currentLocation = {};
    this.moveTo();
  }

  //前进
  playPro() {
    if (this.lineArrays.length == 0 || this.moveIndex == this.lineArrays.length - 1) {
      return;
    }
    this.carMarker.stopMove();
    this.playState = false;
    this.moveIndex++;
    this.passedArrays = [];
    for (let i = 0; i <= this.moveIndex; i++) {
      this.passedArrays.push(this.lineArrays[i]);
    }
    let currentPosition = this.lineArrays[this.moveIndex];
    this.carMarker.setPosition(currentPosition);
    this.nativeMap.setCenter(currentPosition);
    this.sliderValue = Math.round(this.moveIndex / (this.lineArrays.length-1) * 100);
    this.generatePointData(this.moveIndex);
  }

  //上一个行程
  playPreRoute() {
    if (this.routesData.length == 0 || this.currentRoute == null) {
      return;
    }
    this.currentRoute--;
    if (this.currentRoute < 0) {
      this.currentRoute = this.currentRoute + this.routesData.length
    }
    this.queryHBase(this.currentRoute, this.routesData[this.currentRoute].beginTime, this.routesData[this.currentRoute].endTime);
  }

  //下一个行程
  playProRoute() {
    if (this.routesData.length == 0 || this.currentRoute == null) {
      return;
    }
    this.currentRoute++;
    if (this.currentRoute >= this.routesData.length) {
      this.currentRoute = this.currentRoute - this.routesData.length;
    }
    this.queryHBase(this.currentRoute, this.routesData[this.currentRoute].beginTime, this.routesData[this.currentRoute].endTime);
  }

  //后退
  playPre() {
    if (this.lineArrays.length == 0 || this.moveIndex == 0) {
      return;
    }
    this.carMarker.stopMove();
    this.playState = false;
    this.moveIndex--;
    this.passedArrays = [];
    for (let i = 0; i <= this.moveIndex; i++) {
      this.passedArrays.push(this.lineArrays[i]);
    }
    let currentPosition = this.lineArrays[this.moveIndex];
    this.carMarker.setPosition(currentPosition);
    this.nativeMap.setCenter(currentPosition);
    this.sliderValue = Math.round(this.moveIndex / (this.lineArrays.length-1) * 100);
    this.generatePointData(this.moveIndex);
  }

  //暂停播放数据
  pause() {
    this.playState = !this.playState;
    this.carMarker.stopMove();
  }

  //点到点之间移动事件
  moveTo() {
    if (this.moveIndex < this.lineArrays.length - 1) {
      let currentPosition = this.lineArrays[this.moveIndex];
      this.nativeMap.setCenter(currentPosition);
      // 移动到下一坐标
      this.endIndex = this.moveIndex + 1;
      let nextPosition = this.lineArrays[this.endIndex];
      while (new AMap.LngLat(nextPosition.lng, nextPosition.lat).distance([currentPosition.lng, currentPosition.lat]) < 1) {
        this.sliderValue = Math.round(this.endIndex/ (this.lineArrays.length - 1) * 100);
        if (this.endIndex == this.lineArrays.length - 1) {
          this.playState = !this.playState;
          this.generatePointData(this.endIndex);
          return;
        }
        this.endIndex++;
        nextPosition = this.lineArrays[this.endIndex];
      }
      this.carMarker.moveTo(nextPosition, this.playSpeed * this.speedFlod);
      this.sliderValue = Math.round(this.endIndex / (this.lineArrays.length-1) * 100);
      this.generatePointData(this.moveIndex);
    } else {
      this.playState = !this.playState;
      this.sliderValue = Math.round(this.moveIndex / (this.lineArrays.length-1) * 100);
      this.generatePointData(this.moveIndex);
    }
  }

  //移动过程中已过点数据生成
  onMove(marker) {
    if (this.playState) {
      this.passedArrays = this.passedArrays.concat(marker.passedPath);
    }
  }

  //播放进度条移动事件
  sliderChange(value) {
    this.carMarker.stopMove();
    this.playState = false;
    this.moveIndex = Math.floor(value / 100 * this.lineArrays.length);
    this.passedArrays = [];
    for (let i = 0; i <= this.moveIndex; i++) {
      this.passedArrays.push(this.lineArrays[i]);
    }
    let currentPosition = this.lineArrays[this.moveIndex];
    this.carMarker.setPosition(currentPosition);
    this.nativeMap.setCenter(currentPosition);
    this.generatePointData(this.moveIndex);
  }

  queryHBase(index, startDate, endDate) {
    if (this.carMarker) {
      this.carMarker.stopMove();
    }
    this.currentRoute = index;
    this.initData();
    this.sharedService.getHistoricalConditions(this.vin, {startDate: startDate, endDate: endDate}).subscribe(
      (data) => {
        if (data.records.length == 0) {
          this.noticeService.info('none_data', 'success');
          return;
        }
        this.queryList = data.records.reverse();
        this.queryList.forEach((record) => {
          if (record && record.body && record.body != null && record.body != "") {
            const location = JSON.parse(record.body).MDT_PO_DA_TYPE;
            const basic = JSON.parse(record.body).MDT_O_DA_TYPE;
            if (location && location.MDT_PO_OFF_LON != undefined && location.MDT_PO_OFF_LON != null && location.MDT_PO_OFF_LON != ''
              && location.MDT_PO_OFF_LAT != undefined && location.MDT_PO_OFF_LAT != null && location.MDT_PO_OFF_LAT != ''
              && location.MDT_PO_OFF_LON != 0 && location.MDT_PO_OFF_LAT != 0) {
              this.originalData.push(JSON.parse(record.body));
              this.lineArrays.push([location.MDT_PO_OFF_LON, location.MDT_PO_OFF_LAT]);
              if (basic) {
                this.speedList.push(basic.MDT_O_SPEED);
                this.pressureList.push(basic.MDT_O_TOTAL_VOL);
                this.currentList.push(basic.MDT_O_TOTAL_CUR);
                this.resistanceList.push(basic.MDT_O_RES);
              }
              this.xAxis.push(formatDateTime(JSON.parse(record.body).TIME));
            }
          }
        });
        if (this.queryList.length > 0) {
          this.lineArrays = _.cloneDeep(this.lineArrays);
          this.nativeMap.setCenter(this.lineArrays[0]);
          this.startPoint = [this.lineArrays[0][0], this.lineArrays[0][1]];
          this.endPoint = [this.lineArrays[this.lineArrays.length - 1][0], this.lineArrays[this.lineArrays.length - 1][1]];
          this.initLineChart1();
          this.initLineChart2();
          this.initLineChart3();
          this.initLineChart4();
          this.generatePointData(0);
        }

      }
    )
  }


  generatePointData(index) {
    const currentPoint = this.originalData[index];
    const startTime = this.originalData[0]["TIME"];
    const driveTime = parseInt(currentPoint["TIME"]) - parseInt(startTime);
    const startMile = this.originalData[0]["MDT_O_DA_TYPE"]["MDT_O_MILEAGE"];
    const driveMile = (parseFloat(currentPoint["MDT_O_DA_TYPE"]["MDT_O_MILEAGE"]) - parseFloat(startMile)).toFixed(1);
    const speedRotate = -45 + (currentPoint["MDT_O_DA_TYPE"]["MDT_O_SPEED"] / 160 * 226);
    const socRotate = -46 + (currentPoint["MDT_O_DA_TYPE"]["MDT_O_SOC"] / 100 * 226);
    this.djdyRotate = `rotate(${speedRotate}deg)`;
    this.djzsRotate = `rotate(${socRotate}deg)`;
    let warningLevel = "";
    let highest_temperature = "";
    let lowest_temperature = "";
    let highest_pressure = "";
    let lowest_pressure = "";
    if (currentPoint["MDT_A_DA_TYPE"]) {
      warningLevel = currentPoint["MDT_A_DA_TYPE"]["MDT_A_MAX_ALARM_LEV"];
    }
    if (currentPoint["MDT_P_DA_TYPE"]) {
      highest_temperature = currentPoint["MDT_P_DA_TYPE"]["MDT_P_MAX_TEM"] + "°C";
      lowest_temperature = currentPoint["MDT_P_DA_TYPE"]["MDT_P_MIN_TEM"] + "°C";
      highest_pressure = currentPoint["MDT_P_DA_TYPE"]["MDT_P_MAX_VOL_CEL_VLU"] + "V";
      lowest_pressure = currentPoint["MDT_P_DA_TYPE"]["MDT_P_MIN_VOL_CEL_VLU"] + "V";
    }
    //当前点车辆状态数据
    this.vehicleStatus = {
      highest_temperature: highest_temperature,
      lowest_temperature: lowest_temperature,
      highest_pressure: highest_pressure,
      lowest_pressure: lowest_pressure,
      pressure: currentPoint["MDT_O_DA_TYPE"]["MDT_O_TOTAL_VOL"] + "V",
      current: currentPoint["MDT_O_DA_TYPE"]["MDT_O_TOTAL_CUR"] + "A",
      totalMiles: currentPoint["MDT_O_DA_TYPE"]["MDT_O_MILEAGE"],
      speed: (currentPoint["MDT_O_DA_TYPE"]["MDT_O_SPEED"] / 10).toFixed(2),
      soc: currentPoint["MDT_O_DA_TYPE"]["MDT_O_SOC"] + "%",
      currentTime: currentPoint["TIME"],
      driveMile: driveMile,
      driveTime: this.parseTime(driveTime),
      warningLevel: warningLevel,
    }

    //region 当前点报警信息
    this.warningList = [];
    const warningContent = currentPoint["CM_A_DA"];
    const warningMap = this.dictionaryMap["CM_A_DA"];
    if (warningContent) {
      for (let i = 0; i < warningMap.length; i++) {
        for (let key in warningContent) {
          if (key == warningMap[i].value) {
            if (warningContent[key] == 1) {
              this.warningList.push(warningMap[i].label);
            }
            break;
          }
        }
      }
    }
    //endregion

    //region 当前点详细数据
    this.detailInfo = {};
    for (let key in currentPoint) {
      const content = currentPoint[key];
      const map = this.dictionaryMap[key];
      if (map && content) {
        let contentList = [];
        if (key == "MDT_DR_DA_TYPE" || key == "MDT_BA_DA_TYPE") {
          for (let j = 0; j < content.length; j++) {
            let list = [];
            for (let i = 0; i < map.length; i++) {
              for (let contentKey in content[j]) {
                if (contentKey == map[i].value) {
                  list.push({
                    title: this.convertTitle(map[i].label, map[i].unit),
                    content: this.convertContent(content[j][contentKey], map[i].values, map[i].caculateScript)
                  });
                  break;
                }
              }
            }
            contentList.push(list);
          }
        } else {
          for (let i = 0; i < map.length; i++) {
            for (let contentKey in content) {
              if (contentKey == map[i].value) {
                contentList.push({
                  title: this.convertTitle(map[i].label, map[i].unit),
                  content: this.convertContent(content[contentKey], map[i].values, map[i].caculateScript)
                });
                break;
              }
            }
          }
        }
        this.detailInfo[key] = contentList;
      }
    }
    //endregion
    this.changeDetectorRef.markForCheck();
  }

  //HBase查询后画面变量初始化
  initData() {
    this.djdyRotate = `rotate(-45deg)`;
    this.djzsRotate = `rotate(-46deg)`;
    this.lineArrays = [];
    this.originalData = [];
    this.xAxis = [];
    this.speedList = [];
    this.pressureList = [];
    this.currentList = [];
    this.resistanceList = [];
    this.vehicleStatus = {};
    this.tabList = [];
    this.passedArrays = [];
    this.queryList = [];
    this.moveIndex = 0;
    this.playState = false;
    this.startPoint = [];
    this.endPoint = [];
    this.sliderValue = 0;
    this.currentLocation = {};
    this.warningList = [];
    this.initLineChart1();
    this.initLineChart2();
    this.initLineChart3();
    this.initLineChart4();
    this.detailInfo = {};

  }

  //毫秒数转时分秒
  parseTime(times) {
    if (times < 0) {
      return "00:00:00"
    }
    let parsedTime = "";
    let temp = times;
    const hourTime = 60 * 60 * 1000;
    const minuteTime = 60 * 1000;
    const secondTime = 1000;
    if (Math.floor(temp / hourTime) >= 10) {
      parsedTime = parsedTime + Math.floor(temp / hourTime) + ":";
    } else {
      parsedTime = parsedTime + "0" + Math.floor(temp / hourTime) + ":";
    }
    temp = temp % hourTime;
    if (Math.floor(temp / minuteTime) > 10) {
      parsedTime = parsedTime + Math.floor(temp / minuteTime) + ":";
    } else {
      parsedTime = parsedTime + "0" + Math.floor(temp / minuteTime) + ":";
    }
    temp = temp % minuteTime;
    if (Math.floor(temp / secondTime) > 10) {
      parsedTime = parsedTime + Math.floor(temp / secondTime);
    } else {
      parsedTime = parsedTime + "0" + Math.floor(temp / secondTime);
    }
    return parsedTime;
  }

  convertTitle(value, unit) {
    if (unit == null || unit == "") {
      return value;
    } else {
      return value + "(" + unit + ")";
    }
  }

  convertContent(value, values, calScript) {
    if (values == 'VALUE') {
      return value;
    }
    if (values == 'OPTIONS') {
      try {
        let obj = JSON.parse(calScript);
        for (let key in obj) {
          if (key == value) {
            return obj[key];
          }
        }
      } catch (e) {
      }
      return "";
    }

  }

  //重置按钮点击
  reset() {
    this.queryParam.startDate = "";
    this.queryParam.endDate = "";
  }
}
