import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DomResizeEventService {
    resizeEvent$ = new BehaviorSubject<any>('');

    trigger() {
        this.resizeEvent$.next('true');
    }
}