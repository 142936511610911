import {Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-bmap',
  templateUrl: './bmap.component.html',
  styleUrls: ['./bmap.component.less']
})
export class BmapComponent implements OnInit, OnChanges {

  @Input() series: any = [];
  @Input() mapType: any = '';
  @Input() selectMarker: any;
  @Output() changeTwo = new EventEmitter();
  @Input() height?: string;
  option: any;
  isShowbaidumap: boolean = false;

  lng: number = 118.76192;
  lat: number = 32.022335;

  constructor() {
  }

  ngOnInit() {
    this.initBmap();
  }

  ngOnChanges() {
  }

  initBmap() {
    let opt = {
      tooltip: {
        trigger: 'item',
        formatter: (value) => {
          if (value.value && value.value.length > 2) {
            return value.name + ' : ' + value.value[2];
          } else {
            return value.name;
          }
          ;
        }
      },
      bmap: {
        center: [120.114129, 37.550339],
        zoom: 5,
        roam: true,
        mapStyle: {
          styleJson: [
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": {
                "color": "#000007ff"
              }
            },
            {
              "featureType": "highway",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#0d3759"
              }
            },
            {
              "featureType": "highway",
              "elementType": "geometry.stroke",
              "stylers": {
                "color": "#000007ff"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "all",
              "stylers": {
                "color": "#000007ff"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#1c2f73ff"
              }
            },
            {
              "featureType": "local",
              "elementType": "all",
              "stylers": {
                "color": "#001255ff"
              }
            },
            {
              "featureType": "land",
              "elementType": "all",
              "stylers": {
                "color": "#051f6aff"
              }
            },
            {
              "featureType": "railway",
              "elementType": "all",
              "stylers": {
                "color": "#00001bff"
              }
            },
            {
              "featureType": "railway",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#00001bff"
              }
            },
            {
              "featureType": "subway",
              "elementType": "geometry",
              "stylers": {
                "color": "#371359ff"
              }
            },
            {
              "featureType": "building",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#051f6aff"
              }
            },
            {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#ffffff"
              }
            },
            {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": {
                "color": "#0c3557"
              }
            },
            {
              "featureType": "building",
              "elementType": "geometry",
              "stylers": {
                "color": "#0b3150"
              }
            },
            {
              "featureType": "green",
              "elementType": "geometry",
              "stylers": {
                "color": "#051f6aff"
              }
            },
            {
              "featureType": "boundary",
              "elementType": "all",
              "stylers": {
                "color": "#2b488eff"
              }
            },
            {
              "featureType": "manmade",
              "elementType": "all",
              "stylers": {
                "color": "#051f6aff"
              }
            },
            {
              "featureType": "district",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "town",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "city",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "medicallabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#21559fff"
              }
            },
            {
              "featureType": "continent",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "country",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "airportlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#21559fff"
              }
            },
            {
              "featureType": "scenicspotslabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#3279ddff"
              }
            },
            {
              "featureType": "educationlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#21559fff"
              }
            },
            {
              "featureType": "building",
              "elementType": "all",
              "stylers": {
                "color": "#061251ff"
              }
            },
            {
              "featureType": "education",
              "elementType": "all",
              "stylers": {
                "color": "#061251ff"
              }
            },
            {
              "featureType": "building",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#061251ff"
              }
            },
            {
              "featureType": "scenicspots",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "educationlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "airportlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "scenicspotslabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "medicallabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "highway",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "railway",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "local",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "education",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "medical",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "scenicspots",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "subway",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#294995ff"
              }
            },
            {
              "featureType": "building",
              "elementType": "all",
              "stylers": {}
            },
            {
              "featureType": "poilabel",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#2b488eff",
                "lightness": 49
              }
            },
            {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#2b488eff",
                "lightness": 49
              }
            },
            {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": {
                "color": "#2b488eff",
                "lightness": 49
              }
            },
            {
              "featureType": "poilabel",
              "elementType": "labels.icon",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": {
                "visibility": "off"
              }
            }
          ]
        }
      },
      series: this.series.data
    };

    this.option = opt;
  }


  // 点击CHINA切换百度地图
  onChartClick(event) {
    if (event.name == "CHINA") {
      // alert("this is china");
      this.isShowbaidumap = true;
    }
  }

  showchart(event) {
    this.isShowbaidumap = event;
  }

  changeComponentTwo(event) {
    this.changeTwo.emit(event);
  }
}
