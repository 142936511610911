import { Component, OnInit, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-table-sheet',
  templateUrl: './table-sheet.component.html',
  styleUrls: ['./table-sheet.component.scss'],
})

export class TableSheetComponent implements OnInit {
  @Input() data?: any[] = [];
  @Input() inset: any[] = [];
  @Input() theadData?: any[] = [];
  @Input() set columnNum(num) {
    if (num == 0) {
      this._colStyle = 'table-width-all';
      return;
    }
    this._colStyle = `table-${num}`
  }
  _colStyle = 'table-6';
  constructor(
  ) { }


  ngOnInit() {
  }



}
