import { Injectable, Inject, Injector } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    ActivatedRouteSnapshot,
    Route,
    RouterStateSnapshot,
    Router,
  } from '@angular/router';
import { ACLService } from '@delon/acl';

/**
 * 判断用户是否可以访问对应的菜单
 */
@Injectable({
  providedIn: 'root',
})
export class MenuGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private injector: Injector,
  ) {
  }

  private process(value): boolean {
      //从路由上获取data里面的信息
      if(value) {
        const aclService = this.injector.get(ACLService);
        if(aclService.canAbility(value)) return true;
        this.goTo403();
        return false;
      }
      return true;
  }
  
  private goTo403() {
    setTimeout(() => this.injector.get(Router).navigateByUrl("exception/403"));
  }

  // lazy loading
  canLoad(route: Route): boolean {
    return this.process((route.data && route.data.guard) || null);
  }
  // all children route
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
  // route
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.process((route.data && route.data.guard) || null);
  }
}
