import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';


@Injectable()
export class BMonitoringTrackService extends BaseService {

  moduleName = this.baseUrl + '/vehicle';

  constructor(private _http: _HttpClient) {
    super();
  }


  analyzeRoute(vin,params): Observable<any> {
    return this._http.get(`${this.moduleName}/analyzeRoute/${vin}`, params);
  }

}
