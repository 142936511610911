import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable()
export class ChargingConnectorService extends BaseService {

  moduleName = this.baseCharging + `/connectors`

  constructor(private _http: _HttpClient) {
    super();
  }

  getRealTimeList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/realtime`, query, { observe: "response" });
  }

  getHistoryList(query): Observable<any> {
    return this._http.get(`${this.moduleName}/history`, query, { observe: "response" });
  }

  getList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, { observe: "response" });
  }

  getConnectorOptions() {
    return this._http.get(`${this.moduleName}/names`);
  }
}
