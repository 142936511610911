import { Injectable ,OnInit} from '@angular/core';
import { Subject} from 'rxjs';

@Injectable()
export class LoadingService{
  loading$ = new Subject<any>();
  constructor(
  ) {
  }

  show() {
    this.loading$.next(true);
  }

  hide() {
    this.loading$.next(false);
  }
}
