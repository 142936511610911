import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges, forwardRef, ViewChild,ElementRef} from '@angular/core';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'sf-form-item',
  templateUrl: './sf-form-item.component.html',
  styleUrls: ['./sf-form-item.component.less']
})
export class SfFormItemComponent implements OnInit  {
  @Input() nzSm = 24;
  @Input() nzCol = 24;
  @Input() paddingRight = 0;
  @Input() rowPaddingRight = 7;
  constructor(
    private http: _HttpClient,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    let el=this.elementRef.nativeElement;
    el.classList.add(`ant-col-${this.nzSm}`);
    el.style.paddingRight=this.paddingRight+'px';
  }



}
