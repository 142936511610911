import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable()
export class ChargingOperatorService extends BaseService {

  moduleName = this.baseCharging + `/operators`

  constructor(private _http: _HttpClient) {
    super();
  }

  getList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, { observe: "response" });
  }

  getOperatorOptions() {
    return this._http.get(`${this.moduleName}/names`);
  }

  // 查充电站，充电桩，充电枪下拉
  getOptionsInfo(query): Observable<any> {
    return this._http.post(`${this.moduleName}/querySelectOptions`, query);
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/query/${id}`)
  }

  create(obj): Observable<any> {
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj): Observable<any> {
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  downloadExcel(ids) {
    return this._http.post(`${this.moduleName}/exportKey`, ids, {}, { responseType: "blob" });
  }

}
