import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';

@Injectable()
export class RemoteControlService extends BaseService {

  constructor(private _http: _HttpClient) {
    super();
  }

  // 命令-下发
  createInstructionLog(context): Observable<any> {
    return this._http.post(this.baseUrl + `/instructions/commands`, context, { observe: 'response' });
  }

  // 命令-获取结果
  checkCommand(checkId): Observable<any> {
    return this._http.get(this.baseUrl + `/instructions/${checkId}`);
  }

  /**
  * 车辆型号
  */
  getVehicleModelList(): Observable<any> {
    return this._http.get(this.baseUrl + `/vehicles/model/dropDownListInfo`);
  }

  // 任务名称-下拉框
  getTaskNameList(): Observable<any> {
    return this._http.get(this.baseUrl + `/instructions/updateTaskList`);
  }

  getTerminalModel(packageId): Observable<any> {
    return this._http.get(this.baseUrl + `/updatePackage/terminalModel/${packageId}`);
  }

  // 升级包版本-下拉框
  queryPackageListList(): Observable<any> {
    return this._http.get(this.baseUrl + `/instructions/updatePackageList`);
  }

  // 指令下发页面（升级包版本）-下拉框
  queryUpgradeVersionByVin(vin): Observable<any> {
    return this._http.get(this.baseUrl + `/instructions/upgradeVersionList/${vin}`);
  }

  // 升级包管理-分页
  pageQueryPackage(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/updatePackage`, query, { observe: 'response' });
  }

  getPackage(id): Observable<any> {
    return this._http.get(this.baseUrl + `/updatePackage/${id}`)
  }

  // 升级包管理-新增
  addPackageInfo(context): Observable<any> {
    return this._http.post(this.baseUrl + `/updatePackage`, context, { observe: 'response' });
  }

  // 升级包管理-修改
  editPackageInfo(context): Observable<any> {
    return this._http.put(this.baseUrl + `/updatePackage/${context.id}`, context, { observe: 'response' });
  }

  // 升级包管理-删除
  deletePackageInfo(ids): Observable<any> {
    return this._http.delete(this.baseUrl + `/updatePackage`, { 'ids': ids });
  }

  // 升级任务管理-分页
  pageQueryTask(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/updateTask`, query, { observe: 'response' });
  }

  // 升级任务管理-新增
  addTaskInfo(context): Observable<any> {
    return this._http.post(this.baseUrl + `/updateTask`, context, { observe: 'response' });
  }

  // 升级任务管理-修改
  editTaskInfo(context): Observable<any> {
    return this._http.put(this.baseUrl + `/updateTask/${context.id}`, context, { observe: 'response' });
  }

  // 升级任务管理-删除
  deleteTaskInfo(ids): Observable<any> {
    return this._http.delete(this.baseUrl + `/updateTask`, { 'ids': ids });
  }
  // 升级任务获取单个任务接口
  getTask(id): Observable<any> {
    return this._http.get(this.baseUrl + `/updateTask/${id}`);
  }
  // 升级任务获取任务所属的车辆vin码集合
  getVinListByTask(id): Observable<any> {
    return this._http.get(this.baseUrl + `/updateTask/vinList/${id}`);
  }
  // 升级任务管理-删除
  taskStart(id): Observable<any> {
    return this._http.put(this.baseUrl + `/updateTask/run/${id}`);
  }
  // 升级日志管理-分页
  pageQueryHistory(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/updateHistory`, query, { observe: 'response' });
  }

}
