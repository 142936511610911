import { BaseService } from '@service/base.service';
import { _HttpClient } from '@delon/theme';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { parseExportStr } from '@shared/utils';
import { environment } from '@env/environment';

@Injectable()
export class ForwardService extends BaseService {

  forwardConfig = this.baseForward + '/forwardConfig';
  protocols = this.baseForward + '/protocols';
  jobs = this.baseForward + '/jobs';
  platformCheck = this.baseForward + '/platformCheck';
  forwardRecord = this.baseForward + '/forwardRecord';
  exportTask = this.baseForward + '/exportTask';

  constructor(private _http: _HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService) {
    super();
  }
  /*****************************************转发配置***************************************************/
  pageQuery(query, pageIndex, pageSize): Observable<HttpResponse<any>> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get<any>(this.forwardConfig, query, { observe: 'response' });
  }

  create(data): Observable<any> {
    return this._http.post(this.forwardConfig, data);
  }

  get(id): Observable<any> {
    return this._http.get(`${this.forwardConfig}/${id}`);
  }

  update(data, id): Observable<any> {
    return this._http.put(`${this.forwardConfig}/${id}`, data);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.forwardConfig}`, { ids: ids });
  }

  userList(id): Observable<any> {
    return this._http.get(`${this.forwardConfig}/${id}/users`);
  }

  paramList(id): Observable<any> {
    return this._http.get(`${this.forwardConfig}/${id}/params`);
  }

  run(id): Observable<any> {
    return this._http.get(`${this.jobs}/run/${id}`);
  }

  stop(id): Observable<any> {
    return this._http.get(`${this.jobs}/stop/${id}`);
  }

  queryTaskStatus(id): Observable<any> {
    return this._http.get(`${this.forwardRecord}/task/status/${id}`);
  }

  /**查询映射模板集合 */
  getRelationTemplates(): Observable<any> {
    return this._http.get(`${this.baseForward}/relations`);
  }
  /****查询未绑定到任务的车辆*****/
  pageQueryUnbindVehicles(query, pageIndex, pageSize): Observable<HttpResponse<any>> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get<any>(`${this.jobs}/vehicles/unbound/` + query.id, query, { observe: 'response' });
  }

  /****查询绑定到任务的车辆*****/
  pageQueryBoundVehicles(query, pageIndex, pageSize): Observable<HttpResponse<any>> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get<any>(`${this.jobs}/vehicles/bound/` + query.id, query, { observe: 'response' });
  }

  /**
   * 关联车辆
   */
  bindVehicle(taskId, vehicleIdArr): Observable<any> {
    return this._http.post(`${this.jobs}/bind/${taskId}`, vehicleIdArr);
  }

  /**
   * 取消关联车辆
   */
  unbindVehicle(taskId, vehicleArr): Observable<any> {
    return this._http.post(`${this.jobs}/unbind/${taskId}`, vehicleArr);
  }

  queryJobs(taskId: any): Observable<any> {
    return this._http.get(`${this.jobs}/${taskId}/list`);
  }


  /*************************************平台符合性型检测***********************************************/
  start(data): Observable<any> {
    return this._http.post(`${this.platformCheck}/start`, data);
  }

  lastResult(): Observable<any> {
    return this._http.get(`${this.platformCheck}/last/result`);
  }

  queryAllTasks(): Observable<any> {
    return this._http.get(`${this.platformCheck}/list`);
  }

  querySteps(id): Observable<any> {
    return this._http.get(`${this.platformCheck}/task/${id}`);
  }

  /*************************************转发记录***********************************************/
  recordList(obj): Observable<any> {
    return this._http.get(`${this.forwardRecord}`, obj);
  }

  queryVehicles(taskId, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.forwardRecord}/${taskId}/vehicles`, query, { observe: 'response' });
  }

  queryFailRangeData(taskId, obj): Observable<any> {
    return this._http.get(`${this.forwardRecord}/failed/${taskId}/${obj.vin}`, obj);
  }

  exportVehiceFailReport(taskId, obj) {
    let url = `${environment.SERVER_URL}${this.forwardRecord}/export/failed/${taskId}/${obj.vin}`;
    url += "?token=Bearer " + this.tokenService.get().token;
    url += '&' + parseExportStr(obj);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  /*************************************转发记录===>导出报文****************************************/
  exportTaskList(): Observable<any> {
    return this._http.get<any>(this.exportTask);
  }

  createExportTask(data): Observable<any> {
    return this._http.post(this.exportTask, data);
  }

  getExportTask(id): Observable<any> {
    return this._http.get(`${this.exportTask}/${id}`);
  }

  updateExportTask(id, data): Observable<any> {
    return this._http.put(`${this.exportTask}/${id}`, data);
  }

  deleteExportTask(id): Observable<any> {
    return this._http.delete(`${this.exportTask}/${id}`);
  }

  exportExportTask(id) {
    let url = `${environment.SERVER_URL}${this.exportTask}/download/${id}`;
    url += "?token=Bearer " + this.tokenService.get().token;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
