import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';
import {HttpParams} from "@angular/common/http";

@Injectable()
export class AlarmInfoService extends BaseService {

  constructor(private _http: _HttpClient) {
    super();
  }

  // 实时报警-分页
  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/alarm/realTime`, query, {observe: 'response'});
  }

  // 报警类型
  getAlarmTypeList(): Observable<any> {
    return this.getDictionaryChild('ALARM_LEVEL');
  }

  // 北汽常州根据故障等级查询 报警项
  alarmItemCZBQ(code): Observable<any> {
    return this._http.get(this.baseUrl+`/alarm/realTime/alarmItemCZBQ/${code}`);
  }

  getDictionaryChild(code): Observable<any> {
    return this._http.get(this.baseUrl + `/dictionary/child/${code}`);
  }

  // 报警项
  getAlarmItemByType(id): Observable<any> {
    return this._http.get(this.baseUrl + `/alarm/realTime/alarmItemInfo/${id}`);
  }

  // 车辆分组
  queryAllGroupList(): Observable<any> {
    return this._http.get(this.baseUrl + `/vehicles/group/dropDownListInfo`); // ${id}
  }

  // 历史报警-分页
  pageQueryHisAlarm(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/alarm/history`, query, {observe: 'response'});
  }

  // 历史报警-数据
  getAlarmHistory(queryHisData): Observable<any> {
    return this._http.get(this.baseUrl + `/vehicle/historicalConditions`, queryHisData, {observe: 'response'});
  }

  // 自定义策略-列表
  pageQueryCustomize(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/alarm/policy`, query, {observe: 'response'});
  }

  // 自定义策略-启用/停用
  enablePolicy(id, status): Observable<any> {
    return this._http.get(this.baseUrl + `/alarm/policy/enable/${id}?enableFlag=${status}`);
  }

  // 自定义策略-获取详情
  getPolicyInfo(id): Observable<any> {
    return this._http.get(this.baseUrl + `/alarm/policy/${id}`);
  }

  // 自定义策略-报警项列表
  pageQueryAlarmItem(allData, pageIndex, pageSize): Observable<any> {
    const resultData: any = [];
    const firstIdx = (pageIndex - 1) * pageSize;
    if (allData.length > 0) {
      for (let i = firstIdx; i < allData.length; i++) {
        if (i - firstIdx > pageSize) {
          break;
        }
        resultData.push(allData[i]);
      }
    }
    return resultData;
  }

  // 自定义策略-新增
  addPolicyInfo(context): Observable<any> {
    return this._http.post(this.baseUrl + `/alarm/policy`, context, {observe: 'response'});
  }

  // 自定义策略-修改
  editPolicyInfo(context): Observable<any> {
    return this._http.put(this.baseUrl + `/alarm/policy/${context.id}`, context, {observe: 'response'});
  }

  // 自定义策略-删除
  deletePolicyInfo(ids): Observable<any> {
    return this._http.delete(this.baseUrl + `/alarm/policy`, {'ids': ids});
  }

  // 自定义国标-根据车型获取 /alarm/policy/gb/{id}
  getContentByVehicleModel(vehicleModelId): Observable<any> {
    return this._http.get(this.baseUrl + `/alarm/policy/gb/${vehicleModelId}`);
  }

  // 自定义国标-保存 /alarm/policy/gb/{id}
  saveContentByVehicleModel(vehicleModelId, context): Observable<any> {
    return this._http.post(this.baseUrl + `/alarm/policy/gb/${vehicleModelId}`, context, {observe: 'response'});
  }

  /**
   * 获取字典
   */
  getOptionsByCodes(codes: String[]): Observable<any> {

    if (codes == null && codes.length == 0) return;
    let httpParams = new HttpParams();
    let codesStr = this.array2String(codes, ",");
    httpParams = httpParams.set("codes", this.array2String(codes, ","));
    //httpParams.append("monitorStatus",'1');
    return this._http.get(this.baseUrl + "/dictionary?monitorStatus=1&codes="+codesStr);
  }

  array2String(array, separator: String) {
    if (array == null) return;
    let resStr = "";
    for (var i in array) {
      resStr += array[i] + separator;
    }
    return resStr.substring(0, resStr.length - 1);
  }
}
