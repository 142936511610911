import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from './modal.service';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class DialogService {

  constructor(private  modalService: ModalService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private domSanitizer: DomSanitizer) {

  }

  confirm<T>(dialogOptions): Observable<T> {
    // if (dialogOptions.content && dialogOptions.html && (typeof dialogOptions.content === 'string')) {
    //   dialogOptions.content = this.domSanitizer.bypassSecurityTrustHtml(dialogOptions.content as string);
    // }
    return this.modalService.open({
      component: ConfirmDialogComponent,
      componentFactoryResolver: this.componentFactoryResolver,
      resolve: dialogOptions.resolve,
      width: 300,
      height: 'auto',
      title: '确认',
    });
  }
}
