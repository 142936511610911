import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges, forwardRef, ViewChild,ElementRef} from '@angular/core';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'sf-form-control',
  templateUrl: './sf-form-control.component.html',
  styleUrls: ['./sf-form-control.component.less']
})
export class SfFormControlComponent implements OnInit  {
  @Input() nzSm = 16;
  _height=0;

  constructor(
    private http: _HttpClient,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    let el=this.elementRef.nativeElement;
    el.classList.add(`ant-col-${this.nzSm}`);
  }

}
