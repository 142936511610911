import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { CacheService } from '@delon/cache';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

declare var require: any;

@Injectable()
export class MessageResolver implements OnDestroy {

  constructor(
    private translate: TranslateService,
    private cache: CacheService,
    private http: HttpClient,
  ) {
    let lag = this.cache.get('language', { mode: 'none', type: 's' }) ?
      this.cache.get('language', { mode: 'none', type: 's' })
      : 'zh-CN';

    let cnMes = this.http.get(`./assets/message-zh-CN.json`);
    let enMes = this.http.get(`./assets/message-en.json`);

    forkJoin(cnMes, enMes).subscribe((res) => {
      this.cacheMessage['zh-CN'] = res[0];
      this.cacheMessage['en'] = res[1];
      this.messages=this.cacheMessage[lag];
      this.translate.onLangChange.subscribe(((event: TranslationChangeEvent) => {
        this.translate.get('setLanguage').subscribe(res => {
            this.messages = this.cacheMessage[res];
        });
      }));
    });


  }

  ngOnDestroy() {
    // this.translate.onLangChange.unsubscribe();
  }

  cacheMessage = {
    'zh-CN': {},
    'en': {},
  };
  messages = null;
  validationPrefix = 'validation';

  getValidationMessage(
    field: string,
    key: string,
    params: { [key: string]: any } = {},
  ) {
    const msgParams = {
      field,
      key,
      label: field,
      ...params,
    };
    let message = this.getMessage(
      `${this.validationPrefix}.${field}.${key}`,
      msgParams,
    );
    if (!message) {
      message = this.getMessage(`${this.validationPrefix}.${key}`, msgParams);
    }

    return message;
  }

  getMessage(msgKey: string, params: { [key: string]: any } = {}) {
    const message = this.messages[msgKey];
    return message ? this.formatMessage(message, params) : '';
  }

  private formatMessage(message: string, params: { [key: string]: any }) {
    const error = Object.keys(params).reduce(
      (msg, key) =>
        msg.replace(new RegExp(`{${key}}`, 'gi'), params[key].toString()),
      message,
    );

    return `${error.substr(0, 1).toUpperCase()}${error.substr(1)}`;
  }
}
