import {Component, Input, OnInit, OnChanges} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-plus-minus-bar',
    templateUrl: './plus-minus-bar.component.html',
    styleUrls: ['./plus-minus-bar.component.less']
})
export class PlusMinusBarComponent implements OnChanges {

    @Input() serie: any = [];
    @Input() height: number;
    @Input() legend: boolean = true;
    option: any;

    constructor() {
    }

    ngOnChanges() {
        this.initHistogram();
    }

    initHistogram() {
        let tempOption = {
            tooltip: {
                trigger: 'axis',
                axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                    type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            color: this.serie.color,
            legend: {
                bottom: 0,
                data: this.serie.legend.data,
                textStyle: {'color': '#fff'},
                show: this.legend
            },
            grid: {
                top:0,
                left: '3%',
                right: '4%',
                bottom: '14%',
                containLabel: true
            },
            xAxis: {
                type : 'value',
                axisLine: {
                    lineStyle: {
                        color: '#3C5076',
                    }
                },
                axisLabel: {
                    color: '#F9FAFA',
                    fontSize: 10
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#3C5076',
                        width: 1
                    }
                }
            },
            yAxis: [
                {
                    type : 'category',
                    data : this.serie.yAxis,
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#3C5076',
                        }
                    },
                    axisLabel: {
                        color: '#F9FAFA',
                        fontSize: 10,
                        interval:0,
                        rotate:this.serie.axisLabelXRotate?this.serie.axisLabelXRotate:0,
                    }
                }
            ],
            series: this.serie.series
        };

        this.option = _.cloneDeep(tempOption);
    }
}
