import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ContentChild,
  ElementRef,
  SimpleChanges,
  OnChanges, HostListener,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import set = Reflect.set;

@Component({
  selector: 'sf-tree-table',
  templateUrl: './sf-tree-table.component.html',
  styleUrls: ['./sf-tree-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfTreeTableComponent implements OnInit, OnChanges {

  @Input() total = 1;
  @Input() pageIndex = 1;
  @Input() pageSize = 10;
  @Input() columns = [];
  @Input() data = [];
  @Input() showPagination = false;
  @Input() pageSizeOptions = [10, 15, 30, 50, 100];
  @Input() scrollHeight = null;
  @Input() resizableColumns = true;
  @Input() scrollable = false;
  @Input() columnResizeMode = 'expand';
  @Input() showTotal = true;
  @Input() showCheckbox = false;
  @Input() autoLayout = false;
  @Input() loading = false;
  @Input() width = null;
  @Input() dataKey = 'vin';
  @Input() rowExpansion = false;
  @Input() selectionMode = 'single';
  @Input() showHeader = true;
  @Input() metaKeySelection = true;
  @Input() showSerial = true;
  @Input() hiddenSfQuery = false;

  @Output() selectedChange = new EventEmitter();
  @Output() pageIndexChange = new EventEmitter<any>();
  @Output() pageSizeChange = new EventEmitter<any>();
  @Output() onNodeSelectChange = new EventEmitter<any>();
  @Output() onNodeCollapse = new EventEmitter<any>();

  @ViewChild('pagin') pagin;
  @ViewChild('pTable') pTable;
  @ViewChild('ptableBox') ptableBox: ElementRef;
  @ViewChild('innerBox') innerBox: ElementRef;

  private selectionValue;
  public headerHeight = 37;

  @Input()
  get selectionModel() {
    return this.selectionValue;
  }

  set selectionModel(val) {
    this.selectionValue = val;
    this.selectionModelChange.emit(this.selectionValue);
  }

  @Output() selectionModelChange = new EventEmitter<any>();


  constructor(
    private http: _HttpClient,
    private cd: ChangeDetectorRef,
    private ref: ElementRef,
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getScrollHeight();
  }

  putDataIndex(data, index) {
    for (let item of data) {
      if(item.parent==null){
        index.indx++;
        item.data.index = index.indx;
      }
      if (item.children) {
        this.putDataIndex(item.children, index);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      let data = changes.data.currentValue, index = {indx:0};
      this.putDataIndex(data, index);
      this.getScrollHeight();
    }
  }

  getScrollHeight() {
    this.headerHeight = this.showHeader ? 37 : 0;
    setTimeout(() => {
      if (this.ptableBox.nativeElement.offsetHeight == 0) {
        return;
      }
      let outer = this.ptableBox.nativeElement.offsetHeight;
      let inner = this.innerBox.nativeElement.clientHeight;
      if (outer > inner) {
        this.scrollHeight = null;
        this.scrollable = false;
        this.cd.markForCheck();
        setTimeout(() => {
          let outer = this.ptableBox.nativeElement.offsetHeight;
          let inner = this.innerBox.nativeElement.clientHeight;
          if (outer < inner) {
            this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
            this.scrollable = true;
            this.cd.markForCheck();
          }
        }, 100);
      } else {
        this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
        this.scrollable = true;
        this.cd.markForCheck();
      }
    }, 100);
  }

  // ngAfterContentChecked() {
  //
  // }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.headerHeight = this.showHeader ? 37 : 0;
    setTimeout(() => {
      if (this.ptableBox.nativeElement.offsetHeight == 0) {
        return;
      }
      let outer = this.ptableBox.nativeElement.offsetHeight;
      let inner = this.innerBox.nativeElement.clientHeight;
      if (outer > inner) {
        this.scrollHeight = null;
        this.scrollable = false;
        this.cd.markForCheck();
        setTimeout(() => {
          let outer = this.ptableBox.nativeElement.offsetHeight;
          let inner = this.innerBox.nativeElement.clientHeight;
          if (outer < inner) {
            this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
            this.scrollable = true;
            this.cd.markForCheck();
          }
        }, 100);
      } else {
        this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
        this.scrollable = true;
        this.cd.markForCheck();
      }
    }, 100);
  }

  //页面改变
  nzPageIndexChange($event) {
    this.pageIndexChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      total: this.total,
    });
  }

  nzPageSizeChange($event) {
    this.pageSizeChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      total: this.total,
    });
  }


  //获得页数
  getPagers() {
    return Math.ceil(this.total / this.pageSize);
  }

  //确定按钮点击
  tzpage() {
    let oldIndex = this.pagin.nzPageIndex;
    let val = this.pagin._itemRender.elementRef.nativeElement.parentElement.getElementsByTagName('input')[0].value;
    if (val <= 0 || oldIndex == val) {
      return;
    }
    this.pagin._itemRender.elementRef.nativeElement.parentElement.getElementsByTagName('input')[0].value = '';
    this.pagin.jumpPage(val);
  }


  rowClick(rowData) {
    console.log(this.selectionModel);
  }

  IsSelectedRow(rowData) {
    if (this.selectionModel.indexOf(rowData) > -1) {
      return true;
    }
    ;
    return false;
  }

  onRowExpand($event) {
    this.getScrollHeight();
  }

  onNodeExpand($event) {
    this.getScrollHeight();
  }

  nodeCollapse($event) {
    this.onNodeCollapse.emit($event);
    this.getScrollHeight();
  }

  onNodeSelect($event) {
    this.onNodeSelectChange.emit($event);
  }

  clear() {
    setTimeout(() => {
      this.selectionModel = null;
      this.cd.markForCheck();
    });
  }

  scrollWidth=15;

  colResize($event){
    if(this.pTable.scrollable){
      let ui=this.pTable.containerViewChild.nativeElement.querySelector('.ui-treetable-scrollable-body-table');
      setTimeout(()=>{
        // this.pTable.containerViewChild.nativeElement.style.width=parseFloat(ui.style.width)+this.scrollWidth+'px'
        // this.cd.markForCheck()
        // this.cd.detectChanges()

        this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
        this.scrollable = true;
        this.cd.markForCheck()
        this.cd.detectChanges()
      },50)

    };
  }

}
