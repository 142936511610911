import {ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef,} from '@angular/core';
import {ModalComponent} from './modal.component';
import {ModalConfig} from './modal.config';


import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';


@Injectable({providedIn: 'root'})
export class ModalService {
  rootContainer: ViewContainerRef;
  private instances = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private modalConfig: ModalConfig) {
  }


  open<T>(options): Observable<T> {
    const rootContainer = this.modalConfig.rootContainer;
    if (!rootContainer) {
      throw new Error('Should setup ViewContainerRef on modal options');
    }
    if (options.animation === undefined || options.animation === null) {
      options.animation = true;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const injector = options.injector || this.injector;
    const modalRef = <ComponentRef<ModalComponent>>rootContainer
      .createComponent(componentFactory, rootContainer.length, injector);
    this.instances.push(modalRef);
    const instance: ModalComponent = modalRef.instance;
    instance.width = options.width;
    instance.height = options.height;
    instance.title = options.title;
    instance.maximizable = options.maximizable;
    const dismissResult = instance.addContent(options, this.instances.length)
      .pipe(
        tap(() => this.close(modalRef)),
        catchError(error => {
          this.close(modalRef);
          return throwError(error);
        }),
      );
    instance.open();
    return dismissResult as any;
  }

  closeAll(): void {
    this.instances.forEach(modalRef => this.close(modalRef));
  }

  private close(modalRef: ComponentRef<ModalComponent>): void {
    this.instances.splice(this.instances.indexOf(modalRef), 1);
    // const subscriber = modalRef.instance.close();
    modalRef.destroy();
  }

  refresh(height?) {
    if (this.instances.length == 0) {
      return
    }
    let pDialogHeader = 51, defaultHeight = 'auto';
    let pDialog = this.instances[0].instance.pDialog;
    if (height == null) {
      height = 'auto';
      pDialog.contentViewChild.nativeElement.style.height = height;
      pDialog.container.style.height = height;
    } else {
      height = height - pDialogHeader >= 0 ? height - pDialogHeader : 0;
      pDialog.contentViewChild.nativeElement.style.height = height;
      pDialog.container.style.height = height;
    }
  }


}
