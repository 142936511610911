import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable({
  providedIn: 'root',
})
export class HistoryInfoService extends BaseService {

  moduleName = this.baseUrl + '/historicalData';

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.post(`${this.moduleName}/queryHistoryBySingleVehicle`, query);
  }

  pageQueryBy(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.post(`${this.moduleName}/monitorHistoryDataQuery`, query);
  }


  getTreeList(): Observable<any> {
    return this._http.get(`/iov/monitorKeyTree`);
  }

}
