import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ContentChild,
  ViewContainerRef,
  ElementRef,
  OnChanges, HostListener,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'b-monitoring',
  templateUrl: './b-monitoring.component.html',
  styleUrls: ['./b-monitoring.component.less'],
})
export class BMonitoringComponent implements OnInit, OnChanges {
  @ViewChild('pDialog') pDialog: ViewContainerRef;
  private visibleValue;
  @Input()
  get visible(){
    return this.visibleValue;
  }
  set visible(val){
    this.visibleValue = val;
    this.visibleChange.emit(this.visibleValue);
  }

  @Output() visibleChange = new EventEmitter<any>();

  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() title = '';
  @Input() vin = '';
  activeIndex=0;

  constructor(
    private http: _HttpClient,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
  ) {
  }

  modelDom;
  ngAfterViewInit() {
    this.modelDom=this.el.nativeElement.querySelector('p-dialog');
    this.modelDom.addEventListener('click',this.modelClick.bind(this))
  }

  modelClick(event){
    if(event.target.nodeName=='svg'){
      return
    }
    if(event.target.className.indexOf('pi-window-minimize')>=0
      ||event.target.className.indexOf('pi-window-maximize')>=0){
      var myEvent = new Event('resize');
      window.dispatchEvent(myEvent);
      console.log('myEvent');
    };
  }

  ngOnDestroy(){
    this.modelDom.removeEventListener('click',this.modelClick)
  }

  ngOnInit() {
    console.dir(this.pDialog);
  }

  ngAfterContentInit() {
  }

  ngOnChanges() {

  }

  cancel(){
    this.visible=false;
  }

  tabClick(index){
    this.activeIndex=index;
  }

}
