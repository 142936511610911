import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '@service/base.service';
import {any} from "codelyzer/util/function";
import {_HttpClient} from "@delon/theme";

@Injectable()
export class VehicleInfoService extends BaseService{
  moduleName = '/iov/vehicles';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor( private _http: _HttpClient) {
    super();
  }

  /**
   * 组织机构下拉框
   */
  orgOption(): Observable<any> {
    return this._http.get(`/system/organizations/dropDownListInfo`);

  }

  /**
   * 车辆型号机构下拉框
   */
  modelOption(): Observable<any> {
    return this._http.get(this.moduleName + `/modelList`);
  }

  /**
   * 页面数据获取
   * @param query
   * @param pageIndex
   * @param pageSize
   * @returns {Observable<any>}
   */
  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, {observe: "response"});
  }

  /**
   * 通过车辆型号查询 终端型号、储能装置型号1、储能装置型号2、驱动电机型号1、驱动电机型号2、发动机型号
   */

  getInfoById(id): Observable<any> {
    return this._http.get(this.moduleName + `/modelInfo/${id}`);
  }

  create(obj): Observable<any> {
    return this._http.post(this.moduleName, obj)
  }
  vehicleInfo(id): Observable<any> {
    return this._http.get(this.moduleName + `/${id}`);
  }

  update(id,obj ): Observable<any> {
    return this._http.put(this.moduleName + `/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(this.moduleName ,{"ids": ids});
  }
}
