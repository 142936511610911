import { Directive, ElementRef, Renderer2, Input,AfterViewInit, OnInit } from '@angular/core';

@Directive({
  selector: '[nzCustom]'
})
export class nzCustomDirective implements OnInit {
  hostElement: ElementRef;
  @Input('nzCustom') nzCustom:any;
  constructor(
    ele: ElementRef,
    private renderer2: Renderer2
  ) {
    this.hostElement = ele;
  }
  ngOnInit() {
     this.renderer2.setStyle(this.hostElement.nativeElement,'flex','0 0 auto');
     this.renderer2.setStyle(this.hostElement.nativeElement,'float','left');
     this.renderer2.setStyle(this.hostElement.nativeElement,'display','block');
     this.renderer2.setStyle(this.hostElement.nativeElement,'box-sizing','border-box');
     this.renderer2.setStyle(this.hostElement.nativeElement,'width',this.nzCustom+'px');
  }

}
