import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';

export interface VehicleGroup {
  itemName: string;
  remark: string;
  vehicleCount: number;
}

@Injectable()
export class VehicleGroupService extends BaseService {

  moduleName = this.baseUrl + `/vehicles/group`

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, {observe: "response"});
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  create(obj: VehicleGroup): Observable<any> {
    obj = this.trimFormData(obj, ["itemName", "remark"]);
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj: VehicleGroup): Observable<any> {
    obj = this.trimFormData(obj, ["itemName", "remark"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}`, {"ids": ids});
  }

  pageQueryUnRelated(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/getUnJoinList/${id}`, query, {observe: "response"});
  }

  bindToGroup(id, ids): Observable<any> {
    return this._http.put(`${this.moduleName}/bind/${id}`, ids);
  }

  pageQueryRelated(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/getJoinList/${id}`, query, {observe: "response"});
  }

  unBind(id, ids): Observable<any> {
    return this._http.put(`${this.moduleName}/unBind/${id}`, ids);
  }

  dropDownListInfo(): Observable<any> {
    return this._http.get(`${this.moduleName}/dropDownListInfo`);
  }
}
