import { Component, OnInit, Input, Output, EventEmitter,ComponentFactoryResolver ,SimpleChanges, forwardRef, ViewChild,TemplateRef} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService, LoadingService, NoticeService, SharedService } from '@service';
import { getTotal } from '@shared/utils/response.util';
import {formatTimesTemplete} from '@shared/utils/util.lang';
import { ModalService } from '../../modal/modal.service';
import { HttpResponse } from '@angular/common/http';
import { AlarmInfoService } from '@service/alarm';
import { city } from '@shared/city-select';
import {AlarmTracingComponent} from "../alarm-tracing/alarm-tracing.component";

@Component({
  selector: 'warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.less']
})
export class WarningMessageComponent implements OnInit  {
  public columns = [];
  public columns1 = [];
  public columns2 = [];
  public data = [];
  public jcztList = [
    {id:0,name:'未解除的报警'},
    {id:1,name:'已解除的报警'},
  ];
  public bjxlList = [];
  public bjdjList = [];
  public bjxList = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 1;
  public selectedItem = [];
  public queryParam = {
    alarmType: null,
    alarmLevel: null,
    alarmItemId:null,
    alarmItemCode:null,
    startDate: '',
    endDate: '',
  };
  jczt=0;
  formatTimesTemplete=formatTimesTemplete;
  @Input() vin: any;
  @ViewChild('zsTemplate') zsTemplate: TemplateRef<any>;
  @ViewChild('timeTemplate') timeTemplate: TemplateRef<any>;

  constructor(
    private http: _HttpClient,
    private modalService: ModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private noticeService: NoticeService,
    private loadingService: LoadingService,
    private sharedService: SharedService,
    private alarmInfoService: AlarmInfoService,
    private bMonitoringService: BMonitoringService,
  ) {
  }

  ngOnInit() {
    this.columns1 = [
      {field: 'id', hidden:true},
      {field: 'beginTime', header: '报警时间',width:160,template:this.timeTemplate},
      {field: 'endTime', header: '报警解除时间',width:160,template:this.timeTemplate},
      //{field: 'CLXH', header: '报警解除状态'},
      {field: 'level', header: '报警等级'},
      //{field: 'alarmTypeName', header: '报警类型'},
      {field: 'alarmItem', header: '报警项'},
      {field: 'alarmAddress', header: '报警位置'},
      {field: 'CS', header: '操作',template:this.zsTemplate},
    ];

    this.columns2 = [
      {field: 'id', hidden:true},
      {field: 'beginTime', header: '报警时间',width:160,template:this.timeTemplate},
      {field: 'level', header: '报警等级'},
      {field: 'alarmTypeName', header: '报警类型'},
      {field: 'alarmItem', header: '报警项'},
      {field: 'alarmAddress', header: '报警位置'},
      {field: 'CS', header: '操作',template:this.zsTemplate},
    ];
    this.loadDropDown();
    this.query();
  }

  pageIndexChange($event) {
    this.pageIndex = $event.pageIndex;
    this.query();
  }

  pageSizeChange($event) {
    this.pageIndex = 1;
    this.pageSize = $event.pageSize;
    this.query();
  }

  query(reset = false) {
    this.data = [];
    if(reset) {
      this.pageIndex = 1;
    }
    this.loadingService.show();
    if(this.jczt==1){
      this.columns = this.columns1;
      this.bMonitoringService.pageQueryAlarmHisTime(this.queryParam,this.vin, this.pageIndex, this.pageSize).subscribe({
        next: (res: HttpResponse<any>) => {
          this.total = getTotal(res);
          this.data = res.body;
          this.loadingService.hide();
        }, error: err => {
          this.loadingService.hide();
        }
      });
    }else{
      this.columns = this.columns2;
      this.bMonitoringService.pageQueryAlarmRealTime(this.queryParam,this.vin, this.pageIndex, this.pageSize).subscribe({
        next: (res: HttpResponse<any>) => {
          this.total = getTotal(res);
          this.data = res.body;
          this.loadingService.hide();
        }, error: err => {
          this.loadingService.hide();
        }
      });
    }
  }

  reset(){
    this.queryParam = {
      alarmType: null,
      alarmLevel: null,
      alarmItemId:null,
      alarmItemCode:null,
      startDate: '',
      endDate: '',
    };
    this.jczt=0;
    this.query(true);
  }

  loadDropDown() {
    this.alarmInfoService.getAlarmTypeList().subscribe((res) => {
      this.alarmTypeList = res;
    });
  }
  // 请选择报警类型
  alarmTypeList = [];
  // 请选择报警等级
  alarmLevelList =[{"label":"故障一级","value":"SELF_DEFINING_LEVEL_1"},
    {"label":"故障二级","value":"SELF_DEFINING_LEVEL_2"},{"label":"故障三级","value":"SELF_DEFINING_LEVEL_3"}];
  // 请选择报警项
  alarmItemList = [];
  alarmTypeChange(value: string) {
    const tmpVal = [value];
    this.sharedService.getOptionsByCodes(tmpVal).subscribe((res) => {
      for (const k in res) {
        if (value === k) {
          this.alarmLevelList = res[k];
        }
      }
    });
    this.alarmInfoService.getAlarmItemByType(value).subscribe((res) => {
      this.alarmItemList = res;
    });
  }

  alarmLevelChange(event){
    this.queryParam.alarmItemCode = null;
    this.alarmItemList = [];
    this.alarmInfoService.alarmItemCZBQ(event).subscribe(res =>{
      if(res){
        this.alarmItemList = res;
      }
    });
  }

  diagnosis(value) {
    this.modalService.open<string>({
      component: AlarmTracingComponent,
      componentFactoryResolver: this.componentFactoryResolver,
      resolve: value,
      width: 1000,
      height: 'auto',
      title: '诊断',
    }).subscribe(data => {
      console.log(data);
    }, error => {
    });
  }

}
