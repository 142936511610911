import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable({
  providedIn: "root"
})
export class HistoryMultiService extends BaseService {

  moduleName = this.baseUrl + '/historicalData';

  constructor(private _http: _HttpClient) {
    super();
  }

  getTree(): Observable<any> {
    return this._http.get(`/iov/monitorKeyTree`);
  }

  saveTask(obj): Observable<any> {
    return this._http.post(`${this.moduleName}/addTask`, obj);
  }

  getTask(data): Observable<any> {
    return this._http.get(`${this.moduleName}/taskList`, data, { observe: 'response' });
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}?${ids}`);
  }

  taskVehiclesList(id, data = {}): Observable<any> {
    return this._http.get(`${this.moduleName}/taskVehiclesList/${id}`, data);
  }

  getView(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`);
  }
}
