import {Component, ChangeDetectionStrategy, ChangeDetectorRef,} from '@angular/core';
import {Router} from '@angular/router';
import {MenuService} from '@delon/theme';
import {SharedService} from '@service';

/**
 * 菜单通知
 */
@Component({
  selector: 'header-notify',
  template: `
    <div class="topBarAlert" (click)="alarm()">
      <nz-badge [nzCount]="count" [nzOverflowCount]="999"></nz-badge>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNotifyComponent {

  public count = 0;
  private timer: any = null;

  constructor(public router: Router,
              private cdr: ChangeDetectorRef,
              private menuSrv: MenuService,
              private _service: SharedService) {
  }

  ngOnInit() {
    this._service.getAlarmCount().subscribe(result => {
      this.count = result;
      this.cdr.detectChanges();
    })
    this.timer = setInterval(() => {
      this._service.getAlarmCount().subscribe(result => {
        this.count = result;
        this.cdr.detectChanges();
      })
    }, 1000 * 500)
  }

  alarm() {
    const url = '/alarm/reltime';
    this.menuSrv.openedByUrl(url);
    this.router.navigate([url]);
    // this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.timer != null) {
      clearInterval(this.timer);
    }
  }
}
