import {Component, Input, OnInit, OnChanges, EventEmitter,Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-waterfall',
  templateUrl: './waterfall.component.html',
  styleUrls: ['./waterfall.component.less']
})
export class WaterfallComponent implements OnChanges {
  @Output() echartLoaded: EventEmitter<any> = new EventEmitter();
  @Input() serie: any = [];
  @Input() height: string;
  @Input() categorys: any;
  @Input() legend: boolean = true;
  option: any;

  constructor() {
  }

  ngOnChanges() {
    this.initHistogram();
  }

  initHistogram() {
    let tempOption = {
      color: this.serie.color?this.serie.color:'',
      tooltip: {
        // trigger: 'axis',
        trigger: 'item',
        // formatter: (params) => {
        //   console.log(params);
        //   let str='';
        //   params.forEach((d)=>{
        //     if(d.data!='-'){
        //       console.log(d);
        //       str+=d.seriesName+':'+d.data
        //     };
        //   });
        //   return str;
        // }
      },
      legend: {
        bottom: 0,
        data: this.serie.legend,
        textStyle: {'color': '#fff'},
        show: this.legend
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '10%',
        top: this.serie.gridTop?this.serie.gridTop:'6%',
        containLabel: true
      },
      xAxis: [
        {
          type: this.serie.xAxis.type ? this.serie.xAxis.type : '',
          data: this.serie.xAxis,
          splitLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#3C5076',
            }
          },
          axisLabel: {
            color: '#F9FAFA',
            fontSize: 10,
            interval: 0,
            rotate: this.serie.axisLabelXRotate ? this.serie.axisLabelXRotate : 0,
          },
        }
      ],
      yAxis: [
        {
          type: this.serie.yAxis.type,
          data: this.serie.yAxis.data,
          name: this.serie.unit ? this.serie.unit : '',
          max: this.serie.yAxis.max ? this.serie.yAxis.max : null,
          min: this.serie.yAxis.min ? this.serie.yAxis.min : null,
          nameTextStyle: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#3C5076',
            }
          },
          axisLabel: {
            color: '#F9FAFA',
            fontSize: 10
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#3C5076',
              width: 1
            }
          }
        }
      ],
      series: this.serie.series
    };
    this.option = _.cloneDeep(tempOption);
  }

  onEchartLoaded($event){
    this.echartLoaded.emit($event);
  }
}
