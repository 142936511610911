import {Injectable, Injector, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PlatformLocation} from '@angular/common';
import {MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN} from '@delon/theme';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {ACLService} from '@delon/acl';
import {TranslateService} from '@ngx-translate/core';
import {I18NService} from '../i18n/i18n.service';

import {NzIconService} from 'ng-zorro-antd';
// import {ICONS_AUTO} from '../../../style-icons-auto';
// import {ICONS} from '../../../style-icons';
import { filterMenus } from '@shared/utils/menu.util';
import { Location } from '@angular/common';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private httpClient: HttpClient,
    private injector: Injector
  ) {
    // iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
    iconSrv.fetchFromIconfont({
      scriptUrl: '//at.alicdn.com/t/font_1058810_5dihv1vbz9q.js'
    });
  }

  private viaHttp(login) {
    // const href = location.href;
    const pathname = location.pathname;
    return this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`).toPromise()
    .then(langData => {
      this.translate.setTranslation(this.i18n.defaultLang, langData);
      this.translate.setDefaultLang(this.i18n.defaultLang);
      // 设置页面标题的后缀
      this.titleService.suffix = this.translate.instant("app.name");
    }).then(() => {
      if(login || pathname.indexOf("/passport/") == -1) {
        return this.httpClient.get('/system/accounts/current').toPromise();
      } else {
        return null;
      }      
    })
    .then(userinfo => {
        // 用户信息：包括姓名、头像、邮箱地址
        this.settingService.setUser(userinfo);
        // ACL：设置权限为全量
        this.aclService.setFull(false);
        if(userinfo && userinfo["authorizes"]) {
            let ids = [];
            userinfo["authorizes"].forEach(element => {
              let id = + element.id;
              ids.push(id);
            });

            this.aclService.setAbility(ids);
            // 初始化菜单

            this.menuService.add(filterMenus(userinfo["authorizes"]));
        }
    });
  }

  load(login): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return this.viaHttp(login);;
  }
}
