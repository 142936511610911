import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable()
export class BMonitoringService extends BaseService {

  moduleName = this.baseUrl + '/vehicle';
  alertModuleName = this.baseUrl + '/alarm';

  constructor(private _http: _HttpClient) {
    super();
  }

  queryAllVehiclesCount(): Observable<any> {
    return this._http.get('/dashboard/vehiclesCount');
  }

  queryAllModelList(): Observable<any> {
    return this._http.get(`${this.moduleName}/model/dropDownListInfo`);
  }

  queryAllOrgan(): Observable<any> {
    return this._http.get('/system/organizations/dropDownListInfo');
  }

  pageQueryRealtime(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/realtime`, query, { observe: "response" });
  }

  queryAllRealtime(): Observable<any> {
    return this._http.get(`${this.moduleName}/realtime/all`);
  }

  queryAllForwardData(): Observable<any> {
    return this._http.get(`${this.moduleName}/data/all`);
  }

  pageQueryUnboundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/unbound/${id}`, query, { observe: "response" });
  }

  pageQueryBoundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/bound/${id}`, query, { observe: "response" });
  }

  getDictionary(query): Observable<any> {
    return this._http.get(`${this.baseUrl}/dictionary`, query, { observe: "response" });
  }

  queryUserDefinedAlarm(): Observable<any> {
    return this._http.get(`${this.moduleName}/queryUserDefinedAlarm`);
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  getRealtime(id): Observable<any> {
    return this._http.get(`${this.moduleName}/realtime/${id}`)
  }

  getEm(vin, query): Observable<any> {
    return this._http.get(`${this.moduleName}/em/${vin}`, query, { observe: "response" });
  }

  pageQueryhisDate(vin, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/historicalConditions/${vin}`, query, { observe: "response" });
  }

  pageQueryAlarmRealTime(query, vin, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    query.vin = vin;
    return this._http.get(`${this.alertModuleName}/realTime`, query, { observe: "response" });
  }

  pageQueryAlarmHisTime(query, vin, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    query.vin = vin;
    return this._http.get(`${this.alertModuleName}/history`, query, { observe: "response" });
  }

  getOpeStatis(vin, query): Observable<any> {
    return this._http.get(`${this.moduleName}/mileageStatistics/${vin}`, query, { observe: "response" })
  }

  //查询行程明细
  pageQuerymileageDetail(vin, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/mileageDetail/${vin}`, query, { observe: "response" });
  }

  //查询充电分析
  pageQueryCharge(vin, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/charge/${vin}`, query, { observe: "response" });
  }

  //查询里程分析信息
  pageQueryMileageInfo(vin, query): Observable<any> {
    return this._http.get(`${this.moduleName}/mileageInfo/${vin}`, query, { observe: "response" });
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}/${ids}`);
  }

  run(id): Observable<any> {
    return this._http.get(`${this.moduleName}/run/${id}`, {});
  }

  stop(id): Observable<any> {
    return this._http.get(`${this.moduleName}/stop/${id}`, {});
  }

  bind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/bind/${id}`, vehicleIds);
  }

  unbind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/unbind/${id}`, vehicleIds);
  }

}
