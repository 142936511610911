export const DownloadUrls = {
  // common: '',
  fileDownloadUrl: '/system/files/download/',
  supersivionVehicleTemplate: './assets/tmp/excel/supervision-version.xlsx',
  excelVehicleTemplate: './assets/tmp/excel/vehicle.xlsx',
  vehicleBindGroupTemplate: './assets/tmp/excel/importRelatedVehicles.xlsx',
  vinTemplate: './assets/tmp/excel/vin.xlsx',
  historyTaskAddVehicleTemplate: './assets/tmp/excel/history-task-add-vehicle.xlsx',
  common: './assets/tmp/files/download',
  excelVinTemplate: './assets/tmp/excel/vin.xlsx',
  ftpDownloadExcelPath: '/iov-file/ftp/files/Excel/',
  excelOrgTemplate: './assets/tmp/excel/org.xlsx',
  downLoadExcelUrl: '/file/download',
  downloadExcelPath: '/file/download/Excel/',
  excelEngineModelTemplate: './assets/tmp/excel/engineModel.xlsx',
  excelTerminalModelTemplate: './assets/tmp/excel/terminalModel.xlsx',
  excelVehicleModelTemplate: './assets/tmp/excel/vehicleModel.xlsx',
  excelEnergyStorageModelTemplate: './assets/tmp/excel/energyStorageModelExcel.xlsx',
  excelElectricModelTemplate: './assets/tmp/excel/electricModelExcel.xlsx',
};

export const rule = [
  { key: 'MDT_O_VEH_STA', ru: { 1: '启动', 2: '熄火', 3: '其他', 254: '异常', 255: '无效' } },
  { key: 'MDT_O_CHARGE_STA', ru: { 1: '停车充电', 2: '行驶充电', 3: '未充电', 4: '充电完成', 254: '异常', 255: '无效' } },
  { key: 'MDT_O_POW_MODE', ru: { 1: '纯电', 2: '混动', 3: '燃油', 254: '异常', 255: '无效' } },
  { key: 'MDT_O_DC_STA', ru: { 1: "工作", 2: "断开", 254: "异常", 255: "无效" } },
  { key: 'MDT_O_GEAR', ru: { 15: "P", 14: "D", 0: "N", 13: "R", 1: "1档", 2: "2档", 3: "3档", 4: "4档", 5: "5档" } },
  { key: 'MDT_O_DRIVER_STA', ru: { 1: "有效", 254: "异常", 255: "无效" } },
  { key: 'MDT_O_BRAKING_STA', ru: { 1: "有效", 254: "异常", 255: "无效" } },
  { key: 'MDT_DR_STA', ru: { 1: "耗电", 2: "发电", 3: "关闭", 4: "准备", 254: "异常", 255: "无效" } },
  { key: 'MDT_EN_STA', ru: { 1: "启动", 2: "关闭", 254: "异常", 255: "无效" } },
  { key: 'MDT_CO_COOL_METHOD', ru: { 1: "自然冷却", "2": "风冷", "3": "液冷", "4": "其他方式", 254: "异常", 255: "无效" } },
  { key: 'MDT_CO_TYPE', ru: { "0": "断开", "1": "闭合", "254": "异常", "255": "无效" } },
  { key: 'MDT_CO_S2_TYPE', ru: { "0": "断开", "1": "闭合", "254": "异常", "255": "无效" } },
  { key: 'MDT_CO_KEY', ru: { "0": "LOCK", "1": "ACC", "16": "ON", "17": "START", "254": "异常", "255": "无效" } },
  { key: 'MDT_PO_GPS_STA', ru: { 0: "有效", 1: "无效" } },
  { key: 'CM_A_DA_0', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_1', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_2', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_3', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_18', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_5', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_6', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_10', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_8', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_4', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_7', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_11', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_12', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_14', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_17', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_15', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_9', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_13', ru: { 0: "无", 1: "有" } },
  { key: 'CM_A_DA_16', ru: { 0: "无", 1: "有" } },
  { key: 'CM_ST_CAN_O', ru: { 0: "不支持预约", 1: "支持预约" } },
  { key: 'CM_ORDER_STA', ru: { 1: "启动中", 2: "充电中", 3: "停止中", 4: "已结束", 5: "位置" } },
  { key: 'CE_ST_YY_STA', ru: {0: "运营", 1: "拆除" } }, // 充电站点运营状态
  { key: 'CE_ST_TYPE', ru: {1: "公共", 50: "个人", 100: "公交（专用）", 101: "环卫（专用）", 102: "物流（专用）", 103: "出租车（专用）", 255: "其他" } }, // 充电站点类型
  { key: 'CE_ST_STA', ru: {0: "未知", 1: "建设中", 5: "关闭下线", 6: "维护中", 50: "正常使用" } }, // 充电站点状态
  { key: 'CE_ST_BU', ru: {1: "居民区", 2: "公共机构", 3: "企事业单位", 4: "写字楼", 5: "工业园区", 6: "交通枢纽", 7: "大型文体设施", 8: "城市绿地", 9: "大型建筑配建停车场", 10: "路边停车位", 11: "城际高速服务区", 255: "其他" } }, // 充电站建设场所
  { key: 'CE_EQ_TYPE', ru: {0: "未知", 1: "直流设备", 2: "交流设备", 3: "交直流一体设备", 4: "无线设备" } }, // 充电桩类型
  { key: 'CE_EQ_STA', ru: {0: "离线", 1: "空闲", 2: "占用", 255: "故障" } }, // 充电桩状态
  { key: 'CE_CO_STA', ru: {0: "离网", 1: "空闲", 2: "占用（未充电）", 3: "占用（充电中）", 4: "占用（预约锁定）", 255: "故障" } }, // 充电枪状态
  { key: 'CE_CO_TYPE', ru: {1: "家用插座（模式 2）", 2: "交流接口插座（模式 3， 连接方式 B ）", 3: "交流接口插头（带枪线， 模式 3，连接方式 C）", 4: "直流接口枪头（带枪线， 模式 4）", 5: "无线充电座", 6: "其他" } } // 充电枪类型
]

// 数据字典主表KEY
export const Dictionary = {
  // 车辆信息配置
  VEHICLE_INFO_CONFIG: 'VEHICLE_INFO_CONFIG',
  // 车牌颜色
  LICENSE_COLOR: 'LICENSE_COLOR',
  // 购车领域
  PURCHASE_RANGE: 'PURCHASE_RANGE',
  // 车辆用途
  VEHICLE_PURPOSE: 'VEHICLE_PURPOSE',
  // 终端供应商
  TERMINAL_VENDOR: 'TERMINAL_VENDOR',
  // 新能源信息配置
  ELECTRIC_INFO_CONFIG: 'ELECTRIC_INFO_CONFIG',
  // 动力方式
  POWER_TYPE: 'POWER_TYPE',
  // 电池种类
  BATTERY_TYPE: 'BATTERY_TYPE',
  // 驱动电机种类
  ELECTRIC_DRIVER_TYPE: 'ELECTRIC_DRIVER_TYPE',
  // 驱动电机冷却方式
  DRIVER_COOL_TYPE: 'DRIVER_COOL_TYPE',
  // 续驶里程检测方式
  ENDURANCE_CHECK: 'ENDURANCE_CHECK',
  // 监控数据大类
  MONITOR_DATA_TYPE: 'MONITOR_DATA_TYPE',
  // 整车数据
  MDT_O_DA_TYPE: 'MDT_O_DA_TYPE',
  // 驱动电机数据
  MDT_DR_DA_TYPE: 'MDT_DR_DA_TYPE',
  // 发动机数据
  MDT_EN_DA_TYPE: 'MDT_EN_DA_TYPE',
  // 极值数据
  MDT_P_DA_TYPE: 'MDT_P_DA_TYPE',
  // 报警数据
  MDT_A_DA_TYPE: 'MDT_A_DA_TYPE',
  // 位置数据
  MDT_PO_DA_TYPE: 'MDT_PO_DA_TYPE',
  // 动力电池数据
  MDT_BA_DA_TYPE: 'MDT_BA_DA_TYPE',
  // 通用报警数据
  CM_A_DA: 'CM_A_DA',
  // 通信配置
  COMMUNICATION_CONFIG: 'COMMUNICATION_CONFIG',
  // 协议类型
  PROTOCOL_TYPE: 'PROTOCOL_TYPE',
  // 国标协议报文类型
  GB32960: 'GB32960',
  // 报警等级
  ALARM_LEVEL: 'ALARM_LEVEL',
  // 国标报警等级
  GB_ALARM_LEVEL: 'GB_ALARM_LEVEL',
  // 自定义报警等级
  SELF_DEFINING_ALARM_LEVEL: 'SELF_DEFINING_ALARM_LEVEL',
};

export const FtpDownLoadExcelUrl = 'http://218.94.153.148:4400/files/excel/';

// 权限按钮ID
export const PermissionBtnId = {
  // 历史数据导出 查询
  HistoryQuery: 10111110,
  // 历史数据导出 新增任务
  HistoryAddTask: 10111111,
  // 报文查询
  MessageQuery: 10111210,
  // 报文导出
  MessageExport: 10111210,
  //指令下发 查询
  InstructionsSendQuery: 10121010,
  //指令下发
  InstructionsSend: 10121011,

  UpgradePackageMenu: 10121110,
  // 远程升级-升级包查询
  UpgradePackageQuery: 1012111010,
  // 远程升级-升级包新增
  UpgradePackageCreate: 1012111011,
  // 远程升级-升级包修改
  UpgradePackageUpdate: 1012111012,
  // 远程升级-升级包删除
  UpgradePackageDelete: 1012111013,

  UpgradeTaskMenu: 10121111,
  // 远程升级-升级任务管理查询
  UpgradeTaskQuery: 1012111110,
  // 远程升级-升级任务管理新增
  UpgradeTaskCreate: 1012111111,
  // 远程升级-升级任务管理修改
  UpgradeTaskUpdate: 1012111112,
  // 远程升级-升级任务管理删除
  UpgradeTaskDelete: 1012111113,
  // 远程升级-升级任务管理删除
  UpgradeTaskStart: 1012111114,

  UpgradeLogMenu: 10121112,

  // 车辆实时报警查询
  VehicleRealAlarmQuery: 10131010,
  // 车辆实时报警诊断
  VehicleRealAlarmDiagnosis: 10131011,
  // 车辆历史报警查询
  VehicleHistoryAlarmQuery: 10131110,
  // 车辆历史报警诊断
  VehicleHistoryAlarmDiagnosis: 10131111,
  // 自定义报警策略查询
  AlarmStrategyQuery: 10131210,
  // 自定义报警策略新增
  AlarmStrategyCreate: 10131211,
  // 自定义报警策略修改
  AlarmStrategyUpdate: 10131212,
  // 自定义报警策略删除
  AlarmStrategyDelete: 10131213,
  // 自定义报警策略 国标自定义
  AlarmStrategyGBUpdate: 10131214,
  // 自定义报警策略 启用/停用
  AlarmStrategyStatus: 10131215,
  // 车辆信息维护 查询
  VehicleQuery: 10141010,
  // 车辆信息维护 新增
  VehicleCreate: 10151611,
  // 车辆信息维护 修改
  VehicleUpdate: 10151612,
  // 车辆信息维护 删除
  VehicleDelete: 10151613,
  // 车辆信息维护 导入
  VehicleImport: 10151614,
  // 车辆信息维护 导出
  VehicleExport: 10151615,
  // 车辆分组管理 查询
  VehicleGroupQuery: 10151010,
  // 车辆分组管理 新增
  VehicleGroupCreate: 10151011,
  // 车辆分组管理 修改
  VehicleGroupUpdate: 10151012,
  // 车辆分组管理 删除
  VehicleGroupDelete: 10151013,
  // 车辆分组管理 关联车辆
  VehicleGroupRelation: 10151014,
  // 车辆型号管理 查询
  VehicleModelQuery: 10151110,
  // 车辆型号管理 新增
  VehicleModelCreate: 10151111,
  // 车辆型号管理 修改
  VehicleModelUpdate: 10151112,
  // 车辆型号管理 删除
  VehicleModelDelete: 10151113,
  // 车辆储能装置型号管理 查询
  EnergyStorageModelQuery: 10151210,
  // 车辆储能装置型号管理 新增
  EnergyStorageModelCreate: 10151211,
  // 车辆储能装置型号管理 修改
  EnergyStorageModelUpdate: 10151212,
  // 车辆储能装置型号管理 删除
  EnergyStorageModelDelete: 10151213,
  // 车辆驱动电机型号管理 查询
  ElectricDriverModelQuery: 10151310,
  // 车辆驱动电机型号管理 新增
  ElectricDriverModelCreate: 10151311,
  // 车辆驱动电机型号管理 修改
  ElectricDriverModelUpdate: 10151312,
  // 车辆驱动电机型号管理 删除
  ElectricDriverModelDelete: 10151313,
  // 车辆终端型号管理 查询
  TerminalModelQuery: 10151410,
  // 车辆终端型号管理 新增
  TerminalModelCreate: 10151411,
  // 车辆终端型号管理 修改
  TerminalModelUpdate: 10151412,
  // 车辆终端型号管理 删除
  TerminalModelDelete: 10151413,
  // 车辆发动机型号管理 查询
  EngineModelQuery: 10151510,
  // 车辆发动机型号管理 新增
  EngineModelCreate: 10151511,
  // 车辆发动机型号管理 修改
  EngineModelUpdate: 10151512,
  // 车辆发动机型号管理 删除
  EngineModelDelete: 10151513,
  //充电汇总 查询
  ChargingCollectQuery: 10161210,
  //充电汇总 导出汇总
  ChargingCollectExport: 10161211,
  //充电汇总 导出明细
  ChargingCollectDetailExport: 10161212,
  //运营情况分析 查询
  OperationSituationQuery: 10161410,
  //运营情况分析 导出
  OperationSituationExport: 10161411,
  //车辆长期离线统计 查询
  OffLineVehicleQuery: 10161510,
  //车辆长期离线统计 导出
  OffLineVehicleExport: 10161511,
  //车辆定位异常统计 查询
  LocationExceptionVehicleQuery: 10161610,
  //车辆定位异常统计 导出
  LocationExceptionVehicleExport: 10161611,
  //车辆CAN数据异常统计 查询
  CanDateExceptionVehicleQuery: 10161710,
  //车辆CAN数据异常统计 导出
  CanDateExceptionVehicleExport: 10161711,
  // 运营商管理
  ChargingOperatorMenu: 10151710,
  // 运营商管理 查询
  ChargingOperatorQuery: 1015171010,
  // 运营商管理 查看
  ChargingOperatorLook: 1015171011,
  // 运营商管理 新增
  ChargingOperatorAdd: 1015171012,
  // 运营商管理 下载秘钥
  ChargingOperatorExport: 1015171014,
  // 充电站管理
  ChargingStationMenu: 10151711,
  // 充电桩管理
  ChargingEquipmentMenu: 10151712,
  // 充电桩管理 查询
  ChargingEquipmentQuery: 1015171210,
  // 充电桩管理 编辑
  ChargingEquipmentUpdate: 1015171211,
  // 充电枪管理
  ChargingConnectorMenu: 10151713
};
