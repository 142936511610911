import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'customize-alarm',
  templateUrl: './customize-alarm.component.html',
  styleUrls: ['./customize-alarm.component.less'],
})
export class CustomizeAlarmComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  zcsjData: any = [];

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_A_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    let arr = [];
    if (oriDate && oriDate.length > 0) {
      oriDate.forEach(element => {
        element.code = (+element.code).toString(16).toLocaleUpperCase();
        arr.push(element);
      });
    }
    this.zcsjData = arr;
    this.cd.markForCheck();
  }


  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if (this.vdata[this.tag] == null) {
        return;
      }
      let oriDate = this.vdata[this.tag];
      if (!oriDate) {
        return;
      }
      let codes = oriDate['MDT_A_BAD_OTHERS_SN_LI'];
      if (codes && codes.length > 0) {
        this.getContent(codes);
      }
    }
  }


}
