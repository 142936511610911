import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalConfig {

  rootContainer: ViewContainerRef;

  extend(obj: any): this {
    this.extendConfig(obj, this);
    return this;
  }

  private extendConfig(obj: any, targetObj: any) {
    Object.keys(obj || {})
      .reduce((target, key) => {
        const value = obj[key];
        if (value instanceof Object) {
          this.extendConfig(value, target[key]);
        } else {
          target[key] = value;
        }
        return target;
      }, targetObj);
  }
}
