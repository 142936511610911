import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PieComponent} from './pie/pie.component'
import {HistogramComponent} from './histogram/histogram.component'
import {LineComponent} from './line/line.component'
import {RadarComponent} from './radar/radar.component'
import {LineVoltageComponent} from './line-voltage/line-voltage.component'
import {EChartsModule} from '../../directive';
import {GaugeComponent} from './gauge/gaugecomponent';
import {HistogramGroupComponent} from './histogram-group/histogram-group.component';
import {MixLineBarComponent} from './mix-line-bar/mix-line-bar.component';
import {PlusMinusBarComponent} from './plus-minus-bar/plus-minus-bar.component';
import {MixLineBarTimelineComponent} from './mix-line-bar-timeline/mix-line-bar-timeline.component';
import {WaterfallComponent} from './waterfall/waterfall.component';
import { BmapComponent } from './bmap/bmap.component';

@NgModule({
  imports: [EChartsModule, CommonModule],
  declarations: [
    PieComponent,
    HistogramComponent,
    LineComponent,
    RadarComponent,
    GaugeComponent,
    HistogramGroupComponent,
    MixLineBarComponent,
    PlusMinusBarComponent,
    MixLineBarTimelineComponent,
    BmapComponent,
    WaterfallComponent,
    LineVoltageComponent,
  ],
  exports: [
    PieComponent,
    HistogramComponent,
    LineComponent,
    RadarComponent,
    GaugeComponent,
    HistogramGroupComponent,
    MixLineBarComponent,
    PlusMinusBarComponent,
    MixLineBarTimelineComponent,
    BmapComponent,
    WaterfallComponent,
    LineVoltageComponent,
  ],
})
export class EchartsComponentModule {

}
