import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BMonitoringComponent } from './b-monitoring.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {TrackComponent} from './track/track.component';
import {RealTimeComponent} from './real-time/real-time.component';
import {RealTimeInfoComponent} from './real-time-info/real-time-info.component';
import {BicycleAnalysisComponent} from './bicycle-analysis/bicycle-analysis.component';
import {HistoricalDataComponent} from './historical-data/historical-data.component';
import {HistoricalDataRealtimeComponent} from './historical-data/historical-data-realtime/historical-data-realtime.component';
import {OperationStatisticsComponent} from './operation-statistics/operation-statistics.component';
import {WarningMessageComponent} from './warning-message/warning-message.component';
import {ValueDistributionComponent} from './value-distribution/value-distribution.component';
import { NgxAmapModule } from 'ngx-amap';
import { TabsModule } from '../tabs/tabs.module';
import { TableSheetComponent } from '../app-table-sheet';
import { VehicleDataComponent } from './real-time/vehicle-data/vehicle-data.component';
import { DriveMotorComponent } from './real-time/drive-motor/drive-motor.component';
import { ExtremeValueComponent } from './real-time/extreme-value/extreme-value.component';
import { EngineDataComponent } from './real-time/engine-data/engine-data.component';
import { VehicleLocationComponent } from './real-time/vehicle-location/vehicle-location.component';
import { VoltageDataComponent } from './real-time/voltage-data/voltage-data.component';
import { TemperatureDataComponent } from './real-time/temperature-data/temperature-data.component';
import { AlertDataComponent } from './real-time/alert-data/alert-data.component';
import { VehicleArchivesComponent } from './real-time/vehicle-archives/vehicle-archives.component';
import { CustomizeComponent } from './real-time/customize/customize.component';
import { CustomizeAlarmComponent } from './real-time/customize-alarm/customize-alarm.component';
import {
EchartsComponentModule,
} from '../echarts';
import { SfTableModule } from '../sf-table/sf-table.module';
import { ContainerPageModule } from '../container-page/container-page.module';
import {CalendarModule} from 'primeng/calendar';
import { SfCalendarModule } from '../sf-calendar/sf-calendar.module';
import {AlarmTracingComponent} from "./alarm-tracing/alarm-tracing.component";

import { ValidatorsModule } from '../../directive';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    DialogModule,
    NgxAmapModule,
    TabsModule,
    EchartsComponentModule,
    SfTableModule,
    CalendarModule,
    ContainerPageModule,
    SfCalendarModule,
    ValidatorsModule,
  ],
  declarations: [
    BMonitoringComponent,
    TrackComponent,
    RealTimeComponent,
    RealTimeInfoComponent,
    BicycleAnalysisComponent,
    HistoricalDataComponent,
    OperationStatisticsComponent,
    WarningMessageComponent,
    TableSheetComponent,
    VehicleDataComponent,
    DriveMotorComponent,
    ExtremeValueComponent,
    EngineDataComponent,
    VoltageDataComponent,
    TemperatureDataComponent,
    ValueDistributionComponent,
    VehicleLocationComponent,
    AlertDataComponent,
    VehicleArchivesComponent,
    CustomizeComponent,
    CustomizeAlarmComponent,
    HistoricalDataRealtimeComponent,
    AlarmTracingComponent
  ],
  entryComponents:[
    HistoricalDataRealtimeComponent,
    AlarmTracingComponent
  ],
  exports: [
    BMonitoringComponent,
    TableSheetComponent,
    RealTimeInfoComponent,
    VehicleDataComponent,
    DriveMotorComponent,
    ExtremeValueComponent,
    EngineDataComponent,
    VoltageDataComponent,
    TemperatureDataComponent,
    ValueDistributionComponent,
    VehicleLocationComponent,
    AlertDataComponent,
    AlarmTracingComponent
  ]
})
export class BMonitoringModule { }
