import { Component, EventEmitter } from '@angular/core';
import { NoticeService, SharedService } from "@service";
import { Router } from "@angular/router";
import { Md5 } from "ts-md5";

@Component({
  selector: 'modifyPasswordComponent',
  templateUrl: './modify-password.component.html',
  styles: []
})
export class modifyPasswordComponent {
  context: any;

  constructor(private noticeService: NoticeService,
    private route: Router,
    private sharedService: SharedService) {
  }

  dismiss: EventEmitter<any>;

  close() {
    this.dismiss.emit(this.context);
  }

  save() {
    this.sharedService.modifyPassword(Md5.hashStr(this.context.oldPassword), Md5.hashStr(this.context.newPassword), Md5.hashStr(this.context.newPasswordConfirm)).subscribe({
      next: result => {
        // this.noticeService.info('notice_update_success', 'success');
        this.dismiss.emit(result);
        this.route.navigateByUrl("passport/login");
      },
      error: err => {
        this.noticeService.info(err.error.errCode, "warning");
      }
    });
  }
}
