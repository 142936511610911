import {Component, OnInit, HostListener, Input, ViewChild, ElementRef, Output, EventEmitter , Inject,
  Optional,} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {
  MenuService,
  ALAIN_I18N_TOKEN,
  AlainI18NService,
  Menu,
  TitleService,
} from '@delon/theme';

@Component({
  selector: 'pro-container-page',
  templateUrl: './container-page.component.html',
  styleUrls: ['./container-page.component.less']
})
export class ContainerPageComponent implements OnInit {
  @Input() showcurdbox: boolean = true;
  @Input() showQuery: boolean = true;
  @Input() tableInfo;
  @Input() hiddenBread: boolean = false;
  @Input() tablePadding: boolean = true;
  @Input() containerPadding: boolean = true;
  tableHeight: number;
  deccurdnum: number;
  @ViewChild('box') box: ElementRef;
  @ViewChild('searchList') searchList: ElementRef;
  @ViewChild('tablebox') tablebox: ElementRef;
  @ViewChild('queryContent') queryContent: ElementRef;
  @Output() tableLoad = new EventEmitter<any>();
  private _menus: Menu[];
  paths: any[] = [];
  showSearchBox=false;
  constructor(
   private route:Router,
   private menuSrv: MenuService,
   @Optional()
   @Inject(ALAIN_I18N_TOKEN)
   private i18nSrv: AlainI18NService,
  ) {
  }

  ngOnInit() {
    if(this.queryContent.nativeElement.firstElementChild==null){
      this.showSearchBox=true;
    }
    this.contbox={
      'height':!this.hiddenBread ? '100%' : 'calc("100% - 42px")',
    }

    this._menus = this.menuSrv.getPathByUrl(this.route.url.split('?')[0]);
    this._menus.forEach(item => {
      if (typeof item.hideInBreadcrumb !== 'undefined' && item.hideInBreadcrumb)
        return;
      let title = item.text;
      if (item.i18n && this.i18nSrv) title = this.i18nSrv.fanyi(item.i18n);
      this.paths.push({ title, link: item.link && [item.link] });
    });
    this.deccurdnum = this.showSearchBox ? 0 : 0;
  }

  ngAfterContentChecked() {
    this.tableHeight = this.box.nativeElement.clientHeight
      - this.searchList.nativeElement.clientHeight-this.deccurdnum-1;
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    setTimeout(() => {
      this.tableHeight = this.box.nativeElement.clientHeight
        - this.searchList.nativeElement.clientHeight-this.deccurdnum-1;
    }, 200)
  }

  contbox={}

}
