import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 're-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  context: any;
  dismiss: EventEmitter<any>;

  constructor() {
  }

  yes() {
    this.dismiss.emit('yes');
  }

  no() {
    this.dismiss.error('no');
  }

}
