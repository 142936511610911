import { BaseService } from '@service/base.service';
import { _HttpClient } from '@delon/theme';
import { Injectable } from '@angular/core';
declare var AMap: any;

@Injectable()
export class GeocoderService extends BaseService {

  constructor() {
    super();
  }

  getLocation(loc) {
    return new Promise(function (resolve, reject) {
      AMap.plugin('AMap.Geocoder', function () {
        let geocoder = new AMap.Geocoder, address = '';
        if (loc[0] == null || loc[1] == null) {
          resolve('');
          return;
        }
        geocoder.getAddress(loc, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            address = result.regeocode.formattedAddress;
            resolve(address);
          } else {
            reject('根据经纬度查询地址失败')
          }
        })
      })
    })
  }
}
