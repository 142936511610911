import { Component, Input, OnInit, OnChanges, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-line-voltage',
  templateUrl: './line-voltage.component.html',
  styleUrls: ['./line-voltage.component.less'],
})
export class LineVoltageComponent implements OnInit, OnChanges {
  @Output() echartLoaded: EventEmitter<any> = new EventEmitter();
  @Output() echartClicked: EventEmitter<any> = new EventEmitter();
  @Output() echartDblClicked: EventEmitter<any> = new EventEmitter();
  @Input() serie: any = [];
  @Input() height: string;
  @Input() categorys: any;
  option: any;

  constructor() {
  }

  ngOnInit() {
    this.initLine();
  }

  ngOnChanges() {
    this.initLine();
  }

  initLine() {
    let option = {
      title: this.serie.title ? this.serie.title : {},
      color: this.serie.color ? this.serie.color : ['#ffdb5c','#008de1','#7fc4ec','#96efe2','#d7f47c','#c7fa81' ],
      backgroundColor: this.serie.backgroundColor ? this.serie.backgroundColor : 'transparent',
      tooltip: this.serie.tooltip ? this.serie.tooltip : {},
      legend: this.serie.legend ? this.serie.legend : {show: false},
      axisPointer: this.serie.axisPointer ? this.serie.axisPointer : {},
      grid: this.serie.grid ? this.serie.grid : {},
      xAxis: this.serie.xAxis ? this.serie.xAxis : {},
      yAxis: this.serie.yAxis ? this.serie.yAxis : {},
      calendar: this.serie.calendar ? this.serie.calendar : null,
      visualMap: this.serie.visualMap ? this.serie.visualMap : null,
      dataZoom: this.serie.dataZoom ? this.serie.dataZoom : null,
      toolbox: this.serie.toolbox ? this.serie.toolbox : null,
      globe: this.serie.globe ? this.serie.globe : null,
      series: this.serie.series ? this.serie.series : {},
      animation: this.serie.animation ? null : true
    };
    this.option = _.cloneDeep(option);
  }

  onEchartLoaded($event) {
    this.echartLoaded.emit($event);
  }

  onEchartClicked($event) {
    this.echartClicked.emit($event);
  }

  onEchartDblClicked($event){
    this.echartDblClicked.emit($event);
  }
}
