import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfTreeTableComponent } from './sf-tree-table.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {TreeTableModule} from 'primeng/treetable';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    TreeTableModule,
  ],
  declarations: [SfTreeTableComponent],
  exports: [SfTreeTableComponent]
})
export class SfTreeTableModule { }
