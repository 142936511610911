import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
    selector: 'app-gauge',
    templateUrl: './gauge.component.html',
    styleUrls: ['./gauge.component.less']
})
export class GaugeComponent implements OnInit, OnChanges {
    @Input() seriesData1: any = [];
    @Input() seriesData2: any = [];
    @Input() title?: any = [];
    @Input() color?: any = [];
    @Input() isShowAxis:boolean = true;
    @Input() height:number = 180;
    @Input() unit:string = '%';
    option: any;
    constructor() { }

    ngOnInit() {
        this.initGauge()
    }
    ngOnChanges() {
        this.initGauge();
    }
    initGauge() {
        let opt = {
          // tooltip : {
          //   formatter: "{a} <br/>{c} {b}"
          // },
          series : [
            {
              name: '速度',
              type: 'gauge',
              z: 3,
              min: 0,
              max: 220,
              top:10,
              splitNumber: 11,
              radius: '110',
              center: ['220', '130'],    // 默认全局居中
              axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                  width: 10
                }
              },
              axisTick: {            // 坐标轴小标记
                length: 15,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
                }
              },
              splitLine: {           // 分隔线
                length: 20,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
                }
              },
              axisLabel: {
                backgroundColor: 'auto',
                borderRadius: 2,
                color: '#eee',
                padding: 3,
                textShadowBlur: 2,
                textShadowOffsetX: 1,
                textShadowOffsetY: 1,
                textShadowColor: '#222'
              },
              title : {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontWeight: 'bolder',
                fontSize: 20,
                fontStyle: 'italic'
              },
              detail : {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                // formatter: function (value) {
                //   value = (value + '').split('.');
                //   value.length < 2 && (value.push('00'));
                //   return ('00' + value[0]).slice(-2)
                //     + '.' + (value[1] + '00').slice(0, 2);
                // },
                borderRadius: 3,
                backgroundColor: '#444',
                borderColor: '#aaa',
                shadowBlur: 5,
                shadowColor: '#333',
                shadowOffsetX: 0,
                shadowOffsetY: 3,
                borderWidth: 2,
                textBorderColor: '#000',
                textBorderWidth: 2,
                textShadowBlur: 2,
                textShadowColor: '#fff',
                textShadowOffsetX: 0,
                textShadowOffsetY: 0,
                fontFamily: 'Arial',
                width: 48,
                color: '#eee',
                rich: {}
              },
              data:this.seriesData2,
            },
            {
              name: '转速',
              type: 'gauge',
              center: ['70', '150'],    // 默认全局居中
              radius: '80',
              min:0,
              max:7,
              right:10,
              endAngle:45,
              splitNumber:7,
              axisLine: {            // 坐标轴线
                lineStyle: {       // 属性lineStyle控制线条样式
                  width: 8
                }
              },
              axisTick: {            // 坐标轴小标记
                length:12,        // 属性length控制线长
                lineStyle: {       // 属性lineStyle控制线条样式
                  color: 'auto'
                }
              },
              splitLine: {           // 分隔线
                length:20,         // 属性length控制线长
                lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                  color: 'auto'
                }
              },
              pointer: {
                width:5
              },
              title: {
                offsetCenter: [0, '-30%'],       // x, y，单位px
              },
              detail: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontWeight: 'bolder',
                fontSize:15
              },
              data:this.seriesData1,
            },

          ]
        };
        this.option = opt;
    }
}
