import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges, forwardRef, ViewChild,ElementRef} from '@angular/core';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'sf-form-label',
  templateUrl: './sf-form-label.component.html',
  styleUrls: ['./sf-form-label.component.less']
})
export class SfFormLabelComponent implements OnInit  {
  @Input() nzSm = 8;
  @Input() sfRequired = false;

  constructor(
    private http: _HttpClient,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    let el=this.elementRef.nativeElement;
    el.classList.add(`ant-col-${this.nzSm}`);
  }

  ngAfterContentChecked() {

  }



}
