import {
  Component,
  QueryList,
  ContentChildren,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterContentInit, ElementRef, HostListener
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 're-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less'],
  exportAs: 'tabs'
})
export class TabsComponent implements AfterContentInit {
  @Input() type: 'tabs' | 'pills' = 'tabs';
  @Input() activeTab: number | string;
  @Input() vertical: boolean;
  @Input() justified: boolean;
  @Input() cssClass: string;
  @Input() memory: boolean=false;
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() activeTabChange = new EventEmitter<number | string>();

  private contentHeight;
  constructor() {
    this.type = 'tabs';
    this.justified = false;
    this.vertical = false;
  }

  ngAfterContentInit(): void {
    if (!this.activeTab && this.tabs.length) {
      this.select(this.tabs.first.id);
    }
  }

  select(id: number | string) {
    const tab = this.tabs.find(item => item.id === id);
    if (tab && !tab.disabled) {
      this.activeTab = id;
      this.activeTabChange.emit(id);
    }
  }

}
