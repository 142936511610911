import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerPageComponent } from './container-page.component';
import { HeaderBreadcrumbModule } from '../header-breadcrumb/header-breadcrumb.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    HeaderBreadcrumbModule
  ],
  declarations: [ContainerPageComponent],
  exports: [ContainerPageComponent]
})
export class ContainerPageModule { }
