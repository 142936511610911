import {Injectable, Injector, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN} from '@delon/theme';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {ACLService} from '@delon/acl';
import {TranslateService} from '@ngx-translate/core';
import {I18NService} from '../i18n/i18n.service';

import {NzIconService} from 'ng-zorro-antd';
import { filterMenus } from '@shared/utils/menu.util';

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private httpClient: HttpClient,
    private injector: Injector
  ) {
    // iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
    iconSrv.fetchFromIconfont({
      scriptUrl: '//at.alicdn.com/t/font_1058810_15x3fhmse0o.js'
    });
  }

  private viaHttp() {
    return this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`).toPromise()
    .then(langData => {
      this.translate.setTranslation(this.i18n.defaultLang, langData);
      this.translate.setDefaultLang(this.i18n.defaultLang);
      // 设置页面标题的后缀
      this.titleService.suffix = this.translate.instant("app.name");
    }).then(() => this.httpClient.get('/system/accounts/current').toPromise())
    .then(userinfo => {
        // 用户信息：包括姓名、头像、邮箱地址
        this.settingService.setUser(userinfo);
        // ACL：设置权限
        this.aclService.setFull(false);
        if(userinfo && userinfo["authorizes"]) {
            let ids = [];
            userinfo["authorizes"].forEach(element => {
              let id = + element.id;
              ids.push(id);
            });
            
            this.aclService.setAbility(ids);
            // 初始化菜单
    
            this.menuService.add(filterMenus(userinfo["authorizes"]));
        }
    });
  }

  private viaMockI18n(resolve: any, reject: any) {
    this.httpClient
      .get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`)
      .subscribe(langData => {
        this.translate.setTranslation(this.i18n.defaultLang, langData);
        this.translate.setDefaultLang(this.i18n.defaultLang);

        this.viaMock(resolve, reject);
      });
  }

  private viaMock(resolve: any, reject: any) {
    // const tokenData = this.tokenService.get();
    // if (!tokenData.token) {
    //   this.injector.get(Router).navigateByUrl('/passport/login');
    //   resolve({});
    //   return;
    // }
    // mock
    const app: any = {
      name: `ng-alain`,
      description: `Ng-zorro admin panel front-end framework`
    };
    const user: any = {
      name: 'TZ001',
      avatar: './assets/tmp/img/avatar.png',
      email: 'cipchk@qq.com',
      token: '123456789'
    };

    // this.settingService.setLayout('collapsed',true);
    // 应用信息：包括站点名、描述、年份
    this.settingService.setApp(app);
    // 用户信息：包括姓名、头像、邮箱地址
    this.settingService.setUser(user);
    // ACL：设置权限为全量
    this.aclService.setFull(true);
    // 初始化菜单
    this.menuService.add([

    ]);
    // 设置页面标题的后缀
    this.titleService.suffix = app.name;

    resolve({});
  }

  load(): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return this.viaHttp();;
  }
}
