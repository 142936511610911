import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, forwardRef, ViewChild} from '@angular/core';
import {_HttpClient} from '@delon/theme';
import * as _ from 'lodash';
import {BMonitoringService, LoadingService} from '@service';
import {HttpResponse} from '@angular/common/http';
import {formatTimesTemplete} from "@shared/utils";
import {DialogService} from "../../modal/dialog.service";

@Component({
  selector: 'value-distribution',
  templateUrl: './value-distribution.component.html',
  styleUrls: ['./value-distribution.component.less']
})
export class ValueDistributionComponent implements OnInit {
  lineChart1 = {};
  lineChart2 = {};
  @Input() vin: any;
  public queryParam = {
    startDate: '',
    endDate: '',
  };

  constructor(private http: _HttpClient,
              private loadingService: LoadingService,
              private bMonitoringService: BMonitoringService,
              private dialogService: DialogService,) {
  }


  ngOnInit() {
    this.initLineChart1([], [], []);
    this.initLineChart2([], [], []);
  }

  initLineChart1(volMaxData, volMinData, dateData) {
    let data = {
      tooltip: {
        trigger: 'axis',
        formatter: function (param) {
          return param[0].axisValue+"<br>" + "单体电池最高电压：" + param[0].data.value + "<br>" + "最高电压编号："+param[0].data.index+ "<br>"
            + "单体电池最低电压：" + param[1].data.value + "<br>" + "最低电压编号："+param[1].data.index;
        }
      },
      legend: {
        data: ['单体电池最高电压', '单体电池最低电压'],
        bottom: 0,
      },
      grid: {
        left: '5%',
        right: '6%',
        bottom: '9%',
        top: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dateData,
        axisLabel: {
          fontSize: 10,
        }
      },
      yAxis: {
        type: 'value',
        name: '电压(V)',
      },
      series: [
        {
          name: '单体电池最高电压',
          type: 'line',
          smooth: true,
          data: volMaxData,
          itemStyle: {
            normal: {
              color: '#F4B8BC'
            }
          }
        },
        {
          name: '单体电池最低电压',
          type: 'line',
          smooth: true,
          data: volMinData,
          itemStyle: {
            normal: {
              color: '#A5E2EA'
            }
          }
        },

      ]
    };

    this.lineChart1 = _.cloneDeep(data);
  }

  initLineChart2(tmpMaxData, tmpMinData, dateData) {
    let data = {
      tooltip: {
        trigger: 'axis',
        formatter: function (param) {
          return param[0].axisValue+"<br>" + "单体电池最高温度：" + param[0].data.value + "<br>" + "最高温度编号："+param[0].data.index+ "<br>"
            + "单体电池最低温度：" + param[1].data.value + "<br>" + "最低温度编号："+param[1].data.index;
        }
      },
      legend: {
        data: ['单体电池最高温度', '单体电池最低温度'],
        bottom: 0,
      },
      grid: {
        left: '5%',
        right: '6%',
        bottom: '9%',
        top: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dateData,
        axisLabel: {
          fontSize: 10,
        }
      },
      yAxis: {
        type: 'value',
        name: '温度(℃)',
      },
      series: [
        {
          name: '单体电池最高温度',
          type: 'line',
          smooth: true,
          data: tmpMaxData,
          itemStyle: {
            normal: {
              color: '#F4B8BC'
            }
          },
        },
        {
          name: '单体电池最低温度',
          type: 'line',
          smooth: true,
          data: tmpMinData,
          itemStyle: {
            normal: {
              color: '#A5E2EA'
            }
          }
        },

      ]
    };

    this.lineChart2 = _.cloneDeep(data);
  }

  query() {
    let flag_1 = false;
    let flag = false;
    let param = _.cloneDeep(this.queryParam);
    if ("" == param.startDate || "" == param.endDate) {
      flag_1 = true;
      this.dialogService.confirm<string>({
        resolve: {
          content: '开始时间和结束时间都不能为空！'
        }
      }).subscribe(data => {
      }, error => {
      });
    }
    if (flag_1) {
      return;
    }
      if ((param.endDate - param.startDate) / 1000 / 3600 / 24 > 7) {
        flag = true;
        this.dialogService.confirm<string>({
          resolve: {
            content: '时间差不能超过七天！'
          }
        }).subscribe(data => {
        }, error => {
        });
      }

    if (flag) {
      return;
    }
    this.loadingService.show();
    this.bMonitoringService.getEm(this.vin, this.queryParam).subscribe({
      next: (res: HttpResponse<any>) => {
        let volMaxData = [];
        let volMinData = [];
        let tmpMaxData = [];
        let tmpMinData = [];
        let dateData = [];
        if (res) {
          let result = _.cloneDeep(res.body.reverse());
          for (let i = 0; i < result.length; i++) {
            dateData.push(formatTimesTemplete(result[i].TIME, "/"));
            let tempMaxVol = {};
            let tempMinVol = {};
            tempMaxVol['value'] = result[i].MDT_P_MAX_VOL_CEL_VLU;
            tempMaxVol['index'] = result[i].MDT_P_MAX_VOL_CEL_SN;
            tempMinVol['value'] = result[i].MDT_P_MIN_VOL_CEL_VLU;
            tempMinVol['index'] = result[i].MDT_P_MIN_VOL_CEL_SN;
            volMaxData.push(tempMaxVol);
            volMinData.push(tempMinVol);

            let tempMaxTmp = {};
            let tempMinTmp = {};
            tempMaxTmp['value'] = result[i].MDT_P_MAX_TEM;
            tempMaxTmp['index'] = result[i].MDT_P_MAX_TEM_NDL_SN;
            tempMinTmp['value'] = result[i].MDT_P_MIN_TEM;
            tempMinTmp['index'] = result[i].MDT_P_MIN_TEM_NDL_SN;
            tmpMaxData.push(tempMaxTmp);
            tmpMinData.push(tempMinTmp);
          }
        }
        this.initLineChart1(volMaxData, volMinData, dateData);
        this.initLineChart2(tmpMaxData, tmpMinData, dateData);
        this.loadingService.hide();
      }, error: err => {
        this.loadingService.hide();
      }
    });
  }

}
