import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable()
export class HistoryDataService extends BaseService {

  moduleName = this.baseUrl + '/historicalData';

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/taskList`, query, { observe: "response" });
  }

  taskDetailPageQuery(query, pageIndex, pageSize, id): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/taskVehiclesList/${id}`, query, { observe: "response" });
  }

  addTask(param): Observable<any> {
    param = this.trimFormData(param, ["taskName"]);
    return this._http.post(`${this.moduleName}/addTask`, param);
  }

  queryAllVehiclesCount(): Observable<any> {
    return this._http.get('/dashboard/vehiclesCount');
  }

  queryAllModelList(): Observable<any> {
    return this._http.get(`${this.moduleName}/model/dropDownListInfo`);
  }

  queryAllOrgan(): Observable<any> {
    return this._http.get('/system/organizations/dropDownListInfo');
  }

  pageQueryRealtime(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/realtime`, query, { observe: "response" });
  }



  queryAllRealtime(): Observable<any> {
    return this._http.get(`${this.moduleName}/realtime/all`);
  }

  queryAllForwardData(): Observable<any> {
    return this._http.get(`${this.moduleName}/data/all`);
  }

  pageQueryUnboundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/unbound/${id}`, query, { observe: "response" });
  }

  pageQueryBoundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/bound/${id}`, query, { observe: "response" });
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }


  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}/${ids}`);
  }

  run(id): Observable<any> {
    return this._http.get(`${this.moduleName}/run/${id}`, {});
  }

  stop(id): Observable<any> {
    return this._http.get(`${this.moduleName}/stop/${id}`, {});
  }

  bind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/bind/${id}`, vehicleIds);
  }

  unbind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/unbind/${id}`, vehicleIds);
  }

}
