import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfDialogComponent } from './sf-dialog.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';


@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    DialogModule,
  ],
  declarations: [SfDialogComponent],
  exports: [SfDialogComponent]
})
export class SfDialogModule { }
