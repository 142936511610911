import { Component, OnInit, Input, OnChanges } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-histogram-group',
  templateUrl: './histogram-group.component.html',
  styleUrls: ['./histogram-group.component.less']
})
export class HistogramGroupComponent implements OnChanges {

  @Input() serie: any = [];
  @Input() height: number;

  option: any;
  constructor() { }

  ngOnChanges() {
    this.initHistogram();
  }

  initHistogram() {
    let tempOption = {
      baseOption: {
        timeline: {
          left: '10%',
          right: '10%',
          bottom: -15,
          height: 30,
          symbolSize: 9,
          currentIndex: this.serie.years.length - 1,
          itemStyle: {
            emphasis: {
              color: '#4FC2F6'
            }
          },
          lineStyle: {
            color: '#919297',
            type: 'dashed',
            width: 1
          },
          label: {
            normal: {
              textStyle: {
                fontSize: 8,
                color: '#919297',
              },
              position: 'bottom'
            },
            emphasis: {
              textStyle: {
                color: '#919297',
              }
            }
          },
          controlStyle: {
            show: false,
          },
          checkpointStyle: {
            color: '#4FC2F6',
            borderWidth: 0
          },
          axisType: 'category',
          autoPlay: false,
          data: this.serie.years
        },
        grid: {
          left: '5%',
          right: '4%',
          bottom: '10%',
          top:'2%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.serie.xAxis,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              color: '#F9FAFA',
              fontSize: 10,
              formatter: function (value, index) {
                if (index % 2 != 0) {
                  return '\n' + value;
                }
                else {
                  return value;
                }
              }
            },
            axisLine: {
              lineStyle: {
                color: '#3C5076',
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.serie.unit ? this.serie.unit : '',
            axisLine: {
              lineStyle: {
                color: '#3C5076',
              }
            },
            axisLabel: {
              color: '#F9FAFA',
              fontSize: 10
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#3C5076',
                width: 1
              }
            }
          }
        ],
        series: [
          { // 系列一的一些其他配置
            type: 'bar',
            barWidth: '25%',
            itemStyle: {
              normal: {
                color: '#4FC2F6',
              }
            }
          }
        ]
      },
      options: []
    };
    this.serie.years.forEach(year => {
      tempOption.options.push(
        {
          series: [{
            data: this.serie.data[year]
          }]
        }
      );
    });
    this.option = _.cloneDeep(tempOption);
  }
}
