import {
  AfterViewInit,
  Component,
  Input,
  OnInit, OnDestroy,
  Optional,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import { MessageResolver } from '../../core/message/message-resolver.service';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.less'],
  exportAs: 'fieldError',
})
export class FieldErrorComponent implements OnInit, OnDestroy {
  @Input() field: string;
  @Input() label: string = null;
  @Input() width: string = '180px';
  _label: string = null;
  fromGroup: FormGroup;

  langChange$:Subscription = null;

  constructor(
    @Optional() private ngForm: NgForm,
    @Optional() private formGroupDirective: FormGroupDirective,
    private messageResolver: MessageResolver,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.fromGroup = this.ngForm
      ? this.ngForm.form
      : this.formGroupDirective.form;
    this.translate.get(this.label).subscribe(res => this._label = res);
    this.langChange$ = this.translate.onLangChange.subscribe(((event: TranslationChangeEvent) => {
      this.translate.get(this.label).subscribe(res => {this._label = res});
    }));
  }

  get invalid() {
    if (this.fromGroup) {
      const control = this.fromGroup.controls[this.field];
      return control ? control.touched && control.invalid : false;
    }
    return false;
  }

  getErrorMessage() {
    const control = this.fromGroup.controls[this.field];
    //初始不触发
    if(!control.dirty){
      return
    };

    if (control && control.errors) {
      const key = Object.keys(control.errors)[0]; // only show first error.
      return this.messageResolver.getValidationMessage(this.field, key, {
        label: this._label || this.field,
        ...control.getError(key),
      });
    }
  }

  ngOnDestroy() {
    this.langChange$.unsubscribe();
  }
}
