import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { convertTil, convertVal } from '@shared/utils/util.lang';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'engine-data',
  templateUrl: './engine-data.component.html',
  styleUrls: ['./engine-data.component.less'],
})
export class EngineDataComponent implements OnInit, OnDestroy, OnChanges {
  fdjData: any = [
    [
      { title: '发动机状态', content: '' },
      { title: '曲轴转速(r/min)', content: '' },
      { title: '燃料消耗率(L/100km)', content: '' },
    ],
  ];
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }


  tag = 'MDT_EN_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    this.fdjData = [
      [
        { title: '发动机状态', content: convertDate(oriDate.MDT_EN_STA, 'MDT_EN_STA', rule) },
        { title: '曲轴转速(r/min)', content: convertDate(oriDate.MDT_EN_CRANKSHAFT, 'MDT_EN_CRANKSHAFT', rule) },
        { title: '燃料消耗率(L/100km)', content: convertDate(oriDate.MDT_EN_FUEL_COST_RATE, 'MDT_EN_FUEL_COST_RATE', rule) },
      ],
    ];
    this.cd.markForCheck();
  }


  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.fdjData = [
          [
            { title: '发动机状态', content: '' },
            { title: '曲轴转速(r/min)', content: '' },
            { title: '燃料消耗率(L/100km)', content: '' },
          ],
        ];
        return
      }
      let oriDate=this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }

}
