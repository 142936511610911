import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';

@Injectable()
export class ChargingStationService extends BaseService {

  moduleName = this.baseCharging + `/stations`

  constructor(private _http: _HttpClient) {
    super();
  }

  getList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, { observe: "response" });
  }

  getStationOptions() {
    return this._http.get(`${this.moduleName}/names`);
  }

  getAreaOptions() {
    return this._http.get(`${this.moduleName}/areas`);
  }
}
