import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponseBase,
  HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { I18NService } from '@core/i18n/i18n.service';
import * as _ from 'lodash';

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  loginUrl = "/passport/login";

  constructor(private injector: Injector) { }

  get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if (ev.status == 401) return;// 401 登录失败，
    if (ev.status >= 200 && ev.status < 300) return;
    this.showErrorMessage(ev);
  }

  private showErrorMessage(ev: HttpResponseBase) {
    let errortext = CODEMESSAGE[ev.status] || ev.statusText;
    if (ev["error"]) {
      const error = ev["error"];
      if (error.errorCode) {
        errortext = this.injector.get(I18NService).fanyi(error.errorCode);
      } else if (error.parameters) {
        errortext = this.injector.get(I18NService).fanyi(error.parameters["message"], {"key": error.parameters["params"]});
      }
    }
    this.injector.get(NzMessageService).error(
      errortext
    );
  }

  private isLoginPage() {
    return this.loginUrl == this.injector.get(Router).url;
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    if (ev.status > 0) {
      this.injector.get(_HttpClient).end();
    }
    this.checkStatus(ev);
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        break;
      case 400: // 未登录状态码
        return throwError(ev);

      case 401: // 未登录状态码
        if (!this.isLoginPage()) {
          // 请求错误 401: https://preview.pro.ant.design/api/401 用户没有权限（令牌、用户名、密码错误）。
          (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
          this.goTo(this.loginUrl);
        } else {
          return throwError(ev);
        }
        break;
      case 403:
      case 404:
      case 500:
        // this.goTo(`/exception/${ev.status}`);
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          // console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://') && url.indexOf("assets/") == -1) {
      url = environment.SERVER_URL + url;
    }

    // const newReq = req.clone({ url });
    let params = req.params;
    let _params = new HttpParams();
    params.keys().map(key => {
      let values: any[] = params.getAll(key);
      if (values) {
        values.map(item => {
          if (item) {
            item = _.trim(item)
          }
          if(item === 0) {
            _params = _params.append(key, item);
          }
          if (item && item != "") {
            _params = _params.append(key, item);
          }
        })
      }
    })

    const newReq = req.clone({
      url: url,
      params: _params
    });
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase)
          return this.handleData(event);
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
}
