import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';


@Injectable()
export class DashboardService extends BaseService {

  moduleName = this.baseUrl + '/dashboard';
  chargingName = this.baseCharging + '/dashboard';

  constructor(private _http: _HttpClient) {
    super();
  }

  queryAllVehiclesCount(query): Observable<any> {
    return this._http.get(`${this.moduleName}/vehiclesCount`, query, { observe: "response" });
  }

  getDashboard(vin): Observable<any> {
    return this._http.get(`${this.moduleName}/${vin}`);
  }

  queryAllMapsInfo(query): Observable<any> {
    return this._http.get(`${this.moduleName}/mapsInfo`, query, { observe: "response" });
  }

  queryAllModelList(): Observable<any> {
    return this._http.get(`${this.moduleName}/model/dropDownListInfo`);
  }

  queryAllOrgan(): Observable<any> {
    return this._http.get('/system/organizations/dropDownListInfo');
  }

  pageQueryRealtime(query, pageIndex, pageSize): Observable<any> {
    const params = {
      ...query,
      page: pageIndex,
      limit: pageSize
    }
    return this._http.get(`${this.moduleName}/realtime`, params, { observe: "response" });
  }



  queryAllRealtime(): Observable<any> {
    return this._http.get(`${this.moduleName}/realtime/all`);
  }

  queryAllForwardData(): Observable<any> {
    return this._http.get(`${this.moduleName}/data/all`);
  }

  pageQueryUnboundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    const params = {
      ...query,
      page: pageIndex,
      limit: pageSize
    }
    return this._http.get(`${this.moduleName}/vehicles/unbound/${id}`, params, { observe: "response" });
  }

  pageQueryBoundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    const params = {
      ...query,
      page: pageIndex,
      limit: pageSize
    }
    return this._http.get(`${this.moduleName}/vehicles/bound/${id}`, params, { observe: "response" });
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }


  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}/${ids}`);
  }

  run(id): Observable<any> {
    return this._http.get(`${this.moduleName}/run/${id}`, {});
  }

  stop(id): Observable<any> {
    return this._http.get(`${this.moduleName}/stop/${id}`, {});
  }

  bind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/bind/${id}`, vehicleIds);
  }

  unbind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/unbind/${id}`, vehicleIds);
  }

  pageQueryVehicles(query, pageIndex, pageSize): Observable<any> {
    const params = {
      ...query,
      page: pageIndex,
      limit: pageSize
    }
    return this._http.get(`${this.moduleName}/pageQueryVehicles`, params, {observe: "response"});
  }

  getChargingEquipmentList(query, pageIndex, pageSize) {
    const params = {
      ...query,
      page: pageIndex,
      limit: pageSize
    }
    return this._http.get(`${this.chargingName}/equipments`, params, {observe: "response"});
  }

  getChargingStatistics(query) {
    return this._http.get(`${this.chargingName}/statistics`, query);
  }

  getChargingMapInfo(query) {
    return this._http.get(`${this.chargingName}/gis`, query);
  }

  getChargingStationInfo(stationId) {
    return this._http.get(`${this.chargingName}/stations/${stationId}`);
  }
}
