import { NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { NgxAmapModule } from 'ngx-amap';
import { I18NService } from './i18n/i18n.service';
import { MessageResolver } from './message/message-resolver.service';
import {
  NoticeService,
  LoadingService,
  VehicleMonitoringService,
  AlarmInfoService,
  RemoteControlService,
  AnalyseService,
  OrgManageService,
  DashboardService,
  SharedService,
  HistoryDataService,
  MessageQueryService,
  BMonitoringService,
  BMonitoringTrackService,
  GeocoderService,
  ChargingOperatorService,
  ChargingStationService,
  ChargingEquipmentService,
  ChargingConnectorService,
  AlarmPileInfoService
} from '@service';
import { ModalConfig } from '../components/modal/modal.config';
import { TokenService } from '@delon/auth';



@NgModule({
  imports: [
    NgxAmapModule.forRoot({
      apiKey: '0782d1639bd5b56eabe0fd38afcf42c0',
      debug: true
    })
  ],
  providers: [
    I18NService,
    MessageResolver,
    NoticeService,
    LoadingService,
    VehicleMonitoringService,
    AlarmInfoService,
    RemoteControlService,
    AnalyseService,
    OrgManageService,
    SharedService,
    DashboardService,
    HistoryDataService,
    MessageQueryService,
    BMonitoringService,
    GeocoderService,
    BMonitoringTrackService,
    ChargingOperatorService,
    ChargingStationService,
    ChargingEquipmentService,
    ChargingConnectorService,
    TokenService,
    AlarmPileInfoService,
    { provide: ModalConfig, useClass: ModalConfig },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
