import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-mix-line-bar',
  templateUrl: './mix-line-bar.component.html',
  styleUrls: ['./mix-line-bar.component.less'],
})
export class MixLineBarComponent implements OnChanges {

  @Input() serie: any = [];
  @Input() height: string;
  @Input() legend: boolean = true;

  option: any;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serie && changes.serie.firstChange) return;
    console.log('this.serie', this.serie, '')
    this.initHistogram();
  }

  initHistogram() {
    let tempOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
      },
      color: this.serie.color,
      timeline: this.serie.timeline ? this.serie.timeline : null,
      legend: {
        data: this.serie.legend && this.serie.legend.data ? this.serie.legend.data : [],
        textStyle: { color: 'rgba(0,0,0,0.65)' },
        show: this.legend,
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        top: '10%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: this.serie.xAxis ? this.serie.xAxis.data : [],
          splitLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9',
            },
          },
          axisLabel: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 12,
            // interval: 0,
            rotate: this.serie.axisLabelXRotate ? this.serie.axisLabelXRotate : 0,
          },
        },
      ],
      yAxis: [
        {
          type: this.serie.yAxis ? this.serie.yAxis[0].type : [],
          data: this.serie.yAxis ? this.serie.yAxis[0].data : [],
          name: this.serie.yAxis ? this.serie.yAxis[0].name : [],
          nameTextStyle: {
            color: 'rgba(0,0,0,0.65)',
          },
          max: this.serie.yAxis ? this.serie.yAxis[0].max : [],
          min: this.serie.yAxis ? this.serie.yAxis[0].min : [],
          interval: this.serie.yAxis ? this.serie.yAxis[0].interval : [],
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0)',
            },
          },
          axisLabel: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 12,
            formatter: (this.serie.yAxis && this.serie.yAxis[0].axisLabel) ? this.serie.yAxis[0].axisLabel.formatter : null,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E8E8E8',
              width: 1,
              type: 'dashed',
            },
          },
        },
        {
          type: this.serie.yAxis ? this.serie.yAxis[1].type : [],
          data: this.serie.yAxis ? this.serie.yAxis[1].data : [],
          name: this.serie.yAxis ? this.serie.yAxis[1].name : [],
          nameTextStyle: {
            color: 'rgba(0,0,0,0.65)',
          },
          max: this.serie.yAxis ? this.serie.yAxis[1].max : [],
          min: this.serie.yAxis ? this.serie.yAxis[1].min : [],
          interval: this.serie.yAxis ? this.serie.yAxis[1].interval : [],
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0)',
            },
          },
          axisLabel: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 12,
            formatter: (this.serie.yAxis && this.serie.yAxis[1].axisLabel) ? this.serie.yAxis[1].axisLabel.formatter : null,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E8E8E8',
              width: 1,
              type: 'dashed',
            },
          },
        },
      ],
      series: this.serie.series,
    };
    this.option = _.cloneDeep(tempOption);
  }
}
