import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';

export interface ElectricDriverModel {
  modelCode: string;
  enginePosition: string;
  coolDownMethod: string;
  ratedVoltage: string
  peakPower: string;
  maxRotateSpeed: string;
  peakRotatePower: string;
  maxCurrent: string;
  maxRotatePower: string;
  remark: string;
}

@Injectable()
export class ElectricDriverModelService extends BaseService {

  moduleName = this.baseUrl + `/electric/driver/model`

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, {observe: "response"});
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  create(obj: ElectricDriverModel): Observable<any> {
    obj = this.trimFormData(obj, ["modelCode", "enginePosition", "ratedVoltage", "peakPower", "maxRotateSpeed", "peakRotatePower", "maxCurrent","maxRotatePower","remark"]);
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj: ElectricDriverModel): Observable<any> {
    obj = this.trimFormData(obj, ["modelCode", "enginePosition", "ratedVoltage", "peakPower", "maxRotateSpeed", "peakRotatePower", "maxCurrent","maxRotatePower","remark"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}`,{"ids": ids});
  }

}
