import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';

export interface VehicleModel {
  itemName: string;
  configureName: string;
  contractCode: string;
  maxSpeed:string;
  batteryType:string;
  powerType:string;
  electricDriverType:string;
  enduranceCheck:string;
  electricEndurance:string;
  ratedVoltage:string;
  totalStorage:string;
  packLinkMethod:string;
}

@Injectable()
export class VehicleModelService extends BaseService {

  moduleName = this.baseUrl + `/vehicles/model`

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, {observe: "response"});
  }

  queryAllStoragelList(): Observable<any> {
    return this._http.get(this.baseUrl +`/energy/storage/model/dropDownListInfo`);
  }

  queryAllDriverList(): Observable<any> {
    return this._http.get(this.baseUrl +`/electric/driver/model/dropDownListInfo`);
  }

  queryAllEngineList(): Observable<any> {
    return this._http.get(this.baseUrl +`/engine/model/dropDownListInfo`);
  }
  queryAllTerminalList(): Observable<any> {
    return this._http.get(this.baseUrl +`/terminal/model/dropDownListInfo`);
  }

  queryTerminalByModel(model): Observable<any> {
    return this._http.get(this.baseUrl +`/terminal/model/dropDownListInfo/${model}`);
  }
  
  queryAllInfo(itemName): Observable<any> {
    return this._http.get(`${this.moduleName}/getAllInfo`,{"itemName":itemName});
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  create(obj: VehicleModel): Observable<any> {
    obj = this.trimFormData(obj, ["configureName", "itemName", "contractCode","maxSpeed","electricEndurance","ratedVoltage","totalStorage","packLinkMethod"]);
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj: VehicleModel): Observable<any> {
    obj = this.trimFormData(obj, ["configureName", "itemName", "contractCode","maxSpeed","electricEndurance","ratedVoltage","totalStorage","packLinkMethod"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}`, {"ids": ids});
  }

}
