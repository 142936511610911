import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnDestroy {
  static MODEL_OPEN_CSS = 'modal-open';
  @Input() isOpen = false;
  @Input() maximizable = true;
  @Output() dismiss = new EventEmitter<any>();
  @Input() modalOptions;
  instanceCount = 0;
  modalContentRef;
  width = 'auto';
  height = 'auto';
  title = 'title';
  animateState: string = 'void';
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild('pDialog') pDialog: ViewContainerRef;


  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {

  }

  modelDom;

  ngAfterViewInit() {
    this.modelDom = this.el.nativeElement.querySelector('p-dialog');
    this.modelDom.addEventListener('click', this.modelClick.bind(this))
  }

  modelClick(event) {
    if (event.target.nodeName == 'svg') {
      return
    }
    if (event.target.className.indexOf('pi-window-minimize') >= 0) {
      let body = this.modelDom.querySelector('.model-body');
      if (body == null) {
        return;
      }
      let foot = this.modelDom.querySelector('.model-foot');
      if (foot) {
        let height = window.getComputedStyle(foot).height;
        body.style.height = `calc(100% - ${height})`;
      } else {
        body.style.height = '100%';
      }


      var myEvent = new Event('resize');
      window.dispatchEvent(myEvent);
      console.log('myEvent');
    }
    ;
  }

  ngOnDestroy() {
    this.modelDom.removeEventListener('click', this.modelClick)
  }

  open() {
    // this.animateState = 'in';
    this.isOpen = true;
  }

  close() {
    // this.animateState = 'void';
    this.isOpen = false;
    this.dismiss.error({});
  }

  addContent<T>(options, instanceCount: number): EventEmitter<T> {
    this.modalOptions = options;
    this.instanceCount = instanceCount;

    const componentFactoryResolver = options.componentFactoryResolver;
    const componentFactory = componentFactoryResolver.resolveComponentFactory(options.component);
    this.modalContentRef = this.container
      .createComponent(componentFactory);
    const instance = this.modalContentRef.instance;
    instance.dismiss = this.dismiss;
    this.handleResolve(options, instance);
    return this.dismiss;
  }

  private handleResolve(options, instance) {
    const resolve = options.resolve || {};
    if (resolve.then) {
      resolve.then(data => instance.context = data);
    } else if (resolve.subscribe) {
      resolve.subscribe(data => instance.context = data);
    } else {
      instance.context = resolve;
    }
  }

}
