import {Component, OnInit, ViewChild, TemplateRef, Inject,ChangeDetectorRef} from '@angular/core';
import {
  BMonitoringService,
  GeocoderService,
  HistoryInfoService,
  LoadingService,
  NoticeService,
  SharedService,
} from '@service';
import * as _ from 'lodash';
import {formatDateTime, parseQueryStr} from "@shared/utils";
import {forkJoin} from "rxjs/index";
import {DA_SERVICE_TOKEN, ITokenService} from "@delon/auth";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-alarm-tracing',
  templateUrl: './alarm-tracing.component.html',
  styleUrls: ['./alarm-tracing.component.less']
})
export class AlarmTracingComponent implements OnInit {
  public queryParam = {
    keys:{}
  };
  tab = {activeId: 'upgrade-package'};
  context: any;
  warningBaseInfo = [];
  checkOptionsWorkDataType = [];

  public selectedItem = [];
  public originalColumns = [];
  public columns = [];
  public data = {};
  public map = [];
  public pageIndex = 1;
  public pageSize = 10;
  public interval = 10;
  public tableData = [];
  allTableData = [];
  bigData = [];
  public total = 1;
  public loading = false;
  warningIndex = -1;
  exportFileUrl;
  arr = [];

  constructor(
    // private modalService: ModalService,
              private loadingService: LoadingService,
              private shareService: SharedService,
              private sanitizer: DomSanitizer,
              private historyService:HistoryInfoService,
              private cd: ChangeDetectorRef,
              private geocoder: GeocoderService,
              private bMonitoringService: BMonitoringService,
              @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
              private noticeService: NoticeService,) {
  }

  @ViewChild('timeTemplate') timeTemplate: TemplateRef<any>;
  @ViewChild('alarmTypeTemplate') alarmTypeTemplate: TemplateRef<any>;
  @ViewChild('alarmStatusTemplate') alarmStatusTemplate: TemplateRef<any>;
  @ViewChild('tree') tree;

  nodes = [];
  defaultCheckedKeys = [];

  getTreeList(){
    this.historyService.getTreeList().subscribe((res)=>{
      this.nodes=_.cloneDeep(res);
      this.defaultCheckedKeys.push(this.nodes[0].key);
      this.defaultCheckedKeys.push(this.nodes[1].key);
      this.cd.markForCheck();
      this.queryParam.keys={
        BASE_VEHICLE_DA:["VIN","PLATE_NUMBER","TERMINAL_CODE","MODEL"],
        MDT_O_DA_TYPE:["MDT_O_VEH_STA","MDT_O_CHARGE_STA","MDT_O_POW_MODE","MDT_O_SPEED","MDT_O_MILEAGE","MDT_O_TOTAL_VOL","MDT_O_TOTAL_CUR","MDT_O_SOC","MDT_O_DC_STA","MDT_O_GEAR","MDT_O_RES","MDT_O_BRAKING_STA","MDT_O_ASC_LEN","MDT_O_DEC_LEN"]
      }

    })
  }

  alertTimes:any='';

  ngOnInit() {
    this.alertTimes=this.context.beginTime;
    //报警基本信息
    this.warningBaseInfo = [
      [
        {title: "VIN码", content: this.context.vin},
        {title: "报警等级", content: this.context.level},
        {title: "报警项", content: this.context.alarmItem}
      ],
      [
        {title: "报警时间", content: formatDateTime(parseInt(this.context.beginTime))},
        {title: "报警位置", content: '', colspan: "3"}
      ]
    ];

    //checkbox数据
    this.checkOptionsWorkDataType = [
      {label: '整车数据', value: 'MDT_O_DA_TYPE', checked: true},
      {label: '驱动电机数据', value: 'MDT_DR_DA_TYPE'},
      {label: '发动机数据', value: 'MDT_EN_DA_TYPE'},
      {label: '极值数据', value: 'MDT_P_DA_TYPE'},
      {label: '报警数据', value: 'MDT_A_DA_TYPE'},
      {label: '位置数据', value: 'MDT_PO_DA_TYPE'},
      {label: '动力电池数据', value: 'MDT_BA_DA_TYPE'},
    ];

    //初始化列表头
    this.originalColumns = [
      {field: 'beginTime', header: '报警时间', template: this.timeTemplate, width: 160}
    ];
    this.columns = [
      {field: 'beginTime', header: '报警时间', template: this.timeTemplate, width: 160}
    ];
    this.loadingService.show();
    //工况数据
    forkJoin(
      [
        this.shareService.getHistoricalConditions(this.context.vin, {
          startDate: this.context.beginTime,
          endDate: this.context.beginTime
        }),
        // this.bMonitoringService.getRealtime(this.context.vin),
        this.shareService.getOptionsByCodes([
          'MDT_O_DA_TYPE',
          'MDT_DR_DA_TYPE',
          'MDT_EN_DA_TYPE',
          'MDT_P_DA_TYPE',
          'MDT_A_DA_TYPE',
          'MDT_PO_DA_TYPE',
          'MDT_BA_DA_TYPE',
          'CM_A_DA',
        ])
      ]).subscribe((res) => {
      this.map = res[1];
      if(res[0].records.length==0){
        this.data={};
        this.loadingService.hide();
        return;
      }else{
        this.data = JSON.parse(res[0].records[0].body);
      }
      this.geocoder.getLocation([this.data['MDT_PO_DA_TYPE']['MDT_PO_OFF_LON'],this.data['MDT_PO_DA_TYPE']['MDT_PO_OFF_LAT']]).then((res) => {
        this.warningBaseInfo[1][1].content=res;
        this.cd.detectChanges()
      });
      this.loadingService.hide();
      // this.checkJk(this.checkOptionsWorkDataType);
    });
    this.getTreeList()

  }

  nzEvent($event){
    let list=this.tree.getTreeNodes(),obj={};
    for(let i=0;i<list.length;i++){
      let key=list[i].origin.itemCode,
        idxArray=[],
        child=list[i].children;
      for(let j=0;j<child.length;j++){
        if(child[j].isChecked){
          idxArray.push(child[j].origin.itemCode);
        }
      }
      if(idxArray.length!=0){
        obj[key]=idxArray;
      }
    }

    this.queryParam.keys=obj;
  }


  checkJk(val) {
    this.arr = [];
    for (let item of val) {
      if (item.checked) {
        this.arr.push(item.value);
      }
    }
    if (this.arr.length == 0) {
      this.columns = _.cloneDeep(this.originalColumns);
      return;
    }
    this.loadingService.show();

    this.shareService.getOptionsByCodes(this.arr).subscribe((res) => {
      let keyArr = [];
      for (let key in res) {
        if (key == 'MDT_BA_DA_TYPE' || key == 'MDT_DR_DA_TYPE') {

          if (this.bigData && this.bigData.length > 0 && JSON.parse(this.bigData[0].body)[key].length > 1) {
            for (let i = 0; i < this.bigData[0][key].length; i++) {
              for (let item of res[key]) {
                keyArr.push({field: item.value + '_' + (i + 1), header: item.label + '_' + (i + 1), width: 150});
              }
            }
          } else {
            for (let item of res[key]) {
              keyArr.push({field: item.value, header: item.label, width: 150});
            }
          }
        } else {
          for (let item of res[key]) {
            keyArr.push({field: item.value, header: item.label, width: 150});
          }
        }

      }
      this.columns = this.originalColumns.concat(keyArr);
      this.loadingService.hide();
    });
  }


  temp=[];
  //查询按钮点击
  query() {
    let cloneKeys = Object.keys(_.cloneDeep(this.queryParam).keys);
    if(cloneKeys.length == 0){
      this.noticeService.info('keys_is_empty', 'warning');
      return false;
    }
    let query=_.cloneDeep(this.queryParam);
    query.vin = this.context.vin;
    query.beginTime=parseInt(this.context.beginTime) - this.interval * 60 * 1000;
    query.endTime=parseInt(this.context.beginTime) + this.interval * 60 * 1000;
    this.loadingService.show();
    this.tableData = [];
    this.historyService.pageQuery(query, this.pageIndex, this.pageSize).subscribe(
      res => {
        let col=res.columns;
        col.forEach((item)=>{
          let len=item.header.length;
          len=len*12+16;
          if(len<180){
            len=180;
          }
          item.width=len;
        })
        this.columns = _.cloneDeep(col);
        this.temp = res.pageData;
        this.total = this.temp.length;

        this.temp.forEach((res,index)=>{
          if(new Date(res.TIME).getTime()==this.alertTimes){
            this.pageIndex=this.getPageIndex(index+1);
            res.class='trAlert'
          }
        })

        console.log(this.pageIndex);
        this.tableData = this.temp.slice((this.pageIndex-1) * this.pageSize,this.pageIndex * this.pageSize);

        this.loadingService.hide();

        // this.bigData = data.records;
        // this.total = this.bigData.length;
        // this.warningIndex = -1
        // this.allTableData = [];
        // this.tableData = [];
        // for (let k = 0; k < this.bigData.length; k++) {
        //   const singleData = JSON.parse(this.bigData[k].body);
        //   let item = {};
        //   item["beginTime"] = singleData["TIME"];
        //   if (this.warningIndex == -1 && singleData["TIME"] == this.context.beginTime) {
        //     this.warningIndex = k;
        //     item["class"] = "warningColor";
        //   }
        //
        //   for (let key in singleData) {
        //     const content = singleData[key];
        //     const map = this.map[key];
        //     if (map && content) {
        //       if (key == "MDT_DR_DA_TYPE" || key == "MDT_BA_DA_TYPE") {
        //         if (content.length > 1) {
        //           for (let j = 0; j < content.length; j++) {
        //             for (let i = 0; i < map.length; i++) {
        //               for (let contentKey in content[j]) {
        //                 if (contentKey == map[i].value) {
        //                   item[contentKey + "_" + (j + 1)] = this.convertContent(content[j][contentKey], map[i].values, map[i].caculateScript, map[i].unit);
        //                   break;
        //                 }
        //               }
        //             }
        //           }
        //         } else {
        //           for (let i = 0; i < map.length; i++) {
        //             for (let contentKey in content[0]) {
        //               if (contentKey == map[i].value) {
        //                 item[contentKey] = this.convertContent(content[0][contentKey], map[i].values, map[i].caculateScript, map[i].unit);
        //                 break;
        //               }
        //             }
        //           }
        //         }
        //       } else {
        //         for (let i = 0; i < map.length; i++) {
        //           for (let contentKey in content) {
        //             if (contentKey == map[i].value) {
        //               item[contentKey] = this.convertContent(content[contentKey], map[i].values, map[i].caculateScript, map[i].unit);
        //               break;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        //   this.allTableData.push(item);
        // }
        // if (this.warningIndex != -1) {
        //   this.pageIndex = Math.floor(this.warningIndex / this.pageSize) + 1;
        // }
        //
        // this.paginationData();
        // this.loadingService.hide();
      }
    )
  }

  getPageIndex(index){
    return Math.ceil(index/this.pageSize)
  }

  //导出按钮点击
  exportData() {
    let cloneKeys = Object.keys(_.cloneDeep(this.queryParam).keys);
    if(cloneKeys.length == 0){
      this.noticeService.info('keys_is_empty', 'warning');
      return false;
    }
    let param = _.cloneDeep(this.queryParam);
    param.vin = this.context.vin;
    param.beginTime=parseInt(this.context.beginTime) - this.interval * 60 * 1000;
    param.endTime=parseInt(this.context.beginTime) + this.interval * 60 * 1000;
    param['keysJson'] = JSON.stringify(param['keys']);
    delete param['keys'];
    this.exportFileUrl = null;
    let url = 'api/iov/historicalData/queryHistoryBySingleVehicle/export';
    url += parseQueryStr(param);
    console.log(url);

    this.exportFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url + "&token=Bearer " + this.tokenService.get().token);
    this.noticeService.info('notice_export_success', 'success');
  }


  pageIndexChange(e) {
    this.pageIndex = e.pageIndex;
    this.tableData = this.temp.slice((e.pageIndex-1) * e.pageSize,e.pageIndex * e.pageSize);
  }

  pageSizeChange(e) {
    this.pageIndex = 1;
    this.pageSize = e.pageSize;
    this.tableData = this.temp.slice(this.pageIndex-1,this.pageSize);
  }

  paginationData() {
    let startIndex = this.pageSize * (this.pageIndex - 1);
    let endIndex = this.pageIndex * this.pageSize;
    this.tableData = _.cloneDeep(this.allTableData.slice(startIndex, endIndex));
  }


  convertContent(value, values, calScript, unit) {
    let result = "";
    if (values == 'VALUE') {
      result = value;
    }
    if (values == 'OPTIONS') {
      try {
        let obj = JSON.parse(calScript);
        for (let key in obj) {
          if (key == value) {
            result = obj[key];
            break;
          }
        }
      } catch (e) {
      }
    }
    if (unit == null || unit == "") {
      return result;
    } else {
      return result + unit;
    }
  }
}
