import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges, forwardRef, ViewChild} from '@angular/core';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'header-breadcrumb',
  templateUrl: './header-breadcrumb.component.html',
  styleUrls: ['./header-breadcrumb.component.less']
})
export class HeaderBreadcrumbComponent implements OnInit  {
  @Input() routerName = [];

  constructor(
    private http: _HttpClient,
  ) {
  }

  ngOnInit() {
    // console.log(this.routerName);

  }



}
