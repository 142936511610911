import {Component, Input, OnInit, OnChanges, EventEmitter, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.less']
})
export class LineComponent implements OnInit, OnChanges {
  @Output() echartLoaded: EventEmitter<any> = new EventEmitter();
  @Input() serie: any = [];
  @Input() height: string;
  @Input() width: string = '100%';
  @Input() categorys: any;
  option: any;

  constructor() {
  }

  ngOnInit() {
    this.initLine()
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.serie) {
      if (!this.serie || this.serie == null || Object.keys(this.serie).length == 0) {
        return;
      }
    }
    this.initLine();
  }

  initLine() {
    this.option = {
      // title: { text: this.serie.title },
      // backgroundColor:'#F9FBFF',
      color: this.serie.color ? this.serie.color : '',
      animation: false,
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          let t = params[0].name + "<br>";
          params.forEach(para => {
            if (para.seriesType === 'line') {
              t += '<i class="circle_i" style="background: ' + para.color + '"></i>' + para.seriesName + ' : ' + (para.value ? para.value : 0) + '</span><br>';
            } else {
              t += '<i class="circle_i" style="background: ' + para.color + '"></i>' + para.seriesName + ' : ' + para.value + '</span><br>';
            }
          })
          return t;
        }
      },
      legend: {
        show: this.serie.legend.show,
        bottom: 0,
        textStyle: {'color': '#757576'},
        type: 'scroll',
        data: this.serie.legend ? this.serie.legend.data : [],
        pageTextStyle: {
          color: '#fff'
        }
      },
      grid: {
        left: this.serie.gridLeft ? this.serie.gridLeft : '5%',
        right: '6%',
        bottom: '10%',
        top: this.serie.gridTop ? this.serie.gridTop : '6%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#EAEBEE',
          }
        },
        axisLabel: {
          color: '#757576',
          fontSize: 10,
          // interval: 0
        },
        data: this.serie.xAxis
      },
      yAxis: {
        name: this.serie.unit ? this.serie.unit : '',
        nameTextStyle: {
          color: '#757576',
        },
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#EAEBEE',
          }
        },
        axisLabel: {
          color: '#757576',
          fontSize: 10
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EAEBEE',
            width: 1
          }
        },
      },
      series: this.serie.series
    }
  }

  onEchartLoaded($event) {
    this.echartLoaded.emit($event);
  }
}
