import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy,
  SimpleChanges,
  forwardRef,
  ViewChild,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { convertVal, convertTil, convertDate } from '@shared/utils/util.lang';
import { rule } from '@shared/utils/util.const';


@Component({
  selector: 'alert-data',
  templateUrl: './alert-data.component.html',
  styleUrls: ['./alert-data.component.less'],
})
export class AlertDataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  bjData: any = [
    [
      { title: '最高报警等级', content: '' },
      { title: '温度差异报警', content: '' },
      { title: '电池高温报警', content: '' },
    ],
    [
      { title: '车载储能装置类型过压', content: '' },
      { title: '车载储能装置类型欠压', content: '' },
      { title: '车载储能装置类型过充', content: '' },
    ],
    [
      { title: '单体电池过压报警', content: '' },
      { title: '单体电池欠压报警', content: '' },
      { title: '电池单体一致性差报警', content: '' },
    ],
    [
      { title: 'SOC跳变报警', content: '' },
      { title: 'SOC低报警', content: '' },
      { title: 'SOC过高报警', content: '' },
    ],
    [
      { title: '绝缘报警', content: '' },
      { title: 'DC-DC温度报警', content: '' },
      { title: 'DC-DC状态报警', content: '' },
    ],
    [
      { title: '驱动电机温度报警', content: '' },
      { title: '驱动电机控制器温度报警', content: '' },
      { title: '可充电储能系统不匹配', content: '' },
    ],
    [
      { title: '制动系统报警', content: '' },
      { title: '高压互锁状态报警', content: '' },
      { title: '', content: '' },
    ],
  ];

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'CM_A_DA';
  tag_1 = 'MDT_A_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    this.bjData = [
      [
        { title: '最高报警等级', content: convertDate(oriDate.MDT_A_MAX_ALARM_LEV,'MDT_A_MAX_ALARM_LEV',rule) },
        { title: '温度差异报警', content: convertDate(oriDate.CM_A_DA_0,'CM_A_DA_0',rule) },
        { title: '电池高温报警', content: convertDate(oriDate.CM_A_DA_1,'CM_A_DA_1',rule) },
      ],
      [
        { title: '车载储能装置类型过压', content: convertDate(oriDate.CM_A_DA_2,'CM_A_DA_2',rule) },
        { title: '车载储能装置类型欠压', content: convertDate(oriDate.CM_A_DA_3,'CM_A_DA_3',rule) },
        { title: '车载储能装置类型过充', content: convertDate(oriDate.CM_A_DA_18,'CM_A_DA_18',rule) },
      ],
      [
        { title: '单体电池过压报警', content: convertDate(oriDate.CM_A_DA_5,'CM_A_DA_5',rule) },
        { title: '单体电池欠压报警', content: convertDate(oriDate.CM_A_DA_6,'CM_A_DA_6',rule) },
        { title: '电池单体一致性差报警', content: convertDate(oriDate.CM_A_DA_10,'CM_A_DA_10',rule) },
      ],
      [
        { title: 'SOC跳变报警', content: convertDate(oriDate.CM_A_DA_8,'CM_A_DA_8',rule) },
        { title: 'SOC低报警', content: convertDate(oriDate.CM_A_DA_4,'CM_A_DA_4',rule) },
        { title: 'SOC过高报警', content: convertDate(oriDate.CM_A_DA_7,'CM_A_DA_7',rule) },
      ],
      [
        { title: '绝缘报警', content: convertDate(oriDate.CM_A_DA_11,'CM_A_DA_11',rule) },
        { title: 'DC-DC温度报警', content: convertDate(oriDate.CM_A_DA_12,'CM_A_DA_12',rule) },
        { title: 'DC-DC状态报警', content: convertDate(oriDate.CM_A_DA_14,'CM_A_DA_14',rule) },
      ],
      [
        { title: '驱动电机温度报警', content: convertDate(oriDate.CM_A_DA_17,'CM_A_DA_17',rule) },
        { title: '驱动电机控制器温度报警', content: convertDate(oriDate.CM_A_DA_15,'CM_A_DA_15',rule) },
        { title: '可充电储能系统不匹配', content: convertDate(oriDate.CM_A_DA_9,'CM_A_DA_9',rule) },
      ],
      [
        { title: '制动系统报警', content: convertDate(oriDate.CM_A_DA_13,'CM_A_DA_13',rule) },
        { title: '高压互锁状态报警', content: convertDate(oriDate.CM_A_DA_16,'CM_A_DA_16',rule) },
        { title: '', content: '' },
      ],
    ]
    this.cd.markForCheck();
  }


  ngOnDestroy() {
    // clearInterval(this.timer);
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.bjData = [
          [
            { title: '最高报警等级', content: '' },
            { title: '温度差异报警', content: '' },
            { title: '电池高温报警', content: '' },
          ],
          [
            { title: '车载储能装置类型过压', content: '' },
            { title: '车载储能装置类型欠压', content: '' },
            { title: '车载储能装置类型过充', content: '' },
          ],
          [
            { title: '单体电池过压报警', content: '' },
            { title: '单体电池欠压报警', content: '' },
            { title: '电池单体一致性差报警', content: '' },
          ],
          [
            { title: 'SOC跳变报警', content: '' },
            { title: 'SOC低报警', content: '' },
            { title: 'SOC过高报警', content: '' },
          ],
          [
            { title: '绝缘报警', content: '' },
            { title: 'DC-DC温度报警', content: '' },
            { title: 'DC-DC状态报警', content: '' },
          ],
          [
            { title: '驱动电机温度报警', content: '' },
            { title: '驱动电机控制器温度报警', content: '' },
            { title: '可充电储能系统不匹配', content: '' },
          ],
          [
            { title: '制动系统报警', content: '' },
            { title: '高压互锁状态报警', content: '' },
            { title: '', content: '' },
          ],
        ];
        return
      }
      let oriDate=this.vdata[this.tag];
      let oriDate1=this.vdata[this.tag_1];
      Object.assign(oriDate,oriDate1)
      this.getContent(oriDate);
    }
    ;
  }

}
