import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges, forwardRef, ViewChild} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService, LoadingService } from '@service';
import { getTotal } from '@shared/utils/response.util';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'operation-statistics',
  templateUrl: './operation-statistics.component.html',
  styleUrls: ['./operation-statistics.component.less']
})
export class OperationStatisticsComponent implements OnInit  {
  @Input() vin='';
  queryParam: any = {
    year: new Date().getFullYear(),
  };
  yearList = [
  ];
  days:any=[];
  mouths:any=[];
  isString=_.isString;
  constructor(
    private http: _HttpClient,
    private loadingService: LoadingService,
    private bMonitoringService: BMonitoringService,
  ) {
  }

  ngOnInit() {
    for(let i=2000;i<=new Date().getFullYear();i++){
      this.yearList.push({ id: i, name: i });
    }
    this.query();
    for(let i = 1 ;i<32;i++){
      this.days.push(i);
    }
    for(let j = 2 ;j<13*2;j++){
      if(j%2 == 0){
        this.mouths.push(j/2);
      }else{
        this.mouths.push(String(Math.floor(j/2)));
      }
    }
  }

  allDate:any=null;

  query(){
    this.loadingService.show();
    let obj={year:new Date(String(this.queryParam.year)).getTime()};
    this.bMonitoringService.getOpeStatis(this.vin,obj).subscribe({
        next: (res: HttpResponse<any>) => {
          this.allDate=res.body;
          this.loadingService.hide();
        }, error: err => {
          this.loadingService.hide();
        }
      });
  }

  gpsTip=['none-km-gps','more0km-gps','more100km-gps','more200km-gps']
  dayTip=['none-km-tot','more0km-tot','more100km-tot','more200km-tot']

  getState(allDate,day,index){
    if(!allDate){
      return
    }
    if(!allDate[index-1]['data'][day-1]){
      return
    }
    if(!_.isString(index)){
      let mile=allDate[index-1]['data'][day-1]['dayGpsMileage'];
      if(mile==0){
        return 0;
      }else if(mile>0&&mile<=100){
        return 1;
      }else if(mile>100&&mile<=200){
        return 2;
      }else{
        return 3;
      }
    }else{
      let mile=allDate[index-1]['data'][day-1]['dayMileage'];
      if(mile==0){
        return 0;
      }else if(mile>0&&mile<=100){
        return 1;
      }else if(mile>100&&mile<=200){
        return 2;
      }else{
        return 3;
      }
    }
  }

  getValue(allDate,day,index){
    if(!allDate){
      return
    }
    if(!allDate[index-1]['data'][day-1]){
      return
    }
    if(!_.isString(index)){
      let mile=allDate[index-1]['data'][day-1]['dayGpsMileage'];
      return mile;
    }else{
      let mile=allDate[index-1]['data'][day-1]['dayMileage'];
      return mile;
    }
  }

}
