import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.less'],
})
export class CustomizeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  zcsjData: any = [
    [
      { title: '动力电池系统总能量(kwh)', content: '' },
      { title: '动力电池系统冷却方式', content: '' },
      { title: '动力电池快充CC2阻值(Ω)', content: '' },
    ],
    [
      { title: '动力电池慢充CC阻值(Ω)', content: '' },
      { title: '动力电池慢充PMW值(%)', content: '' },
      { title: '电子锁状态', content: '' },
    ],
    [
      { title: '动力电池慢充S2开关状态', content: '' },
      { title: '整车钥匙位置', content: '' },
      { title: '', content: '' },
    ],
  ];

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_CO_DA_TYPE';
  timer = null;

  ngOnInit() {
  }

  getContent(oriDate) {
    this.zcsjData = [
      [
        { title: '动力电池系统总能量(kwh)', content: convertDate(oriDate.MDT_CO_TOTAL_ENERGY, 'MDT_CO_TOTAL_ENERGY', rule) },
        { title: '动力电池系统冷却方式', content: convertDate(oriDate.MDT_CO_COOL_METHOD, 'MDT_CO_COOL_METHOD', rule) },
        { title: '动力电池快充CC2阻值(Ω)', content: convertDate(oriDate.MDT_CO_CC2, 'MDT_CO_CC2', rule) },
      ],
      [
        { title: '动力电池慢充CC阻值(Ω)', content: convertDate(oriDate.MDT_CO_CC, 'MDT_CO_CC', rule) },
        { title: '动力电池慢充PMW值(%)', content: convertDate(oriDate.MDT_CO_PWM, 'MDT_CO_PWM', rule) },
        { title: '电子锁状态', content: convertDate(oriDate.MDT_CO_TYPE, 'MDT_CO_TYPE', rule) },
      ],
      [
        { title: '动力电池慢充S2开关状态', content: convertDate(oriDate.MDT_CO_S2_TYPE, 'MDT_CO_S2_TYPE', rule) },
        { title: '整车钥匙位置', content: convertDate(oriDate.MDT_CO_KEY, 'MDT_CO_KEY', rule) },
        { title: '', content: '' },
      ],
    ];
    this.cd.markForCheck();
  }


  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if (this.vdata[this.tag] == null) {
        this.zcsjData = [
          [
            { title: '动力电池系统总能量(kwh)', content: '' },
            { title: '动力电池系统冷却方式', content: '' },
            { title: '动力电池快充CC2阻值(Ω)', content: '' },
          ],
          [
            { title: '动力电池慢充CC阻值(Ω)', content: '' },
            { title: '动力电池慢充PMW值(%)', content: '' },
            { title: '电子锁状态', content: '' },
          ],
          [
            { title: '动力电池慢充S2开关状态', content: '' },
            { title: '整车钥匙位置', content: '' },
            { title: '', content: '' },
          ],
        ];
        return
      }
      let oriDate = this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }


}
