import { Directive, ElementRef, Renderer2, Input,AfterViewInit, OnInit ,OnDestroy} from '@angular/core';
import clipboard from 'clipboard-polyfill'

@Directive({
  selector: '[voluation]'
})
export class voluationDirective implements OnInit,OnDestroy{
  hostElement: ElementRef;
  @Input('voluation') voluation:any;
  dom:any;
  rect:any;
  constructor(
    ele: ElementRef,
    private renderer2: Renderer2
  ) {
    this.hostElement = ele;
  }
  ngOnInit() {
    this.dom=document.createElement('span');
    this.dom.innerHTML='复制';
    this.dom.className='Umore';
    this.dom.style.display='none';
    this.dom.style.padding='2px 5px';
    this.dom.style.background='#fff';
    this.dom.style.borderRadius='5px';
    this.dom.style.border='1px solid #ddd';
    this.dom.style.position='fixed';
    this.dom.style.cursor='pointer';
    this.dom.style.zIndex='1000000';
    this.renderer2.setStyle(this.hostElement.nativeElement,'position','relative');
    this.hostElement.nativeElement.appendChild(this.dom);
    this.hostElement.nativeElement.addEventListener('mouseenter',this.moreenter.bind(this))
    this.hostElement.nativeElement.addEventListener('mouseleave',this.moreleave.bind(this))
    this.dom.addEventListener('click',this.moreclick.bind(this))

  }

  ngOnDestroy(){
    this.hostElement.nativeElement.removeEventListener('mouseenter',this.moreenter.bind(this))
    this.hostElement.nativeElement.removeEventListener('mouseleave',this.moreleave.bind(this))
  }

  moreenter(){
    this.rect=this.hostElement.nativeElement.getBoundingClientRect();
    this.dom.style.display='inline-block';
    this.dom.style.top=this.rect.top-15+'px';
    this.dom.style.left=this.rect.left-15+'px';
  }

  moreleave(){
    this.dom.style.display='none';
    this.dom.innerHTML='复制';
  }

  moreclick(){
    this.dom.innerHTML='<i></i>复制成功';
    // this.dom.insertBefore(newItem,existingItem)
    clipboard.writeText(this.voluation);
  }

}
