import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ContentChild,
  ElementRef,
  TemplateRef,
  OnChanges, HostListener, SimpleChanges,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import set = Reflect.set;

@Component({
  selector: 'sf-table',
  templateUrl: './sf-table.component.html',
  styleUrls: ['./sf-table.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfTableComponent implements OnInit, OnChanges {

  @Input() total = 1;
  @Input() pageIndex = 1;
  @Input() pageSize = 10;
  @Input() columns = [];
  @Input() data = [];
  @Input() showPagination = true;
  @Input() pageSizeOptions = [10, 15, 30, 50, 100];
  @Input() scrollHeight = null;
  @Input() resizableColumns = true;
  @Input() scrollable = false;
  @Input() columnResizeMode = 'expand';
  @Input() showTotal = true;
  @Input() showCheckbox = true;
  @Input() autoLayout = false;
  @Input() loading = false;
  @Input() width = null;
  @Input() dataKey = 'id';
  @Input() rowExpansion = false;
  @Input() selectionMode = null;
  @Input() virtualScroll = false;
  @Input() virtualRowHeight = 36;
  @Input() lazy = false;
  @Input() totalRecords = null;
  @Input() rows = null;
  @Input() clearSelectIfQuery = false;
  @Input() showRadioBox = false;
  @Input() showSerial = true;
  @Input() showSort = false;
  @Input() showMultiSelect = false;
  @Input() hiddenSfQuery = false;
  @Input() nzShowQuickJumper = true;
  @Input() showSelArrow = false;
  @Input() nzShowSizeChanger = true;
  @Input() unScrollTable = false;
  @Input() customizePagination = false;
  @Input() sortField = null;
  @Input() sortOrder = 1;

  @Output() selectedChange = new EventEmitter<any>();
  @Output() unSelectedChange = new EventEmitter<any>();
  @Output() pageIndexChange = new EventEmitter<any>();
  @Output() pageSizeChange = new EventEmitter<any>();
  @Output() onRowExpand = new EventEmitter<any>();
  @Output() onRowCollapse = new EventEmitter<any>();
  @Output() onLazyLoad = new EventEmitter<any>();

  @Output() onColSort = new EventEmitter<any>();

  @ViewChild('pagin') pagin;
  @ViewChild('pTable') pTable;
  @ViewChild('ptableBox') ptableBox: ElementRef;
  @ViewChild('innerBox') innerBox: ElementRef;

  private selectionValue;
  public headerHeight = 37;

  multiSelectCols=[]

  @Input()
  get selectionModel() {
    return this.selectionValue;
  }

  set selectionModel(val) {
    this.selectionValue = val;
    this.selectionModelChange.emit(this.selectionValue);
  }

  @Output() selectionModelChange = new EventEmitter<any>();


  constructor(
    private http: _HttpClient,
    private cd: ChangeDetectorRef,
    private ref: ElementRef,
  ) {
  }

  mulSelChange($event){
    this.columns.sort(this.NumAscSort);
  }

  onSort($event) {
    this.onColSort.emit($event);
    return false;
  }

  NumAscSort(a,b) {return a.tableIndex - b.tableIndex;}

  ngOnInit() {
    // for(let item of this.columns){
    //   if(item.hidden!=true){
    //     this.multiSelectCols.push(item);
    //   }
    // }

    let col=[],index=0;
    for(let item of this.columns){
      if(item.hidden!=true){
        item.tableIndex=index++;
        this.multiSelectCols.push(item);
        col.push(item);
      }
    }
    this.columns=col;
  }

  ngAfterViewInit() {
    // this.getScrollHeight();
  }

  getScrollHeight() {
    setTimeout(() => {
      if (this.ptableBox.nativeElement.offsetHeight == 0) {
        return;
      }
      let outer = this.ptableBox.nativeElement.offsetHeight;
      let inner = this.innerBox.nativeElement.clientHeight;
      if(this.unScrollTable){
        this.scrollHeight = null;
        this.scrollable = false;
        this.cd.markForCheck();
        return
      }
      if (outer > inner) {
        this.scrollHeight = null;
        this.scrollable = false;
        this.cd.markForCheck();
      } else {
        this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
        this.scrollable = true;
        this.cd.markForCheck();
      }
    }, 100);
  }

  getVirtualScrollHeight() {
    setTimeout(() => {
      if (this.ptableBox.nativeElement.offsetHeight == 0) {
        return;
      }
      this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
      this.scrollable = true;
      this.cd.markForCheck();
    }, 100);
  }

  // ngAfterContentChecked() {
  //
  // }

  ngAfterContentChecked(){

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if(!this.clearSelectIfQuery){
        this.selectionModel = [];
      }
      if (!this.virtualScroll) {
        this.getScrollHeight();
      } else {
        this.getVirtualScrollHeight();
      }
    }
  }


  @HostListener('window:resize', ['$event'])
  resize(event) {
    if (!this.virtualScroll) {
      setTimeout(() => {
        if (this.ptableBox.nativeElement.offsetHeight == 0) {
          return;
        }
        let outer = this.ptableBox.nativeElement.offsetHeight;
        let inner = this.innerBox.nativeElement.clientHeight;
        if(this.unScrollTable){
          this.scrollHeight = null;
          this.scrollable = false;
          this.cd.markForCheck();
          return
        }
        if (outer > inner) {
          this.scrollHeight = null;
          this.scrollable = false;
          this.cd.markForCheck();
          setTimeout(() => {
            let outer = this.ptableBox.nativeElement.offsetHeight;
            let inner = this.innerBox.nativeElement.clientHeight;
            if (outer < inner) {
              this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
              this.scrollable = true;
              this.cd.markForCheck();
            }
          }, 100);
        } else {
          this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
          this.scrollable = true;
          this.cd.markForCheck();
        }
      }, 100);
    } else {
      this.getVirtualScrollHeight();
    }
  }

  //页面改变
  nzPageIndexChange($event) {
    this.selectionModel = [];
    this.pageIndexChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      total: this.total,
    });
  }

  nzPageSizeChange($event) {
    this.selectionModel = [];
    this.pageSizeChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      total: this.total,
    });
  }


  //获得页数
  getPagers() {
    return Math.ceil(this.total / this.pageSize);
  }

  //确定按钮点击
  tzpage() {
    let oldIndex = this.pagin.nzPageIndex;
    let val = this.pagin._itemRender.elementRef.nativeElement.parentElement.getElementsByTagName('input')[0].value;
    if (val <= 0 || oldIndex == val) {
      return;
    }
    this.pagin._itemRender.elementRef.nativeElement.parentElement.getElementsByTagName('input')[0].value = '';
    this.pagin.jumpPage(val);
  }

  colResize($event){
    if(this.pTable.scrollable){
      let ui=this.pTable.containerViewChild.nativeElement.querySelector('.ui-table-scrollable-body-table');
      setTimeout(()=>{
        // this.pTable.containerViewChild.nativeElement.style.width=parseFloat(ui.style.width)+this.scrollBarheight+'px';
        this.scrollHeight = this.ptableBox.nativeElement.offsetHeight - this.headerHeight + 'px';
        this.scrollable = true;
        this.cd.markForCheck()
        this.cd.detectChanges()
      },200)

    };
  }


  IsSelectedRow(rowData) {
    if (this.selectionModel.indexOf(rowData) > -1) {
      return true;
    }
    ;
    return false;
  }

  rowExpand($event) {
    this.getScrollHeight();
    this.onRowExpand.emit($event);
  }

  getIfRender($event) {
    return $event instanceof TemplateRef;
  }

  rowCollapse($event) {
    this.getScrollHeight();
    this.onRowCollapse.emit($event);
  }

  rowSelect($event) {
    this.selectedChange.emit($event);
  }

  rowUnselect($event) {
    this.unSelectedChange.emit($event);
  }


  rowClick(event, rowData,data) {
    // if(this.selectionMode == 'single'){
    //   console.log(rowData);
    //   console.log(data);
    // }
    if(event.button!=0){
      return;
    };
    if (this.selectionMode != null || this.showCheckbox == false || this.showRadioBox == true) {
      return;
    }
    if(event.path[0].className.indexOf==null){
      return;
    }
    if (event.path[0].className.indexOf('bodyCell') == -1 && event.path[0].nodeName != 'TD') {
      return;
    }

    rowData.onClick(event);
  }

  LazyLoad($event) {
    this.onLazyLoad.emit($event);
  }

  setSelection(val) {
    setTimeout(() => {
      this.selectionModel = val;
    });
  }

}
