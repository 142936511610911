import { Component, OnInit, Input, Output, EventEmitter ,SimpleChanges,OnChanges, forwardRef, ViewChild} from '@angular/core';
import { _HttpClient } from '@delon/theme';


@Component({
  selector: 'vehicle-archives',
  templateUrl: './vehicle-archives.component.html',
  styleUrls: ['./vehicle-archives.component.less']
})
export class VehicleArchivesComponent implements OnInit ,OnChanges  {
  @Input() vin: any;
  @Input() vdata: any;
  cldaData :any;
  constructor(
    private http: _HttpClient,
  ) {
  }

  ngOnInit() {
    this.cldaData=[
      [
        { title: '车辆用途', content: '' },
        { title: '购车领域', content: '' },
        { title: '购车客户', content: '' },
      ],
      [
        { title: '终端型号', content: '' },
        { title: '驱动电机型号', content: '' },
        { title: '储能装置型号', content: '' },
      ],
      [
        { title: '终端编号', content: '' },
        { title: '驱动电机编号', content: '' },
        { title: '储能装置编号', content: '' },
      ],
      [
        { title: '发动机型号', content: '' },
        { title: '生产批次', content: '' },
        { title: '发动机编号', content: '' },
      ],
      [
        { title: '出厂日期', content: '' }
      ]
    ];
  }

  ngOnChanges(change:SimpleChanges){
    if(change.vdata){
      this.cldaData=[
        [
          { title: '车辆用途', content: this.vdata.vehiclePurpose },
          { title: '购车领域', content: this.vdata.purchaseRange },
          { title: '购车客户', content: this.vdata.companyName },
        ],
        [
          { title: '生产批次', content: this.vdata.productionBatchno },
          { title: '出厂日期', content: this.getTimes(this.vdata.manufactureDate) },
          { title: '上牌日期', content: this.getTimes(this.vdata.licensedDate) },
        ],
        [
          { title: '终端型号', content: this.vdata.terminalModelCode },
          { title: '驱动电机1型号', content: this.vdata.driverModelCode1 },
          { title: '驱动电机2型号', content: this.vdata.driverModelCode2 },
        ],
        [
          { title: '终端编号', content: this.vdata.terminalCode },
          { title: '驱动电机1编号', content: this.vdata.electricDriverCode1 },
          { title: '驱动电机2编号', content: this.vdata.electricDriverCode2 },
        ],
        [
          { title: '储能装置1型号', content: this.vdata.storageModelCode1 },
          { title: '储能装置2型号', content: this.vdata.storageModelCode2 },
          { title: '发动机型号', content: this.vdata.engineModelCode },
        ],
        [
          { title: '储能装置1编号', content: this.vdata.storageCode1 },
          { title: '储能装置2编号', content: this.vdata.storageCode2 },
          { title: '发动机编号', content: this.vdata.engineCode },
        ],
      ];
    };
  }

  getTimes(now){
    if(!now){
      return
    };
    now=new Date(Number(now));
    let year = now.getFullYear();       //年
    let month = now.getMonth() + 1;     //月
    let day = now.getDate();            //日
    let hh = now.getHours();            //时
    let mm = now.getMinutes();          //分
    let ss = now.getSeconds();           //秒
    let clock = year + "-";
    if(month < 10)
      clock += "0";
    clock += month + "-";
    if(day < 10)
      clock += "0";
    clock += day + "";
    return(clock);
  }

}
