import {Component, Input, OnInit, OnChanges} from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.less']
})
export class PieComponent implements OnInit, OnChanges {

  @Input() seriesData: any = [];
  @Input() pieStyle: any = "";
  @Input() height: string;
  option: any;

  constructor() {
  }

  ngOnInit() {
    this.initPie()
  }

  ngOnChanges() {
    this.initPie();
  }

  initPie() {
    if (!this.seriesData) {
      return;
    }
    let tempOption = {
      toolbox: {
        show: true,
        feature: {
          mark: {show: true},
          magicType: {
            show: true,
            type: ['pie']
          },
          saveAsImage: {show: false}
        }
      },
      calculable: true,
      series: [
        {
          type: 'pie',
          radius: ['35%', '60%'],
          center: ['50%', '55%'],
          labelLine: {
            normal: {length2: 40},
          },
          label: {
            normal: {
              color:'#8BA2E5',
              formatter: (val) => {   //让series 中的文字进行换行
                if (val) {
                  return (val['name'].split(" ")).reverse().join("\n");
                }
                return '';
              },
              padding: [3, -60, 30, -60]
            }
          },
          data: this.seriesData,
        }
      ],
    };
    if (this.pieStyle == 'bg') {
      let obj = {
        type: 'pie',
        data: [100],
        z: 1,
        radius: ['42%', '0%'],
        center: ['50%', '55%'],
        itemStyle: {
          normal: {
            color: '#374B75',
          }
        },
        silent: true,
        label: null,
        labelLine: {
          normal: {
            show: false,
            length2: 0
          }
        }
      }
      tempOption.series.push(obj);
      obj = {
        type: 'pie',
        data: [100],
        z: 2,
        radius: ['39%', '0%'],
        center: ['50%', '55%'],
        itemStyle: {
          normal: {
            color: '#051C4F',
          }
        },
        silent: true,
        label: null,
        labelLine: {
          normal: {
            show: false,
            length2: 0
          }
        }
      }
      tempOption.series.push(obj);
      obj = {
        type: 'pie',
        data: [100],
        z: 3,
        radius: ['36%', '0%'],
        center: ['50%', '55%'],
        itemStyle: {
          normal: {
            color: '#374B75',
          }
        },
        silent: true,
        label: null,
        labelLine: {
          normal: {
            show: false,
            length2: 0
          }
        }
      }
      tempOption.series.push(obj);
    } else if (this.pieStyle == 'double') {
      let obj = {
        type: 'pie',
        label: {
          normal: {
            formatter: (val) => {   //让series 中的文字进行换行
              return '';
            },
            color:'#8BA2E5',
            padding: []
          }
        },
        labelLine: {
          normal: {
            show: false,
            length2: 0
          },
          emphasis: {
            show: false,
            length: 0
          }
        },
        radius: ['33%', '43%'],
        center: ['50%', '55%'],
        data: [],
      }
      this.seriesData.forEach(element => {
        obj.data.push({itemStyle: element.itemStyle, value: element.value});
      });
      tempOption.series.unshift(obj);
    } else if (this.pieStyle == 'fan') {
      tempOption.series[0].radius = ['25%', '70%'];
      tempOption.series[0]['roseType'] = 'radius';
    }
    this.option = _.cloneDeep(tempOption);
  }
}
