import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { nzCustomDirective ,voluationDirective} from '../directive';
// delon
import { AlainThemeModule } from '@delon/theme';
import { DelonABCModule } from '@delon/abc';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
// i18n
import { TranslateModule } from '@ngx-translate/core';

import {
  HeaderBreadcrumbModule,
  ContainerPageModule,
  SfFormItemModule,
  SfTableModule, SfDialogModule,
  BMonitoringModule,
  TabsModule,
  ModalModule,
  SfTreeTableModule,
  SfCalendarModule,
  EchartsComponentModule,
} from '@components';

// #region third libs
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CountdownModule } from 'ngx-countdown';

import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';

import { FieldErrorComponent } from './field-error/field-error.component';
import { MessagePipe } from './message/message.pipe';
import { ValidatorsModule } from '../directive';

const THIRDMODULES = [
  NgZorroAntdModule,
  CountdownModule,
  DialogModule,
  TableModule,
  MultiSelectModule,
  DropdownModule,
  TooltipModule,
  CalendarModule,
  HeaderBreadcrumbModule,
  ContainerPageModule,
  SfFormItemModule,
  SfTableModule,
  SfDialogModule,
  BMonitoringModule,
  TabsModule,
  ValidatorsModule,
  ModalModule,
  SfTreeTableModule,
  SfCalendarModule,
  EchartsComponentModule,
];
// #endregion

// #region your componets & directives
const COMPONENTS = [
  FieldErrorComponent,
];
const DIRECTIVES = [
  MessagePipe,
  nzCustomDirective,
  voluationDirective,
];
// #endregion



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // third libs
    ...THIRDMODULES
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonABCModule,
    DelonACLModule,
    DelonFormModule,
    // i18n
    TranslateModule,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES
  ]
})
export class SharedModule { }
