import {NgModule} from '@angular/core';

import {NgxAmapModule} from 'ngx-amap';
import {SharedModule} from '@shared';
import {RouteRoutingModule} from './routes-routing.module';
// dashboard pages
// import { DashboardComponent } from './dashboard/dashboard.component';
// passport pages
import {UserLoginComponent} from './passport/login/login.component';
import { SSOComponent } from './passport/sso.component';
// single pages
import {CallbackComponent} from './callback/callback.component';
import {UserLockComponent} from './passport/lock/lock.component';

const COMPONENTS = [
  // DashboardComponent,
  // passport pages
  UserLoginComponent,
  SSOComponent,
  // single pages
  CallbackComponent,
  UserLockComponent,
];
const COMPONENTS_NOROUNT = [];

@NgModule({
  imports: [SharedModule, RouteRoutingModule, NgxAmapModule],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT,
  ],
  entryComponents: COMPONENTS_NOROUNT
})
export class RoutesModule {
}
