import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SettingsService ,MenuService} from '@delon/theme';
import { Router } from '@angular/router';
import { ACLService } from '@delon/acl';
// import {UserType} from "@shared/utils";

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  searchToggleStatus: boolean;

  showNotify = true;
  constructor(
    private _acl : ACLService,
    public settings: SettingsService,
    private menuSrv: MenuService,
    private route: Router,
  ) { }

  ngOnInit() {
    // if(this._acl.can(UserType.customer) || ! this._acl.canAbility(1013)) {
    //   this.showNotify = false;
    // }
  }

  toggleCollapsedSidebar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
    if (!this.settings.layout.collapsed) {
      this.menuSrv.openedByUrl(this.route.url.split('?')[0]);
    };
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }
}
