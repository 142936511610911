import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BaseService} from '@service/base.service';
import {_HttpClient} from '@delon/theme';

@Injectable()
export class SharedService extends BaseService {

  private iovModuleName = '/iov';

  private headers = new HttpHeaders({'Content-Type': 'application/json'});


  constructor(private http: HttpClient, private _http: _HttpClient) {
    super();
  }

  /**
   * 获取字典
   */
  getOptionsByCodes(codes: String[]): Observable<any> {

    if (codes == null && codes.length == 0) return;
    let httpParams = new HttpParams();
    httpParams = httpParams.set("codes", this.array2String(codes, ","));

    return this.http.get(this.iovModuleName + "/dictionary", {params: httpParams});
  }

  /**
   * 数据字典表中该code下直接子类列表
   */
  getChildrenByCodes(code: String[]): Observable<any> {
    if (code == null && code.length == 0) return;
    return this.http.get(`${this.iovModuleName}/dictionary/child/${code}`);
  }

  /**
   * 获取实时报警数量
   */
  getAlarmCount(): Observable<any> {
    return this.http.get(`${this.iovModuleName}/dashboard/alarmCount`);
  }

  /**
   * 修改密码
   */
  modifyPassword(oldPassword,newPassword,newPasswordConfirm): Observable<any>{
    return this._http.post(`${this.iovModuleName}/dashboard/modifyPwd`,null,{oldPassword,newPassword,newPasswordConfirm});
  }

  /**
   * 获取历史工况数据
   */
  getHistoricalConditions(vin, params): Observable<any> {
    return this._http.get(`${this.iovModuleName}/vehicle/historicalConditions/${vin}`, params);
  }

  array2String(array, separator: String) {
    if (array == null) return;
    let resStr = "";
    for (var i in array) {
      resStr += array[i] + separator;
    }
    return resStr.substring(0, resStr.length - 1);
  }

  //根据vin获取详细地址
  getLocation(vin): Observable<any> {
    return this._http.get(`${this.iovModuleName}/location/${vin}`);
  }
}
