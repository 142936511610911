import { Component, ChangeDetectorRef } from '@angular/core';
import { MenuService, SettingsService, Menu } from '@delon/theme';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { CacheService } from '@delon/cache';

@Component({
  selector: 'layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent {
  list = [];

  private change$: Subscription;
  openMap = {};

  constructor(
    public settings: SettingsService,
    private menuSrv: MenuService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private cache: CacheService,

  ) {
  }

  ngOnInit() {
    this.menuSrv.openedByUrl(this.router.url);
    this.change$ = <any>this.menuSrv.change.subscribe(res => {
      this.list = res;
      this.cd.detectChanges();
    });
    this.setCache();
  }

  setCache() {

  }

  openHandler($event, value): void {
    if (!$event || this.settings.layout.collapsed) {
      return;
    }
    //获得此节点id及所有父节点id
    let idArray = [], currentNode = value;
    idArray.push(value.__id);
    while (currentNode.__parent) {
      currentNode = currentNode.__parent;
      idArray.push(currentNode.__id);
    }

    function closeMenu(val) {
      if (!idArray.includes(val.__id)) {
        val._open = false;
      }
      val.children.forEach((item) => {
        closeMenu(item);
      });
    }

    this.list[0].children.forEach((item) => {
      closeMenu(item);
    });
    // if (value.children && value.children.length > 0) {
    //   this.router.navigateByUrl(value.children[0].link);
    // }

  }

  ngOnDestroy(): void {
    this.change$.unsubscribe();
  }

}
