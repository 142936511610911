import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from '../base.service';
import {_HttpClient} from '@delon/theme';

export interface VehicleTerminalModel {
  itemCode: string;
  protocolType:any;
  terminalBrand: string;
  supplier: string;
  remark: string;
}

@Injectable()
export class VehicleTerminalModelService extends BaseService {

  moduleName = this.baseUrl + `/terminal/model`

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, {observe: "response"});
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  create(obj: VehicleTerminalModel): Observable<any> {
    obj = this.trimFormData(obj, ["itemCode", "terminalBrand", "supplier", "remark"]);
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj: VehicleTerminalModel): Observable<any> {
    obj = this.trimFormData(obj, ["itemCode", "terminalBrand", "supplier", "remark"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}`, {"ids": ids});
  }

  dropDownListInfo(): Observable<any> {
    return this._http.get(this.baseUrl + `${this.moduleName}/dropDownListInfo`, null, {observe: "response"});
  }
}
