import { Component, OnInit, Input, Output, OnDestroy,ChangeDetectorRef,EventEmitter ,SimpleChanges, forwardRef, ViewChild} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'real-time-info',
  templateUrl: './real-time-info.component.html',
  styleUrls: ['./real-time-info.component.less']
})
export class RealTimeInfoComponent implements OnInit,OnDestroy  {
  @Input() vin: any;
  timer=null;
  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }
  data=null;
  map=null;
  ngOnInit() {
    this.getContent()
    this.timer=setInterval(()=>{
      this.getContent()
    },30000)
  }

  getContent(){
    this.bMonitoringService.getRealtime(this.vin).subscribe((res)=>{
      this.data=res;
      console.log(this.data);
      this.cd.markForCheck();
    })
    // forkJoin(
    //   [
    //     this.bMonitoringService.getRealtime(this.vin),
    //     this.bMonitoringService.getDictionary(
    //       {
    //         codes:
    //           [
    //             'MDT_O_DA_TYPE',
    //             'MDT_DR_DA_TYPE',
    //             'MDT_EN_DA_TYPE',
    //             'MDT_P_DA_TYPE',
    //             'MDT_A_DA_TYPE',
    //             'MDT_PO_DA_TYPE',
    //             'MDT_BA_DA_TYPE',
    //             'CM_A_DA',
    //           ]
    //       })
    //   ]).subscribe((res)=>{
    //   this.data=res[0];
    //   this.map=res[1].body;
    //   this.cd.markForCheck();
    // })
  }

  ngOnDestroy(){
    clearInterval(this.timer)
  }

}
