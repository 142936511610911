import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'vehicle-data',
  templateUrl: './vehicle-data.component.html',
  styleUrls: ['./vehicle-data.component.less'],
})
export class VehicleDataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  zcsjData: any = [
    [
      { title: '车辆状态', content: '' },
      { title: '充电状态', content: '' },
      { title: '运行模式', content: '' },
    ],
    [
      { title: '车速(km/h)', content: '' },
      { title: '累计里程(km)', content: '' },
      { title: '绝缘电阻(kΩ)', content: '' },
    ],
    [
      { title: '总电压(V)', content: '' },
      { title: '总电流(A)', content: '' },
      { title: 'SOC(%)', content: '' },
    ],
    [
      { title: 'DC-DC状态', content: '' },
      { title: '挡位', content: '' },
      { title: '有无驱动力', content: '' },
    ],
    [
      { title: '有无制动力', content: '' },
      { title: '', content: '' },
      { title: '', content: '' },
    ],
  ];

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_O_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    this.zcsjData = [
      [
        { title: '车辆状态', content: convertDate(oriDate.MDT_O_VEH_STA,'MDT_O_VEH_STA',rule) },
        { title: '充电状态', content: convertDate(oriDate.MDT_O_CHARGE_STA,'MDT_O_CHARGE_STA',rule) },
        { title: '运行模式', content: convertDate(oriDate.MDT_O_POW_MODE,'MDT_O_POW_MODE',rule) },
      ],
      [
        { title: '车速(km/h)', content: convertDate(oriDate.MDT_O_SPEED,'MDT_O_SPEED',rule) },
        { title: '累计里程(km)', content: convertDate(oriDate.MDT_O_MILEAGE,'MDT_O_MILEAGE',rule) },
        { title: '绝缘电阻(kΩ)', content: convertDate(oriDate.MDT_O_RES,'MDT_O_RES',rule) },
      ],
      [
        { title: '总电压(V)', content: convertDate(oriDate.MDT_O_TOTAL_VOL,'MDT_O_TOTAL_VOL',rule) },
        { title: '总电流(A)', content: convertDate(oriDate.MDT_O_TOTAL_CUR,'MDT_O_TOTAL_CUR',rule) },
        { title: 'SOC(%)', content: convertDate(oriDate.MDT_O_SOC,'MDT_O_SOC',rule) },
      ],
      [
        { title: 'DC-DC状态', content: convertDate(oriDate.MDT_O_DC_STA,'MDT_O_DC_STA',rule) },
        { title: '挡位', content: convertDate(oriDate.MDT_O_GEAR,'MDT_O_GEAR',rule) },
        { title: '有无驱动力', content: convertDate(oriDate.MDT_O_DRIVER_STA,'MDT_O_DRIVER_STA',rule) },
      ],
      [
        { title: '有无制动力', content: convertDate(oriDate.MDT_O_BRAKING_STA,'MDT_O_BRAKING_STA',rule) },
        { title: '', content: '' },
        { title: '', content: '' },
      ],
    ];
    this.cd.markForCheck();
  }


  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.zcsjData=[
          [
            { title: '车辆状态', content: '' },
            { title: '充电状态', content: '' },
            { title: '运行模式', content: '' },
          ],
          [
            { title: '车速(km/h)', content: '' },
            { title: '累计里程(km)', content: '' },
            { title: '绝缘电阻(kΩ)', content: '' },
          ],
          [
            { title: '总电压(V)', content: '' },
            { title: '总电流(A)', content: '' },
            { title: 'SOC(%)', content: '' },
          ],
          [
            { title: 'DC-DC状态', content: '' },
            { title: '挡位', content: '' },
            { title: '有无驱动力', content: '' },
          ],
          [
            { title: '有无制动力', content: '' },
            { title: '', content: '' },
            { title: '', content: '' },
          ],
        ];
        return
      }
      let oriDate=this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }


}
