import { Component, Input, OnInit, OnChanges, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.less'],
})
export class HistogramComponent implements OnChanges {
  @Output() echartLoaded: EventEmitter<any> = new EventEmitter();
  @Input() serie: any = [];
  @Input() height: string;
  @Input() categorys: any;
  @Input() legend: boolean = true;
  option: any;

  constructor() {
  }

  ngOnChanges() {
    this.initHistogram();
  }

  initHistogram() {
    if (!this.serie) {
      return;
    }
    let tempOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {    // 坐标轴指示器，坐标轴触发有效
          type: 'line', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      color: this.serie.color ? this.serie.color : '',
      legend: {
        data: this.serie.legend && this.serie.legend.data ? this.serie.legend.data : [],
        textStyle: { color: 'rgba(0,0,0,0.65)' },
        show: this.legend,
      },
      grid: {
        left: this.serie.grid && this.serie.grid.left ? this.serie.grid.left : '5%',
        right: this.serie.grid && this.serie.grid.right ? this.serie.grid.right : '7%',
        bottom: this.serie.grid && this.serie.grid.bottom ? this.serie.grid.bottom : '5%',
        top: this.serie.grid && this.serie.grid.top ? this.serie.grid.top : '10%',
        containLabel: true,
      },
      xAxis: [
        {
          type: this.serie.xAxis && this.serie.xAxis.type ? this.serie.xAxis.type : '',
          data: this.serie.xAxis && this.serie.xAxis.data ? this.serie.xAxis.data : '',
          name: this.serie.xAxis && this.serie.xAxis.name ? this.serie.xAxis.name : '',
          nameTextStyle: {
            color: 'rgba(0,0,0,0.85)',
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9',
            },
          },
          axisLabel: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 12,
            interval: 0,
            rotate: this.serie.axisLabelXRotate ? this.serie.axisLabelXRotate : 0,
          },
        },
      ],
      yAxis: [
        {
          type: this.serie.yAxis && this.serie.yAxis.type ?  this.serie.yAxis.type : '',
          data: this.serie.yAxis && this.serie.yAxis.data ?  this.serie.yAxis.data : [],
          name:this.serie.yAxis &&  this.serie.yAxis.name ? this.serie.yAxis.name : '',
          max:this.serie.yAxis &&  this.serie.yAxis.max ? this.serie.yAxis.max : null,
          nameTextStyle: {
            color: 'rgba(0,0,0,0.85)',
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0)',
            },
          },
          axisLabel: {
            color: 'rgba(0,0,0,0.65)',
            fontSize: 12,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E8E8E8',
              width: 1,
              type: 'dashed',
            },
          },
        },
      ],
      dataZoom: this.serie.dataZoom ? this.serie.dataZoom : [],
      series: [],
    };
    if (this.serie.series) {
      this.serie.series.forEach(data => {
        let obj = {
          name: data.name && data ? data.name : '',
          stack: 'sum',
          type: 'bar',
          markPoint: data.markPoint && data ? data.markPoint : '',
          barWidth: data.barWidth && data ? data.barWidth : '24',
          data: data.data,
        };
        if (this.serie.mutiple) {
          obj.stack = '';
          obj.barWidth = '15%';
        }
        //设置平均值
        if (this.serie.average) {
          obj['markLine'] = {
            data: [
              { type: 'average', name: '平均值' },
            ],
          };
        }
        //设置最大最小值
        if (this.serie.maxAndMin) {
          obj['markPoint'] = {
            data: [
              { type: 'max', name: '最大值' },
              { type: 'min', name: '最小值' },
            ],
          };
        }
        if (data.itemStyle) {
          obj['itemStyle'] = data.itemStyle;
        } else {
          obj['itemStyle'] = {
            normal: {
              color: data.color ? data.color : '',
            },
          };
        }

        tempOption['series'].push(obj);
      });
    }
    this.option = _.cloneDeep(tempOption);
  }

  onEchartLoaded($event) {
    this.echartLoaded.emit($event);
  }
}
