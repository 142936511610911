import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
  SimpleChanges,
  forwardRef,
  ViewChild,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import * as _ from 'lodash';
import { BMonitoringService } from '@service';
import { convertVal ,toDecimalFixed} from '@shared/utils/util.lang';

@Component({
  selector: 'temperature-data',
  templateUrl: './temperature-data.component.html',
  styleUrls: ['./temperature-data.component.less'],
})
export class TemperatureDataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  toDecimal=toDecimalFixed;
  volsysidOptions = [
  ];
  dldcChart: any = {
    tooltip: {
      trigger: 'axis',

      axisPointer: {
        animation: false,
      },
    },
    legend: {
      data: ['流量'],
      x: 'left',
    },

    axisPointer: {
      link: {
        xAxisIndex: 'all',
      },
    },

    grid: [{
      left: 40,
      right: 40,
    }, {
      left: 40,
      right: 40,
    }],
    xAxis: [{
      type: 'category',
      boundaryGap: true,
      axisLine: {
        onZero: true,
      },
      data: [],
    }, {
      gridIndex: 1,
    }],

    yAxis: [{
      type: 'value',
      max: null,
      name: '单位:℃',
      min: null,
      interval: 2,
    }, {
      gridIndex: 1,
    }],
    series: [{
      name: '数值',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 9,
      showSymbol: false,
      lineStyle: {
        normal: {
          width: 1,
          color: '#219CE6',
        },
      },
      markPoint: {
        data: [{
          type: 'max',
          name: '最大值',
        }, {
          type: 'min',
          name: '最小值',
        }],
      },
      data: [],
    }],
  };

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }


  tag = 'MDT_BA_DA_TYPE';
  MDT_BA_SYS_SN;
  MDT_BA_NDL_NO;
  max;
  min;
  dvalue;
  average;
  timer = null;

  ngOnInit() {

  }
  len=0;
  getContent() {
    this.timeData = [];
    if(this.vdata[this.tag]==null){
      return
    }
    this.volsysidOptions=[];
    for(let i=0;i<this.vdata[this.tag].length;i++){
      this.volsysidOptions.push(
        { value: i+1, label: i+1 }
      );
    };
    let da = this.vdata[this.tag][this.len];
    this.MDT_BA_SYS_SN = da['MDT_BA_SYS_SN'];
    this.cd.markForCheck();
    this.MDT_BA_NDL_NO = da['MDT_BA_NDL_NO'];
    this.echartsDate = da['MDT_BA_NDL_TEM'];
    for (let i = 1; i <= da['MDT_BA_NDL_TEM'].length; i++) {
      this.timeData.push(i);
    }
    this.max = Math.max.apply(null, this.echartsDate);
    this.min = Math.min.apply(null, this.echartsDate);
    this.dvalue = (this.max - this.min).toFixed(2);
    let sum = 0;
    for (let i = 0; i < this.echartsDate.length; i++) {
      sum += this.echartsDate[i];
    }
    this.average = (sum / this.echartsDate.length).toFixed(2);
    this.onEchartDldc();
    this.cd.markForCheck();
  }

  timeData = [];
  echartsDate = [];

  onEchartDldc() {
    let serie = {
      toolbox: {
        top: 0,
        right: 10,
        itemGap: 2,
        feature: {
          magicType: {show: true, type: ['line', 'bar']},
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ],
      tooltip: {
        trigger: 'axis',

        axisPointer: {
          animation: false,
        },
      },
      legend: {
        data: ['流量'],
        x: 'left',
      },

      axisPointer: {
        link: {
          xAxisIndex: 'all',
        },
      },

      grid: [{
        left: 40,
        right: 40,
      }, {
        left: 40,
        right: 40,
      }],
      xAxis: [{
        type: 'category',
        boundaryGap: true,
        axisLine: {
          onZero: true,
        },
        data: this.timeData,
      }, {
        gridIndex: 1,
      }],

      yAxis: [{
        type: 'value',
        max: this.max - 0 + 0.5,
        name: '单位:℃',
        min: this.min - 0.5,
      }, {
        gridIndex: 1,
      }],
      series: [{
        name: '数值',
        type: 'bar',
        // smooth: true,
        // symbol: 'circle',
        // symbolSize: 9,
        // showSymbol: false,
        barWidth:'40%',
        barMaxWidth:10,
        itemStyle: {
          normal: {
            color: '#219CE6',
            barBorderRadius:5
          },
        },
        markPoint: {
          itemStyle:{
            normal: {
              color: '#FFDB5C',
            },
          },
          data: [{
            type: 'max',
            name: '最大值',
          }, {
            type: 'min',
            name: '最小值',
          }],
        },
        data: this.echartsDate,
      }],
    };
    this.dldcChart = _.cloneDeep(serie);
    this.cd.markForCheck();
  }

  onEchartLoaded(event) {

  }

  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      this.getContent();
    }
    ;
  }

  change($event){
    this.len=$event;
    this.getContent();
  }
}
