﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JWTGuard } from '@delon/auth';
import { environment } from '@env/environment';
// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { UserLockComponent } from './passport/lock/lock.component';
import { MenuGuard } from '@shared';
import { SSOComponent } from './passport/sso.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [JWTGuard],
    children: [
      {path: '', redirectTo: '/dashboard/vehicle', pathMatch: 'full'},
      {
        path: 'dashboard',
        canActivate: [MenuGuard],
        data: {
          title: '平台首页', guard: 1010,
        },
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'monitoring-center', loadChildren: './monitoring-center/monitoring-center.module#MonitoringCenterModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1011 },
      },
      {
        path: 'remote-control', loadChildren: './remote-control/remote-control.module#RemoteControlModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1012 },
      },
      {
        path: 'alarm', loadChildren: './alarm/alarm.module#AlarmModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1013 },
      },
      {
        path: 'vehicle-manage', loadChildren: './vehicle-manage/vehicle-manage.module#VehicleManageModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1015 },
      },
      {
        path: 'base-info', loadChildren: './base-info/base-info.module#BaseInfoModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1015 },
      },
      {
        path: 'analyse', loadChildren: './analyse/analyse.module#AnalyseModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1016 },
      },
      {
        path: 'system', loadChildren: './system-manage/system-manage.module#SystemManageModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1020 },
      },
      { path: 'exception', loadChildren: './exception/exception.module#ExceptionModule' },
      {
        path: 'forward', loadChildren: './forward/forward.module#ForwardModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1018 },
      },
      {
        path: 'alarm-pile', loadChildren: './alarm-pile/alarm-pile.module#AlarmChargePileModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1013 },
      },
      {
        path: 'charging', loadChildren: './charging/charging.module#CharingModule',
        canActivate: [MenuGuard], canActivateChild: [MenuGuard], data: { guard: 1022 }
      },
      // 业务子模块
      // { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule' }
    ],
  },
  // 全屏布局
  // {
  //     path: 'fullscreen',
  //     component: LayoutFullScreenComponent,
  //     children: [
  //     ]
  // },
  // passport
  {
    path: 'passport/sso', component: SSOComponent, data: { title: 'SSO' },
  },
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      { path: 'login', component: UserLoginComponent, data: { title: '登录' } },
      { path: 'lock', component: UserLockComponent, data: { title: '锁屏' } },
    ],
  },
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        useHash: environment.useHash,
        // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
        // Pls refer to https://ng-alain.com/components/reuse-tab
        scrollPositionRestoration: 'top',
      },
    )],
  exports: [RouterModule],
})
export class RouteRoutingModule {
}
