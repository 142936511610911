import {SettingsService, _HttpClient} from '@delon/theme';
import {PlatformLocation} from '@angular/common';
import {Component, OnDestroy, Inject, Optional} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {
  ITokenService,
  DA_SERVICE_TOKEN,
} from '@delon/auth';
import {ReuseTabService} from '@delon/abc';
import {environment} from '@env/environment';
import {StartupService, I18NService} from '@core';

@Component({
  selector: 'passport-sso',
  template: ""
})
export class SSOComponent {
  error = '';
  type = 0;
  context = {
    username: '',
    password: '',
    timestamp: null,
    patchca: ''
  }
  patchcaUrl = null;

  constructor(
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Optional()
              @Inject(ReuseTabService)
              private reuseTabService: ReuseTabService,
              @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
              private startupSrv: StartupService,
              public http: _HttpClient,
              public msg: NzMessageService,) {
  }

  ngOnInit() {
    //从路由获取参数username, password
    const username = this.getParam("username");
    const password = this.getParam("password");
    this.context.username = username;
    this.context.password = password;
    this.submit();
  }

  
  getParam(name) {
    var urlParameters = location.href.substring(location.href.indexOf("?"))
    //声明并初始化接收请求参数的对象
    var requestParameters = new Object();
    if (urlParameters.indexOf('?') != -1) {
      var parameters = decodeURI(urlParameters.substr(1));
      //将请求的参数以&分割中字符串数组
      var parameterArray = parameters.split('&');
      for (var i = 0; i < parameterArray.length; i++) {
          requestParameters[parameterArray[i].split('=')[0]] = (parameterArray[i].split('=')[1]);
      }
    }

    return requestParameters[name];
  }

  submit() {
    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    return this.http
      .post('/auth/sso?_allow_anonymous=true', {
        type: this.type,
        username: this.context.username,
        password: this.context.password,
      }).toPromise().then((res: any) => {
        // 清空路由复用信息
        this.reuseTabService.clear();
        // 设置用户Token信息
        this.tokenService.set({token: res.accessToken});
        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load(true).then(() => {
          let url = '/dashboard';
          this.router.navigateByUrl(url);
        });
      }, error => {
        
      });
  }


}
