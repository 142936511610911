import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfCalendarComponent } from './sf-calendar.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';


@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
  ],
  declarations: [SfCalendarComponent],
  exports: [SfCalendarComponent]
})
export class SfCalendarModule { }
