import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';

@Injectable()
export class ChargingEquipmentService extends BaseService {

  moduleName = this.baseCharging + `/equipments`

  constructor(private _http: _HttpClient) {
    super();
  }

  getList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}`, query, { observe: "response" });
  }

  getEquipmentOptions() {
    return this._http.get(`${this.moduleName}/equipmentIds`);
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/query/${id}`)
  }

  update(id, obj): Observable<any> {
    obj = this.trimFormData(obj, ["chargingPileType", "remark"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }
}
