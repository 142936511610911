import * as _ from 'lodash';

export interface Authorizes {
    id: number;
    sortCode: number;
    enableFlag : boolean;
    layerLevel : number;
}
// 1010	10	10	首页	2	菜单	1	否
// 1011	11	10	车辆监控	2	菜单	1	否
// 101110	10	1011	车辆实时监控	3	菜单	2	否
// 101111	11	1011	历史数据导出	3	菜单	2	否
// 10111110	10	101111	查询	4	功能	1	否
// 10111111	11	101111	新增任务	4	功能	2	否
// 101112	12	1011	报文查询	3	菜单	2	否
// 10111210	10	101112	查询	4	功能	1	否
// 10111211	11	101112	导出	4	功能	2	否
// 101113	13	1011	多车监控	3	菜单	2	否
// 1012	12	10	远程控制	2	菜单	1	否
// 101210	10	1012	指令下发	3	菜单	2	否
// 101211	11	1012	远程升级	3	菜单	2	否
// 10121110	10	101211	升级包管理	3	菜单	1	否
// 1012111010	10	10121110	查询	4	功能	1	否
// 1012111011	11	10121110	新增	4	功能	2	否
// 1012111012	12	10121110	修改	4	功能	3	否
// 1012111013	13	10121110	删除	4	功能	4	否
// 10121111	11	101211	升级任务管理	3	菜单	2	否
// 1012111110	10	10121111	查询	4	功能	1	否
// 1012111111	11	10121111	新增	4	功能	2	否
// 1012111112	12	10121111	修改	4	功能	3	否
// 1012111113	13	10121111	删除	4	功能	4	否
// 10121112	12	101211	升级日志	3	菜单	3	否
// 1013	13	10	车辆报警	2	菜单	1	否
// 101310	10	1013	车辆实时报警	3	菜单	2	否
// 10131010	10	101310	查询	4	功能	1	否
// 10131011	11	101310	诊断	4	功能	2	否
// 101311	11	1013	车辆历史报警	3	菜单	2	否
// 10131110	10	101311	查询	4	功能	1	否
// 10131111	11	101311	诊断	4	功能	2	否
// 101312	12	1013	自定义报警策略	3	菜单	2	否
// 10131210	10	101312	查询	4	功能	1	否
// 10131211	11	101312	新增	4	功能	2	否
// 10131212	12	101312	修改	4	功能	3	否
// 10131213	13	101312	删除	4	功能	4	否
// 10131214	14	101312	国标自定义	4	功能	5	否
// 1014	14	10	车辆管理	2	菜单	1	否
// 101410	10	1014	车辆信息维护	3	菜单	2	否
// 10141010	10	101410	查询	4	功能	1	否
// 10141011	11	101410	新增	4	功能	2	否
// 10141012	12	101410	修改	4	功能	3	否
// 10141013	13	101410	删除	4	功能	4	否
// 10141014	14	101410	导入	4	功能	5	否
// 10141015	15	101410	导出	4	功能	6	否
// 1015	15	10	基础信息	2	菜单	1	否
// 101510	10	1015	车辆分组管理	3	菜单	2	否
// 10151010	10	101510	查询	4	功能	1	否
// 10151011	11	101510	新增	4	功能	2	否
// 10151012	12	101510	修改	4	功能	3	否
// 10151013	13	101510	删除	4	功能	4	否
// 10151014	14	101510	关联车辆	4	功能	5	否
// 101511	11	1015	车辆型号管理	3	菜单	2	否
// 10151110	10	101511	查询	4	功能	1	否
// 10151111	11	101511	新增	4	功能	2	否
// 10151112	12	101511	修改	4	功能	3	否
// 10151113	13	101511	删除	4	功能	4	否
// 101512	12	1015	车辆储能装置型号管理	3	菜单	2	否
// 10151210	10	101512	查询	4	功能	1	否
// 10151211	11	101512	新增	4	功能	2	否
// 10151212	12	101512	修改	4	功能	3	否
// 10151213	13	101512	删除	4	功能	4	否
// 101513	13	1015	车辆驱动电机型号管理	3	菜单	2	否
// 10151310	10	101513	查询	4	功能	1	否
// 10151311	11	101513	新增	4	功能	2	否
// 10151312	12	101513	修改	4	功能	3	否
// 10151313	13	101513	删除	4	功能	4	否
// 101514	14	1015	车辆终端型号管理	3	菜单	2	否
// 10151410	10	101514	查询	4	功能	1	否
// 10151411	11	101514	新增	4	功能	2	否
// 10151412	12	101514	修改	4	功能	3	否
// 10151413	13	101514	删除	4	功能	4	否
// 101515	15	1015	车辆发动机型号管理	3	菜单	2	否
// 10151510	10	101515	查询	4	功能	1	否
// 10151511	11	101515	新增	4	功能	2	否
// 10151512	12	101515	修改	4	功能	3	否
// 10151513	13	101515	删除	4	功能	4	否
// 1016	16	10	统计分析	2	菜单	1	否
// 101610	10	1016	充电分析	3	菜单	2	否
// 101611	11	1016	里程分析	3	菜单	2	否
// 101612	12	1016	充电汇总	3	菜单	2	否
// 10161210	10	101612	查询	4	功能	1	否
// 10161211	11	101612	导出汇总	4	功能	2	否
// 10161212	12	101612	导出明细	4	功能	3	否
// 101613	13	1016	运营趋势分析	3	菜单	2	否
// 101614	14	1016	运营情况分析	3	菜单	2	否
// 10161410	10	101614	查询	4	功能	1	否
// 10161411	11	101614	导出	4	功能	2	否
// 101615	15	1016	车辆长期离线统计	3	菜单	2	否
// 10161510	10	101615	查询	4	功能	1	否
// 10161511	11	101615	导出	4	功能	2	否
// 101616	16	1016	车辆定位异常统计	3	菜单	2	否
// 10161610	10	101616	查询	4	功能	1	否
// 10161611	11	101616	导出	4	功能	2	否
// 101617	17	1016	车辆CAN数据异常统计	3	菜单	2	否
// 10161710	10	101617	查询	4	功能	1	否
// 10161711	11	101617	导出	4	功能	2	否

export const menus = [
  {
    id: 1010,
    text: '首页',
    i18n: '首页',
    link: '/dashboard',
    icon: 'sy',
    children: [
      {
        id: 101010,
        text: '车辆分布',
        link: '/dashboard/vehicle',
        i18n: '车辆分布'
      },
      {
        id: 101011,
        text: '充电桩分布',
        link: '/dashboard/charging',
        i18n: '充电桩分布'
      }
    ]
  },
  {
    id: 1011,
    text: '车辆监控',
    i18n: '车辆监控',
    link: '/commissioning',
    icon: 'cljk',
    children: [
      {
        id: 101110,
        text: '车辆实时监控',
        link: '/monitoring-center/vehicle-monitoring',
        i18n: '车辆实时监控'
      },
      {
        id: 101111,
        text: '历史数据导出',
        link: '/monitoring-center/history-data',
        i18n: '历史数据导出'
      },
      {
        id: 101112,
        text: '报文查询',
        link: '/monitoring-center/message-query',
        i18n: '报文查询'
      },
      {
        id: 101113,
        text: '多车监控',
        link: '/monitoring-center/multi-vehicle',
        i18n: '多车监控'
      }
    ]
  },
  {
    id: 1021,
    text: '充电桩监控',
    i18n: '充电桩监控',
    link: '/charging',
    icon: 'cjzjk',
    children: [
      {
        id: 102110,
        text: '充电桩实时监控',
        link: '/charging/equipment-monitoring',
        i18n: '充电桩实时监控'
      },
      {
        id: 102111,
        text: '充电桩历史数据',
        link: '/charging/equipment-history',
        i18n: '充电桩历史数据'
      }
    ]
  },
  {
    id: 1013,
    text: '车辆报警',
    i18n: '车辆报警',
    link: '/terminal',
    icon: 'clbj',
    children: [
      {
        id: 101310,
        text: '车辆实时报警',
        link: '/alarm/reltime',
        i18n: '车辆实时报警'
      },
      {
        id: 101311,
        text: '车辆历史报警',
        link: '/alarm/history',
        i18n: '车辆历史报警'
      },
      {
        id: 101312,
        text: '自定义报警策略',
        link: '/alarm/customize',
        i18n: '自定义报警策略'
      }
    ]
  },
  {
    id: 1022,
    text: '充电桩报警',
    i18n: '充电桩报警',
    link: '/terminal',
    icon: 'cdzbj',
    children: [
      {
        id: 102210,
        text: '充电桩实时报警',
        link: '/alarm-pile/realtime',
        i18n: '充电桩实时报警'
      },
      {
        id: 102211,
        text: '充电桩历史报警',
        link: '/alarm-pile/history',
        i18n: '充电桩历史报警'
      },
      {
        id: 102212,
        text: '充电桩报警策略',
        link: '/alarm-pile/strategy',
        i18n: '充电桩报警策略'
      }
    ]
  },
  {
    id: 1016,
    text: '统计分析',
    i18n: '统计分析',
    link: '/analyse',
    icon: 'tjfx',
    children: [
      {
        id: 101610,
        text: '车辆充电分析',
        link: '/analyse/charge-statistics',
        i18n: '车辆充电分析'
      },
      {
        id: 101611,
        text: '车辆里程分析',
        link: '/analyse/mileage-statistics',
        i18n: '车辆里程分析'
      },
      {
        id: 101612,
        text: '车辆充电汇总',
        link: '/analyse/charge-data',
        i18n: '车辆充电汇总'
      },
      {
        id: 101613,
        text: '车辆运营趋势分析',
        link: '/analyse/operation-tendency',
        i18n: '车辆运营趋势分析'
      },
      {
        id: 101614,
        text: '车辆运营情况分析',
        link: '/analyse/operation-situation',
        i18n: '车辆运营情况分析'
      },
      {
        id: 101615,
        text: '车辆长期离线统计',
        link: '/analyse/long-term-offline',
        i18n: '车辆长期离线统计'
      },
      {
        id: 101616,
        text: '车辆定位异常统计',
        link: '/analyse/position-exception',
        i18n: '车辆定位异常统计'
      },
      {
        id: 101617,
        text: '车辆CAN数据异常统计',
        link: '/analyse/can-data-exception',
        i18n: '车辆CAN数据异常统计'
      },
      {
        id: 101621,
        text: '充电桩运营分析',
        link: '/analyse/charging-operators-data',
        i18n: '充电桩运营分析'
      },
      {
        id: 102622,
        text: '充电桩异常统计',
        link: '/analyse/charging-fault',
        i18n: '充电桩异常统计'
      },
      {
        id: 102623,
        text: '充电数据分析',
        link: '/analyse/charging-data-analysis',
        i18n: '充电数据分析'
      }
    ]
  },
  {
    id: 1018,
    text: '数据转发',
    i18n: '数据转发',
    link: '/forward',
    icon: 'sjzf',
    children: [
      {
        id: 101810,
        text: '转发配置',
        link: '/forward/config',
        i18n: '转发配置'
      },
      {
        id: 101811,
        text: '转发记录',
        link: '/forward/record',
        i18n: '转发记录'
      },
      {
        id: 101812,
        text: '平台符合性检测',
        link: '/forward/platform-inspect',
        i18n: '平台符合性检测'
      }
    ]
  },
  {
    text: '基础信息',
    i18n: '基础信息',
    link: '/base-info',
    icon: 'jcxx',
    children: [
      {
        id: 101516,
        text: '车辆信息维护',
        link: '/vehicle-manage/vehicle-info',
        i18n: '车辆信息维护'
      },
      {
        id: 101510,
        text: '车辆分组管理',
        link: '/base-info/vehicle-group',
        i18n: '车辆分组管理'
      },
      {
        id: 101511,
        text: '车辆型号管理',
        link: '/base-info/vehicle-model',
        i18n: '车辆型号管理'
      },
      {
        id: 101512,
        text:'车辆储能装置型号管理',
        link: '/base-info/energy-storage-model',
        i18n: '车辆储能装置型号管理'
      },
      {
        id: 101513,
        text: '车辆驱动电机型号管理',
        link: '/base-info/electric-driver-model',
        i18n: '车辆驱动电机型号管理'
      },
      {
        id: 101514,
        text: '车辆终端型号管理',
        link: '/base-info/vehicle-terminal-model',
        i18n: '车辆终端型号管理'
      },
      {
        id: 101515,
        text: '车辆发动机型号管理',
        link: '/base-info/engine-model',
        i18n: '车辆发动机型号管理'
      },
      {
        id: 101517,
        text: '充电桩管理',
        link: '/base-info/charging-model',
        i18n: '充电桩管理'
      }
    ]
  },
  {
    id: 1020,
    text: '系统管理',
    i18n: '系统管理',
    link: '/system',
    icon: 'user',
    children: [
      {
        text: '机构管理',
        id: 102011,
        link: '/system/orgination',
        i18n: '机构管理'
      },
      {
        text: '用户管理',
        id: 102010,
        link: '/system/user',
        i18n: '用户管理'
      },
      {
        id: 102012,
        text: '角色管理',
        link: '/system/role',
        i18n: '角色管理'
      },
      {
        id: 102013,
        text: '系统日志',
        link: '/system/log',
        i18n: '日志管理'
      },
      {
        id: 102014,
        text: '参数设置',
        link: '/system/param',
        i18n: '参数设置'
      },
      {
        text: '菜单管理',
        id: 102015,
        link: '/system/menu',
        i18n: '菜单管理'
      },
    ]
  }
]


export function filterMenus(authrizes: Authorizes[]| any) : any[] {
    let arr = [
    {
      "text": "主导航",
      "i18n": "main_navigation",
      "group": true,
      "hideInBreadcrumb": true,
      children : []
    }];
    let menuArr = []
    let x = 1;
    menus.map((item: any) => {
        let childArr = [];
        if(item.children && item.children.length > 0) {
          let childs: any[] = item.children;
          childs.map(child => {
              const childAuth = authrizes.find((auth) => auth.layerLevel < 4 && auth.id === child.id);
              if(childAuth) {
                  let childObj: any = {};
                  _.assign(childObj, child);
                  childObj.sortCode = childAuth.sortCode
                  childArr.push(childObj);
              }
          })
          if(childArr.length > 0) {
            childArr = _.sortBy(childArr, (item)=> item.sortCode )
          }
        }
        if(childArr.length > 0) {
          let obj: any = {};
          _.assign(obj, item);
          obj.sortCode = x;
          obj.children = childArr;

          menuArr.push(obj);
        } else {
          const auth = authrizes.find((auth) => auth.layerLevel < 4 && auth.id === item.id);
          if(auth) {
              let obj: any = {};
              _.assign(obj, item);
              obj.sortCode = auth.sortCode
              obj.children = childArr;

              menuArr.push(obj);
          }
        }
        x += 1;
    });
    if(menuArr.length > 0) {
      menuArr = _.sortBy(menuArr, (item)=> item.sortCode )
    }
    arr[0].children = menuArr;
    return arr;
}
