import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { convertTil, convertVal } from '@shared/utils/util.lang';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';

@Component({
  selector: 'vehicle-location',
  templateUrl: './vehicle-location.component.html',
  styleUrls: ['./vehicle-location.component.less'],
})
export class VehicleLocationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;
  wzxxData: any = [
    [
      { title: '定位状态', content: '' },
      { title: '经度', content: '' },
      { title: '纬度', content: '' },
    ],
  ];

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_PO_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    let prov=oriDate.PROVINCE==null?'':oriDate.PROVINCE;
    let dist=oriDate.DISTRICT==null?'':oriDate.DISTRICT;
    let city=oriDate.CITY==null?'':oriDate.CITY;
    this.wzxxData = [
      [
        { title: '定位状态', content: convertDate(oriDate.MDT_PO_GPS_STA,'MDT_PO_GPS_STA',rule) },
        { title: '经度', content: this.getLonLat(oriDate.MDT_PO_LON) },
        { title: '纬度', content: this.getLonLat(oriDate.MDT_PO_LAT) },
      ],
    ];
    this.cd.markForCheck();
  }

  getLonLat(val){
    if(val==null){
      return '';
    }
    return parseFloat(Number(val).toFixed(6));
  }


  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.wzxxData=[
          [
            { title: '定位状态', content: '' },
            { title: '经度', content: '' },
            { title: '纬度', content: '' },
          ]
        ]
        return
      }
      let oriDate=this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }

}
