import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  forwardRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
declare const echarts;
import * as _ from 'lodash';
import { BMonitoringService, LoadingService } from '@service';
import { HttpResponse } from '@angular/common/http';
import { getTotal } from '@shared/utils/response.util';

@Component({
  selector: 'bicycle-analysis',
  templateUrl: './bicycle-analysis.component.html',
  styleUrls: ['./bicycle-analysis.component.less'],
})
export class BicycleAnalysisComponent implements OnInit {
  @Input() vin: any;
  @ViewChild('timeTemplate') timeTemplate: TemplateRef<any>;
  public selectedItem = [];
  public selectedItem_1 = [];
  public columns = [];
  public columns_1 = [];
  public data = [];
  public data_1 = [];
  public pageIndex = 1;
  public pageIndex_1 = 1;
  public pageSize = 10;
  public pageSize_1 = 10;
  public total = 0;
  public total_1 = 0;
  public startTime;
  public endTime;
  public year;
  public month;
  public monthList = [
    { id: '01', name: 1 },
    { id: '02', name: 2 },
    { id: '03', name: 3 },
    { id: '04', name: 4 },
    { id: '05', name: 5 },
    { id: '06', name: 6 },
    { id: '07', name: 7 },
    { id: '08', name: 8 },
    { id: '09', name: 9 },
    { id: '10', name: 10 },
    { id: '11', name: 11 },
    { id: '12', name: 12 },
  ];
  public yearList = this.getYearList();
  startTime1;
  endTime1;
  showTop=false;
  showBottom=false;
  lineChart1: any = {};
  lineChart1_1: any = {};
  lineChart2: any = {};
  lineChart3: any = {};
  yAxis1 = {
    name: '里程(KM)',
    nameTextStyle: {
      color: '#000000',
    },
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#E1E1E1',
      },
    },
    axisLabel: {
      color: '#000000',
      fontSize: 10,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#E1E1E1',
        width: 1,
      },
    },
    min: 0,
    max: 100,
  };
  yAxis3 = {
    name: '百分比',
    nameTextStyle: {
      color: '#000000',
    },
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#E1E1E1',
      },
    },
    axisLabel: {
      color: '#000000',
      fontSize: 10,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#E1E1E1',
        width: 1,
      },
    },
    position: 'left',
    min: 0,
    max: 100,
  };
  yAxis2 = {
    name: 'SOC,速度',
    nameTextStyle: {
      color: '#000000',
    },
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#E1E1E1',
      },
    },
    axisLabel: {
      color: '#000000',
      fontSize: 10,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#E1E1E1',
        width: 1,
      },
    },
    min: 0,
    max: 100,
  };
  yAxis4 = {
    name: '充电次数',
    type: 'value',
    position: 'rigth',
    minInterval: 1,
    min: 0,
    max: 100,
    axisLine: {
      lineStyle: {
        color: '#E1E1E1',
      },
    },
    axisLabel: {
      color: '#000000',
      fontSize: 10,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#E1E1E1',
        width: 1,
      },
    },
    nameTextStyle: {
      color: '#000000',
    },
  };
  queryParam1 = {
    startDate: '',
    endDate: '',
  };
  queryParam2 = {
    startDate: '',
    endDate: '',
  };
  queryParam3 = {
    year: null,
    month: null,
  };

  constructor(
    private http: _HttpClient,
    private loadingService: LoadingService,
    private bMonitoringService: BMonitoringService,
  ) {
  }

  ngOnInit() {
    this.columns = [
      { field: 'id', hidden: true },
      { field: 'beginTime', header: '开始充电时间', width: 160, template: this.timeTemplate },
      { field: 'endTime', header: '结束充电时间', width: 160, template: this.timeTemplate },
      { field: 'chargeTimeDuration', header: '充电时长(分钟)' },
      { field: 'socDuration', header: '充电电量(%)' },
      { field: 'beginSoc', header: 'SOC_开始(%)' },
      { field: 'endSoc', header: 'SOC_结束(%)' },
    ];

    this.columns_1 = [
      { field: 'beginTime', header: '行程开始时间', width: 160, template: this.timeTemplate },
      { field: 'endTime', header: '行程结束时间', width: 160, template: this.timeTemplate },
      { field: 'timeDuration', header: '本次行驶时间(分钟)' },
      { field: 'beginSoc', header: 'SOC_开始(%)' },
      { field: 'endSoc', header: 'SOC_结束(%)' },
      { field: 'mileDuration', header: '本次里程数' },
      { field: 'energyCost', header: '本次耗电数' },
      { field: 'hundredEnergy', header: '本次百公里能耗' },
    ];

    this.query();
    this.initLineChart1();
    this.initLineChart1_1();
  }

  mileages = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
  mileagesData=[];
  mileages_1 = [];
  mileagesData_1=[];

  initLineChart1() {
    let data = {
      color: ['#FF799C'],
      gridTop: '15%',
      unit: '里程(km)',
      legend: {
        show: true,
        data: ['平均里程'],
      },
      xAxisName: '里程(Km)',
      xAxis: this.mileages,
      grid: {
        right: 80,
      },
      series: [
        {
          name: '里程',
          type: 'line',
          smooth: true,
          stack: '',
          data: this.mileagesData,
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#FF799C',
              },
            },
          },
          areaStyle: {
            normal: {
              //折线图区域颜色渐变
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#FFB8D7' },
                  { offset: 0.4, color: '#FFCBD9' },
                  { offset: 1, color: '#FFEEF0' }],
              ),
            },
          },
        },
      ],
    };
    this.lineChart1 = _.cloneDeep(data);
  }

  initLineChart1_1() {
    let data = {
      color: ['#FF799C'],
      gridTop: '15%',
      unit: '里程(km)',
      legend: {
        show: true,
        data: ['平均里程'],
      },
      xAxisName: '里程(Km)',
      xAxis: this.mileages_1,
      grid: {
        right: 80,
      },
      series: [
        {
          name: '里程',
          type: 'line',
          smooth: true,
          stack: '',
          data: this.mileagesData_1,
          markLine : {
            data : [
              {type : 'average', name: '平均值'}
            ]
          },
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#FF799C',
              },
            },
          },
          areaStyle: {
            normal: {
              //折线图区域颜色渐变
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#FFB8D7' },
                  { offset: 0.4, color: '#FFCBD9' },
                  { offset: 1, color: '#FFEEF0' }],
              ),
            },
          },
        },
      ],
    };
    this.lineChart1_1 = _.cloneDeep(data);
  }

  initLineChart2() {
    let data = {
      color: ['red', '#294995ff', 'green', '#FF799C'],
      gridTop: '15%',
      unit: '百分比',
      legend: {
        data: ['0-30Km', '30-80Km', '80Km以上'],
      },
      xAxis: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],//['10:01', '10:02', '10:03', '10:04', '10:05', '10:06', '10:07', '10:08', '10:09', '10:10', '10:11', '10:12'],
      xAxisName: '时间',
      yAxis: [this.yAxis3, this.yAxis4],
      grid: {
        right: 80,
      },
      series: [
        {
          name: '0-30Km',
          type: 'line',
          stack: '0-30Km',
          yAxisIndex: 0,
          data: [3.2, 3.3, 3.5, 3.7, 3.1, 3.5, 3.9, 4, 3.5, 3.2, 3.3, 3.7],
          itemStyle: {
            normal: {
              lineStyle: {
                color: 'red',
              },
            },
          },
        },
        {
          name: '30-80Km',
          type: 'line',
          stack: '30-80Km',
          yAxisIndex: 0,
          data: [2.1, 2.6, 2.9, 3, 2.6, 2.5, 2.2, 2.7, 2.5, 2.8, 2.1, 2],
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#294995ff',
              },
            },
          },
        },
        {
          name: '80Km以上',
          type: 'line',
          stack: '80Km以上',
          yAxisIndex: 0,
          data: [2.1, 2.6, 2.9, 3, 2.6, 2.5, 2.2, 2.7, 2.5, 2.8, 2.1, 2],
          itemStyle: {
            normal: {
              lineStyle: {
                color: 'green',
              },
            },
          },
        },
      ],
    };

    this.lineChart2 = _.cloneDeep(data);
  }

  initLineChart3() {
    let data = {
      color: ['red', 'green'],
      gridTop: '15%',
      unit: 'SOC,速度',
      legend: {
        data: ['SOC', '速度'],
      },
      xAxis: ['10:01', '10:02', '10:03', '10:04', '10:05', '10:06', '10:07', '10:08', '10:09', '10:10', '10:11', '10:12'],
      xAxisName: '里程(km)',
      xAxisType: 'value',
      yAxis: this.yAxis2,
      grid: {
        right: 80,
      },
      series: [
        {
          name: 'SOC',
          type: 'line',
          stack: 'SOC',
          data: [3.2, 3.3, 3.5, 3.7, 3.1, 3.5, 3.9, 4, 3.5, 3.2, 3.3, 3.7],
          itemStyle: {
            normal: {
              lineStyle: {
                color: 'red',
              },
            },
          },
        },
        {
          name: '速度',
          type: 'line',
          stack: '速度',
          data: [2.1, 2.6, 2.9, 3, 2.6, 2.5, 2.2, 2.7, 2.5, 2.8, 2.1, 2],
          itemStyle: {
            normal: {
              lineStyle: {
                color: 'green',
              },
            },
          },
        },
      ],
    };
    this.lineChart3 = _.cloneDeep(data);
  }

  pageIndexChange($event) {
    this.pageIndex = $event.pageIndex;
    this.search1();
  }

  pageSizeChange($event) {
    this.pageIndex = 1;
    this.pageSize = $event.pageSize;
    this.search1();
  }

  pageIndexChange_1($event) {
    this.pageIndex_1 = $event.pageIndex;
    this.search2();
  }

  pageSizeChange_1($event) {
    this.pageIndex_1 = 1;
    this.pageSize_1 = $event.pageSize;
    this.search2();
  }

  search1() {
    this.loadingService.show();
    this.bMonitoringService.pageQueryCharge(this.vin, this.queryParam1, this.pageIndex, this.pageSize).subscribe({
      next: (res: HttpResponse<any>) => {
        this.total = getTotal(res);
        this.data = res.body;
        this.loadingService.hide();
      }, error: err => {
        this.loadingService.hide();
      },
    });
  }

  search2() {
    this.bMonitoringService.pageQuerymileageDetail(this.vin, this.queryParam2, this.pageIndex_1, this.pageSize_1).subscribe({
      next: (res: HttpResponse<any>) => {
        this.total_1 = getTotal(res);
        this.data_1 = res.body;
        this.loadingService.hide();
      }, error: err => {
        this.loadingService.hide();
      },
    });
  }

  search3() {
    this.queryCharts();
  }

  reset() {
    this.queryParam3 = {
      year: null,
      month: null,
    };
    this.queryCharts();
  }

  timesDate1 = [];
  timesDate2 = [];

  query() {
    this.loadingService.show();
    this.bMonitoringService.pageQueryCharge(this.vin, this.queryParam1, this.pageIndex, this.pageSize).subscribe({
      next: (res: HttpResponse<any>) => {
        this.total = getTotal(res);
        this.data = res.body;
        this.loadingService.hide();
      }, error: err => {
        this.loadingService.hide();
      },
    });

    this.bMonitoringService.pageQuerymileageDetail(this.vin, this.queryParam2, this.pageIndex_1, this.pageSize_1).subscribe({
      next: (res: HttpResponse<any>) => {
        this.total_1 = getTotal(res);
        this.data_1 = res.body;
        this.loadingService.hide();
      }, error: err => {
        this.loadingService.hide();
      },
    });
  }

  queryCharts() {
    this.showTop=false;
    this.showBottom=false;
    if(this.queryParam3.year==null){
      return
    }
    this.timesDate1=[];
    this.timesDate2=[];
    this.mileagesData=[];
    this.mileages_1=[];
    this.mileagesData_1=[];
    let obj={
      flag:'',
      time:null,
    };
    if(this.queryParam3.month==null){
      obj.flag='year';
      obj.time=new Date(this.queryParam3.year+'/01').getTime();
      this.showTop=true;
      this.showBottom=false;
      this.bMonitoringService.pageQueryMileageInfo(this.vin, obj).subscribe({
        next: (res: HttpResponse<any>) => {
          let arr=res.body;
          for(let i=0;i<arr.length;i++){
            this.timesDate1.push(arr[i]);
            this.mileagesData.push(arr[i].avgMile);
          };
          this.initLineChart1();
          this.loadingService.hide();
        }, error: err => {
          this.loadingService.hide();
        },
      });
    }else{
      obj.flag='month';
      obj.time=new Date(this.queryParam3.year+'/'+this.queryParam3.month).getTime();
      this.showTop=false;
      this.showBottom=true;
      this.bMonitoringService.pageQueryMileageInfo(this.vin, obj).subscribe({
        next: (res: HttpResponse<any>) => {
          let arr=res.body;
          for(let i=0;i<arr.length;i++){
            this.timesDate2.push(arr[i]);
            this.mileagesData_1.push(arr[i].dayMiles);
            this.mileages_1.push(((i+1)+'日'));
          };
          this.initLineChart1_1();
          this.loadingService.hide();
        }, error: err => {
          this.loadingService.hide();
        },
      });
    }
  }

  getYearList() {
    let yearList = [];
    for (let i=2018;i<=new Date().getFullYear();i++) {
      let obj = {id: null, name: null};
      obj.id = i;
      obj.name = i;
      yearList.push(obj);
    }
    return yearList;
  }
}
