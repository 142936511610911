import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Output } from 'node_modules/@angular/core';

export const EXE_COUNTER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SfCalendarComponent),
  multi: true,
};

export const EXE_COUNTER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SfCalendarComponent),
  multi: true,
};


@Component({
  selector: 'sf-calendar',
  templateUrl: './sf-calendar.component.html',
  styleUrls: ['./sf-calendar.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SfCalendarComponent),
    multi: true,
  }],
})
export class SfCalendarComponent implements OnInit, ControlValueAccessor {
  @Input() appendTo = 'body';
  @Input() placeholder;
  @Input() showIcon = true;
  @Input() showButtonBar = true;
  @Input() data: any = '';
  @Input() dataType = 'date';
  @Input() dateFormat = 'yy-mm-dd';
  @Input() yearRange = null;
  @Input() yearNavigator = false;
  @Input() monthNavigator = false;
  @Input() showTime = false;
  @Input() showSeconds = false;
  @Input() disabled = false;
  @Input() minDate;
  @Input() maxDate;
  @Input() view = 'date';
  @Input() selectionMode = 'single';
  @Input() locale = {
    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'mm/dd/yy',
  };
  @Output() onSelect = new EventEmitter<any>();

  onselect($event){
    this.onSelect.emit($event);
  }

  constructor(
    private translate: TranslateService,
  ) {

  }

  get cal() {
    return this.data;
  }

  set cal(value) {
    this.data = value;
    this.propagateChange(this.data);
    this.propagateTouched(this.data);
  }

  propagateChange = (_: any) => {
  };
  propagateTouched = (_: any) => {
  };

  writeValue(value: any) {
    this.cal = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  ngOnInit() {
    this.translate.get('setLanguage').subscribe((res) => {
      if (res == 'zh-CN') {
        this.locale = {
          firstDayOfWeek: 0,
          dayNames: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
          dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
          dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
          monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          monthNamesShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          today: '今天',
          clear: '清除',
          dateFormat: 'mm/dd/yy',
        };
      } else {
        this.locale = {
          firstDayOfWeek: 0,
          dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          today: 'Today',
          clear: 'Clear',
          dateFormat: 'mm/dd/yy',
        };
      }
      ;
    });
  }

}
