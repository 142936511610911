import {
  Component,
  OnInit,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ContentChild,
  ElementRef,
  OnChanges, HostListener,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import set = Reflect.set;

@Component({
  selector: 'sf-dialog',
  templateUrl: './sf-dialog.component.html',
  styleUrls: ['./sf-dialog.component.less'],
})
export class SfDialogComponent implements OnInit, OnChanges {

  private visibleValue;
  @Input()
  get visible(){
    return this.visibleValue;
  }
  set visible(val){
    this.visibleValue = val;
    this.visibleChange.emit(this.visibleValue);
  }

  @Output() visibleChange = new EventEmitter<any>();

  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() showCancel = true;
  @Input() title = '';

  constructor(
    private http: _HttpClient,
    private cd: ChangeDetectorRef,
    private ref: ElementRef,
  ) {
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

  ngOnChanges() {

  }

  cancel(){
    this.visible=false;
  }

}
