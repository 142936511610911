import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
    selector: 'app-radar',
    templateUrl: './radar.component.html',
    styleUrls: ['./radar.component.less']
})
export class RadarComponent implements OnInit, OnChanges {

    @Input() legendData: any = [];
    @Input() indicatorData: any = [];
    @Input() seriesData: any = [];
    @Input() title?: any = [];
    @Input() height: number = 220;
    @Input() legend: boolean = true;
    option: any;
    constructor() { }

    ngOnInit() {
        this.initRadar();
    }
    ngOnChanges() {
        this.initRadar();
    }
    initRadar() {
        this.option = {
            title: { text: this.title },
            tooltip: {
                // formatter: (param: any, arr: any[]) => {
                //     let d = param.data.name + "<br>";
                //     param.data.value.forEach(item => {
                //         d += item + '%</span><br>'
                //     });
                //     return d;
                // }
            },
            legend: {
                data: this.legendData,
                type:'scroll',
                textStyle:{
                    color:'#fff'
                },
                bottom:0,
                pageTextStyle:{
                    color:'#fff'
                },
                show:this.legend
            },
            radar: {
                center: ['50%', '55%'],
                name: {
                    textStyle: {
                        color: '#fff',
                        backgroundColor: '',
                        borderRadius: 3,
                        padding: [0, 0]
                    }
                },
                indicator: this.indicatorData
            },
            series: [{
                name: '',
                type: 'radar',
                data: this.seriesData,
                itemStyle: {
                    normal: {
                        color: "#8CAAA8", // 图表中各个图区域的边框线拐点颜色
                        lineStyle: {
                            color: "#8CAAA8" // 图表中各个图区域的边框线颜色
                        },
                        areaStyle: {
                            type: 'default',
                            opacity: 0.5, // 图表中各个图区域的透明度
                            color: "#3A82AC" // 图表中各个图区域的颜色
                        }
                    }
                },
            }]
        }
    }
}
