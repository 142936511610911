import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  forwardRef,
  ViewChild,
  TemplateRef,
  Inject,
  ComponentFactoryResolver,
  ChangeDetectorRef,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import {
  BMonitoringService,
  HistoryInfoService,
  LoadingService,
  NoticeService,
  VehicleMonitoringService,
} from '@service';
import { getTotal } from '@shared/utils/response.util';
import { HttpResponse } from '@angular/common/http';
import { FtpDownLoadExcelUrl, parseQueryStr } from '@shared/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { convertVal, convertTil } from '@shared/utils/util.lang';
import { ModalService } from '../../modal/modal.service';
import { HistoricalDataRealtimeComponent } from './historical-data-realtime/historical-data-realtime.component';
import { DA_SERVICE_TOKEN, ITokenService } from "@delon/auth";
import * as _ from 'lodash';

@Component({
  selector: 'historical-data',
  templateUrl: './historical-data.component.html',
  styleUrls: ['./historical-data.component.less'],
})
export class HistoricalDataComponent implements OnInit {
  public columns = [];
  public data = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 1;
  public loading = false;
  public selectedItem = [];
  public queryParam = {
    beginTime: null,
    endTime: null,
    keys: {}
  };
  @ViewChild('viewTemplate') viewTemplate: TemplateRef<any>;
  @ViewChild('timeTemplate') timeTemplate: TemplateRef<any>;
  @Input() vin: any;
  @ViewChild('tree') tree;

  constructor(
    private http: _HttpClient,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer,
    private bMonitoringService: BMonitoringService,
    private modalService: ModalService,
    private historyService: HistoryInfoService,
    private cd: ChangeDetectorRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private noticeService: NoticeService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  tag = 'MDT_O_DA_TYPE';
  map = {};
  allMap = {};


  fy2() {
    this.data = [];
    let da = this.tempData.slice((this.pageIndex - 1) * this.pageSize, (this.pageIndex - 1) * this.pageSize + this.pageSize);
    this.data = _.cloneDeep(da)
  }

  ngOnInit() {
    this.columns = [
      // { field: 'time', header: '采集数据时间', width: 160 ,template:this.timeTemplate},
      // { field: 'MDT_O_VEH_STA', header: '车辆状态' },
      // { field: 'MDT_O_CHARGE_STA', header: '充电状态' },
      // { field: 'MDT_O_SPEED', header: '车速' },
      // { field: 'MDT_O_MILEAGE', header: '累计里程' },
      // { field: 'MDT_O_SOC', header: 'SOC' },
      // { field: 'MDT_O_TOTAL_VOL', header: '总电压' },
      // { field: 'MDT_O_TOTAL_CUR', header: '总电流' },
      // { field: 'MDT_O_DC_STA', header: 'DC-DC' },
      // { field: 'MDT_O_RES', header: '绝缘电阻' },
      { field: 'cz', header: '查看详情', template: this.viewTemplate, width: 80 },
    ];
    this.bMonitoringService.getDictionary(
      {
        codes:
          [
            'MDT_O_DA_TYPE',
            'MDT_DR_DA_TYPE',
            'MDT_EN_DA_TYPE',
            'MDT_P_DA_TYPE',
            'MDT_A_DA_TYPE',
            'MDT_PO_DA_TYPE',
            'MDT_BA_DA_TYPE',
            'CM_A_DA',
          ],
      }).subscribe((res) => {
        this.allMap = res.body;
        this.map = res.body[this.tag];
        // this.query();
      });
    this.getTreeList();
  }

  pageIndexChange($event) {
    this.pageIndex = $event.pageIndex;
    this.fy2();
  }

  pageSizeChange($event) {
    this.pageIndex = 1;
    this.pageSize = $event.pageSize;
    this.fy2();
  }

  temp = [];
  tempData = [];

  query(reset = false) {
    if (!this.queryParam.beginTime || !this.queryParam.endTime) {
      this.noticeService.info('validation.dateAndVin.required', 'warning');
      return false;
    }
    if (Object.keys(this.queryParam.keys).length == 0) {
      this.noticeService.info('请选择要显示的数据项再进行查询', 'warning');
      return false;
    }

    let fiveDay=3*24*60*60*1000;
    let t1:any=this.queryParam.beginTime;
    let t2:any=this.queryParam.endTime;
    if(Math.abs(t2.getTime()-t1.getTime())>fiveDay){
      this.noticeService.info('时间间隔大于3天', 'warning');
      return false;
    };


    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingService.show();
    let query = _.cloneDeep(this.queryParam);
    query.beginTime = query.beginTime.getTime();
    query.endTime = query.endTime.getTime();
    query.vin = this.vin;
    this.historyService.pageQueryBy(query, this.pageIndex, this.pageSize).subscribe({
      next: (res) => {
        // this.loadingService.hide();
        // this.total = res.body.records.length;
        // this.temp = res.body.records;
        console.log('res');
        console.log(res);
        this.loadingService.hide();
        let col = res.columns;
        col.forEach((item) => {
          let len = item.header.length;
          len = len * 12 + 16;
          if (len < 180) {
            len = 180;
          }
          item.width = len;
        })
        col.unshift({ field: 'cz', header: '查看详情', template: this.viewTemplate, width: 80 })
        this.columns = col;
        this.tempData = res.pageData;
        this.temp = res.records;
        this.total = this.tempData.length;
        this.data = this.tempData.slice(this.pageIndex - 1, this.pageSize);

        // this.fyquery();
      }, error: err => {
        this.loadingService.hide();
      },
    });
  }

  fyquery() {
    this.data = [];
    let da = this.temp.slice((this.pageIndex - 1) * this.pageSize, (this.pageIndex - 1) * this.pageSize + this.pageSize);
    for (let i = 0; i < da.length; i++) {
      this.data.push(Object.assign({ time: da[i].time }, this.getContent(JSON.parse(da[i].body)[this.tag], this.map)));
    }
  }

  getContent(content, smallLm) {
    for (let n in content) {
      for (let val of smallLm) {
        if (val.value == n) {
          content[n] = convertVal(content[n], val.values, val.caculateScript, val.unit)
        }
      }
    }
    return content;
  }

  exportFileUrl: any;

  view(index) {
    let arrIndex = (this.pageIndex - 1) * this.pageSize + index;//记录当前行所在位置
    this.modalService.open<string>({
      component: HistoricalDataRealtimeComponent,
      componentFactoryResolver: this.componentFactoryResolver,
      resolve: {
        map: this.allMap,
        temp: this.temp,
        arrIndex: arrIndex,
      },
      width: 900,
      height: 'auto',
      title: '详情'
    })
      .subscribe(data => {
        console.log(data);
      }, error => {
      });
  }

  reset() {
    this.queryParam.beginTime = null;
    this.queryParam.endTime = null;
    this.pageIndex = 1;
    this.fy2();
  }

  public exportQuery: any = {};
  exportData() {
    if (!this.queryParam.beginTime || !this.queryParam.endTime) {
      this.noticeService.info('validation.dateAndVin.required', 'warning');
    }
    let cloneKeys = Object.keys(_.cloneDeep(this.queryParam).keys);
    if (cloneKeys.length == 0) {
      this.noticeService.info('keys_is_empty', 'warning');
      return false;
    }
    this.loadingService.show();
    this.exportQuery = _.cloneDeep(this.queryParam);
    this.exportQuery['keysJson'] = JSON.stringify(this.exportQuery['keys']);
    delete this.exportQuery['keys'];
    this.exportQuery.vin = this.vin;
    this.exportFileUrl = null;
    let url = 'api/iov/historicalData/queryHistoryBySingleVehicle/export';
    this.exportQuery['beginTime'] = this.exportQuery['beginTime'].getTime();
    this.exportQuery['endTime'] = this.exportQuery['endTime'].getTime();
    url += parseQueryStr(this.exportQuery);
    console.log(url);
    this.exportFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url + "&token=Bearer " + this.tokenService.get().token);

    this.noticeService.info('notice_export_success', 'success');
    this.loadingService.hide();
  }

  getTreeList() {
    this.historyService.getTreeList().subscribe((res) => {
      this.nodes=_.cloneDeep(res);
      this.defaultCheckedKeys.push(this.nodes[0].key);
      this.defaultCheckedKeys.push(this.nodes[1].key);
      this.defaultCheckedKeys=_.cloneDeep(this.defaultCheckedKeys)
      this.cd.markForCheck();
      this.queryParam.keys={
        BASE_VEHICLE_DA:["VIN","PLATE_NUMBER","TERMINAL_CODE","MODEL"],
        MDT_O_DA_TYPE:["MDT_O_VEH_STA","MDT_O_CHARGE_STA","MDT_O_POW_MODE","MDT_O_SPEED","MDT_O_MILEAGE","MDT_O_TOTAL_VOL","MDT_O_TOTAL_CUR","MDT_O_SOC","MDT_O_DC_STA","MDT_O_GEAR","MDT_O_RES","MDT_O_BRAKING_STA","MDT_O_ASC_LEN","MDT_O_DEC_LEN"]
      }
    })
  }

  nzEvent($event) {
    let list = this.tree.getTreeNodes(), obj = {};
    for (let i = 0; i < list.length; i++) {
      let key = list[i].origin.itemCode,
        idxArray = [],
        child = list[i].children;
      for (let j = 0; j < child.length; j++) {
        if (child[j].isChecked) {
          idxArray.push(child[j].origin.itemCode);
        }
      }
      if (idxArray.length != 0) {
        obj[key] = idxArray;
      }
    }

    this.queryParam.keys = obj;
  }

  nodes = [];
  defaultCheckedKeys = ['1','2'];

}
