import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, _HttpClient } from '@delon/theme';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ACLService } from '@delon/acl';
import { NoticeService } from "@service";
// import { ChangePasswordComponent } from '../../change-password/change-password.component';
// import { OrgInfoComponent } from '../../org-info/org-info.component';
import { ComponentFactoryResolver } from '@angular/core';
import { DialogService, ModalService } from '@components';
import { modifyPasswordComponent } from "../../modify-password/modify-password.component";
import * as _ from 'lodash';

// import { UserType } from '@shared/utils';

@Component({
  selector: 'header-user',
  template: `
  <nz-dropdown nzPlacement="bottomRight">
    <div class="topBarUser" nz-dropdown>
      <!--<span class="label-username">{{settings.user.loginName}}</span>-->
      <!--<nz-avatar [nzShape]="'square'" [nzSrc]="settings.user.avatar" nzSize="small" class="mr-sm ml-sm"></nz-avatar>-->
    </div>
    <div nz-menu class="width-sm">
      <div nz-menu-item (click)="changePassword()"><i nz-icon type="key" class="mr-sm"></i>
        {{ "修改密码" | translate }}
      </div>
      <div nz-menu-item (click)="logout()"><i nz-icon type="logout" class="mr-sm"></i>
        {{ 'menu.account.logout' | translate }}
      </div>
    </div>
  </nz-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserComponent {

  admin = false;
  inner = false;

  constructor(
    public settings: SettingsService,
    private aclService: ACLService,
    private _http: _HttpClient,
    private _acl: ACLService,
    private router: Router,
    private modalService: ModalService,
    private dialogService: DialogService,
    private noticeService: NoticeService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
  }

  ngOnInit() {
    // if(this._acl.can(UserType.admin)) {
    //   this.admin = true;
    // }
    // if(this._acl.can(UserType.inner)){
    //   this.inner = true;
    // }
  }

  logout() {
    this.dialogService.confirm<string>({
      resolve: {
        content: '确定退出登录吗？',
      },
    }).subscribe(data => {
      // this._http.post("/auth/logout").subscribe(data => {
      //   this.clearAndNav();
      // }, () => this.clearAndNav())
      this.aclService.attachAbility([]);
      this.settings.setUser(null);
      this.tokenService.clear();
      this.router.navigateByUrl(this.tokenService.login_url);
    }, error => {
    });
  }

  private clearAndNav() {
    this.aclService.setRole([]);
    this.aclService.setAbility([]);
    this.settings.setUser(null);
    this.tokenService.clear();
    this.router.navigateByUrl(this.tokenService.login_url);
  }

  changePassword() {
    this.modalService.open<string>({
      component: modifyPasswordComponent,
      componentFactoryResolver: this.componentFactoryResolver,
      resolve: {},
      width: 400,
      height: 'auto',
      title: '修改密码'
    }).subscribe(data => {
      this.noticeService.info('notice_update_success', 'success');
    }, error => {
    });
  }

}
