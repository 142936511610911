import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormItemComponent } from './sf-form-item.component';
import { SfFormLabelComponent } from './sf-form-label.component';
import { SfFormControlComponent } from './sf-form-control.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
  ],
  declarations: [
    SfFormItemComponent,
    SfFormLabelComponent,
    SfFormControlComponent
  ],
  exports: [
    SfFormItemComponent,
    SfFormLabelComponent,
    SfFormControlComponent
  ]
})
export class SfFormItemModule { }
