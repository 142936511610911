import * as _ from 'lodash';
import * as df from 'date-fns';
import JSEncrypt from 'jsencrypt'

export function idsOfArr(arr: any[]) {
  let ids = [];
  arr.forEach(obj => {
    ids.push(obj.id);
  });
  return ids;
}

export function optionValues(arr: any[]) {
  let vals = [];
  arr.forEach(obj => {
    vals.push(obj.value);
  });
  return vals;
}

export function appIdsOfArr(arr: any[]) {
  let ids = [];
  arr.forEach(obj => {
    ids.push(obj.appId);
  });
  return ids;
}

export function formatDateTime(val: Date | string | number): string {
  const d = df.format(val, 'YYYY-MM-DD HH:mm:ss');
  return d == null ? null : d;
}

export function formatStartDateTime(val: Date | string | number): string {
  const d = formatDate(val);
  return d == null ? null : d + ' 00:00:00';
}

export function formatEndDateTime(val: Date | string | number): string {
  const d = formatDate(val);
  return d == null ? null : d + ' 23:59:59';
}

export function formatDate(val: Date | string | number): string {
  if (!val) {
    return '';
  }
  ;
  const d = df.format(val, 'YYYY-MM-DD');
  return 'Invalid Date' === d ? null : d;

}

export function formatDateYMD(val: Date | string | number): string {
  if (!val) {
    return '';
  }
  ;
  const d = df.format(val, 'YYYYMMDD');
  return 'Invalid Date' === d ? null : d;

}

export function formatTime(val: Date | string | number): string {
  const d = df.format(val, 'HH:mm:ss');
  return 'Invalid Date' === d ? null : d;
}

export function formatFloat(val, fix = 1) {
  if (val) {
    if (fix == 1) {
      return Math.round(val * 10) / 10;
    } else if (fix == 2) {
      return Math.round(val * 100) / 100;
    } else if (fix == 4) {
      return Math.round(val * 10000) / 10000;
    }
  }
  return val;
}

export function startOfMonth() {
  return df.startOfMonth(new Date());
}

export function endOfMonth() {
  return df.endOfMonth(Date.now());
}

// df.startOfMonth()

export function formatCycle(val: Date | string | number): string {
  return df.format(val, 'YYYYMM');
}


var _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export function encode(input) {
  var output = '';
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;
  input = _utf8_encode(input);
  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output = output + _keyStr.charAt(enc1)
      + _keyStr.charAt(enc2) + _keyStr.charAt(enc3)
      + _keyStr.charAt(enc4);
  }
  return output;
}

export function parseDate(val: string): Date {
  if (!val) {
    return new Date();
  } else if (val.length === 10) {
    val += ' 00:00:00';
  }
  let d = new Date();
  var arr = val.split(' ');
  var ymd = arr[0].split('-');
  var hms = arr[1].split(':');
  d.setFullYear(parseInt(ymd[0]));
  d.setMonth(parseInt(ymd[1]) - 1);
  d.setDate(parseInt(ymd[2]));

  d.setHours(parseInt(hms[0]));
  d.setMinutes(parseInt(hms[1]));
  d.setSeconds(parseInt(hms[2]));
  return d;
}


function _utf8_encode(string) {
  string = string.replace(/\r\n/g, '\n');
  var utftext = '';
  for (var n = 0; n < string.length; n++) {
    var c = string.charCodeAt(n);
    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if ((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }

  }
  return utftext;
}

export function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

export function isDigits(value: any) {
  return /^\d+$/.test(value) ? true : false;
}

export function isNumber(value: any) {
  return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value) ? true : false;
}

export function evalErrorMessage(msg: string, fields: any) {
  let reg = /\{.*\}/g;
  let arr = msg.match(reg);
  if (arr && arr.length > 0) {
    let str = arr[0].substr(1);
    str = str.substr(0, str.length - 1);
    return fields[str] + msg.substr(arr[0].length);
  }
  return msg;
}

export function trimFormData(obj, fields: string[]) {
  _.forIn(obj, (value, key) => {
    if (_.indexOf(fields, key) != -1 && _.isString(value)) {
      obj[key] = _.trim(value);
    }
  });
  // console.log(obj);
  return obj;
}

export function addObjToArrIdx(arr: any[], obj: any, idx) {
  if (idx == 0) {
    arr.unshift(obj);
  } else {
    arr.splice(idx, 0, obj);
  }
  return arr;
}

//parse the params, field query append to url params, data is the request body
export function encodeParams(params: string) {
  params = params.replace(/\%/g, '%25'); // % = %25
  params = params.replace(/\#/g, '%23'); // # = %23
  params = params.replace(/\+/g, '%2B'); // + = %2B
  params = params.replace(/\//g, '%2F'); // / = %2F
  params = params.replace(/\?/g, '%2F'); // ? = %3F
  params = params.replace(/\&/g, '%26'); // & = %26
  params = params.replace(/\=/g, '%26'); // = = %3D
  // params = params.replace(/\:/g, '%3A'); // : = %3A
  return encodeURI(params);
}

export function parseQueryStr(params: any) {
  let querys: string[] = [];
  let queryStr: string = '';

  if (_.isString(params) || _.isNumber(params)) {
    queryStr = '?' + encodeParams(params + '');
  } else {
    for (let p in params) {
      if (_.isArray(params[p])) {
        for (let x in params[p]) {
          querys.push(p + '=' + encodeParams(params[p][x] + ''));
        }
      } else {
        if (params[p] !== undefined && params[p] !== null) {
          querys.push(p + '=' + encodeParams(params[p] + ''));
        }
      }
    }
    if (querys.length > 0) {
      queryStr = '?' + querys.join('&');
    }
  }
  return queryStr;
}

export function labelFromOptions(options, value) {
  if (!value) return value;
  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    if (element.value && value === parseInt(element.value)) {
      return element.label;
    }
  }
  return value;
}

export function queryLabelByLong(options, value) {
  if (!value) return value;
  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    if (element.value && String(value) === String(element.value)) {
      return element.label;
    }
  }
  return value;
}

export function getIds(idsArray, id = 'id') {
  if (!idsArray) return;
  let idlist = [];
  idsArray.forEach((obj) => {
    idlist.push(obj[id]);
  });
  return idlist;
}

export function formatTimeTemplete(val) {
  if (!val) {
    return '';
  }
  ;
  let t = new Date(Number(val));
  let year = t.getFullYear();
  let month = t.getMonth() + 1 < 10 ? '0' + (t.getMonth() + 1) : t.getMonth() + 1;
  let day = t.getDate() < 10 ? '0' + t.getDate() : t.getDate();
  return year + '/' + month + '/' + day;
}

export function formatTimesTemplete(val, symbol) {
  if (!val) {
    return '';
  }
  ;
  let t = new Date(Number(val));
  let year = t.getFullYear();
  let month = t.getMonth() + 1 < 10 ? '0' + (t.getMonth() + 1) : t.getMonth() + 1;
  let day = t.getDate() < 10 ? '0' + t.getDate() : t.getDate();
  let hour = t.getHours() < 10 ? '0' + t.getHours() : t.getHours();
  let min = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes();
  let sec = t.getSeconds() < 10 ? '0' + t.getSeconds() : t.getSeconds();
  return year + symbol + month + symbol + day + ' ' + hour + ':' + min + ':' + sec;
}

export function compareDate(val, Dates, DateFromName, DateEndName) {
  let vals = _.cloneDeep(val);
  vals = new Date(vals).getTime();
  let from = _.cloneDeep(Dates[DateFromName]);
  let to = _.cloneDeep(Dates[DateEndName]);
  let _from = from;
  let _to = to;
  if (!from || !to) {
    return;
  }
  from = new Date(from).getTime();
  to = new Date(to).getTime();
  if (from > to) {
    if (vals == from) {
      Dates[DateFromName] = _to;
    }
    if (vals == to) {
      Dates[DateEndName] = _from;
    }
  }
  ;
}

export function formatRawdata(str) {
  const step = 2;
  let result = str.substr(0, step);
  let idx = 2;
  for (; idx < str.length; idx += step) {
    result += ' ' + str.substr(idx, step);
  }
  if (str.length > idx) {
    result += ' ' + str.substr(idx);
  }
  return result;
}

export function trimHex(hex) {
  let str = '0000' + hex;
  return '0x' + str.substr(hex.length, 4);
}


const regLeft = new RegExp('\"{', 'g');
const regRight = new RegExp('}\"', 'g');

export function strToJson(str) {
  try {
    str = _.replace(str, regLeft, '{');// item.body.replace('\"{', "{");
    str = _.replace(str, regRight, '}');//item.body.replace('\"}', "}");
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
  ;
}

export function getSingleSelect(sel) {
  if (_.isArray(sel)) {
    return sel.length == 1;
  } else if (_.isObject(sel)) {
    return true;
  }
  return false;
}

export function getMultiSelect(sel) {
  if (_.isArray(sel)) {
    return sel.length > 0;
  } else if (_.isObject(sel)) {
    return true;
  }
  return false;
}


//转换参数
export function converParams(params) {
  let array = [];
  for (var key in params) {
    let param = params[key];
    if (!_.isNumber(param) && !_.isString(param)) {
      continue;
    }
    param = String(param).trim();
    if (!param) {
      continue;
    }
    array.push(`${key}=${param}`);
  }
  if (array.length == 0) {
    return '';
  } else {
    return '?' + array.join('&');
  }
}

export function converPostParams(param) {
  let params = _.cloneDeep(param);
  for (let key in params) {
    if (_.isString(params[key])) {
      params[key] = params[key].trim();
    }
  }
  return params;
}


export function getManufacturers(manufacturersArray, manufacturer = 'manufacturer') {
  if (!manufacturersArray) return;
  let manufacturerlist = [];
  manufacturersArray.forEach((obj) => {
    manufacturerlist.push(obj[manufacturer]);
  });
  return manufacturerlist;
}

export function getTerminalModels(terminalModelsArray, terminalModel = 'terminalModel') {
  if (!terminalModelsArray) return;
  let terminalModelList = [];
  terminalModelsArray.forEach((obj) => {
    terminalModelList.push(obj[terminalModel]);
  });
  return terminalModelList;
}


export function convertVal(value, values, cal, unit) {
  if (values == 'VALUE') {
    return value;
  }
  if (values == 'RANGE') {
    return value + cal;
  }
  if (values == 'OPTIONS') {
    let obj = JSON.parse(cal);
    for (let key in obj) {
      if (key == value) {
        return obj[key];
      }
    }
    return value;
  }
}

export function convertTil(value, unit) {
  if (unit == null || unit == '') {
    return value;
  } else {
    return value + '(' + unit + ')';
  }
}


export function parseExportStr(params: any) {
  let querys: string[] = [];
  let queryStr: string = '';

  if (_.isString(params) || _.isNumber(params)) {
    queryStr = encodeParams(params + '');
  } else {
    for (let p in params) {
      if (_.isArray(params[p])) {
        for (let x in params[p]) {
          if (params[p][x] !== undefined && params[p][x] !== null) {
            if (_.isDate(params[p][x])) {
              querys.push(p + '=' + encodeParams(new Date(params[p][x]).getTime() + ''));
            } else {
              params[p][x] = _.trim(params[p][x]);
              if (params[p][x] == '') {
                continue;
              }
              querys.push(p + '=' + encodeParams(params[p][x] + ''));
            }
          }
        }
      } else {
        if (params[p] !== undefined && params[p] !== null) {
          if (_.isDate(params[p])) {
            querys.push(p + '=' + encodeParams(new Date(params[p]).getTime() + ''));
          } else {
            params[p] = _.trim(params[p]);
            if (params[p] == '') {
              continue;
            }
            querys.push(p + '=' + encodeParams(params[p] + ''));
          }
        }
      }
    }
    if (querys.length > 0) {
      queryStr = querys.join('&');
    }
  }
  return queryStr;
}


export function convertDate(val, key, rule) {
  let ru = null;
  for (let i = 0; i < rule.length; i++) {
    if (rule[i].key == key) {
      ru = rule[i].ru;
    }
  }
  if (ru == null) {
    if (isNumbers(val)) {
      return parseFloat(Number(val).toFixed(2));
    } else {
      return val;
    }
  } else {
    for (let keys in ru) {
      if (keys == val) {
        return ru[keys];
      }
    }
  }

  return '';
}

function isNumbers(val) {
  var regPos = /^\d+(\.\d+)?$/; //非负浮点数
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
}


export function toDecimal(x) {
  if (isNaN(x)) {
    return x;
  }
  let f = Math.round(x * 100) / 100;
  return f;
}


export function toDecimalFixed(x) {
  if (isNaN(x)) {
    return x;
  }
  let f = (Math.round(x * 100) / 100).toFixed(2);
  return f;
}


export function convertDbcBo(arr) {
  if (arr && arr.length > 0) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      let obj = {};
      let rowExpansion = arr[i]['rowExpansion'];
      delete arr[i]['rowExpansion'];
      obj['data'] = arr[i];
      obj['parent'] = null;
      if (rowExpansion && rowExpansion.length > 0) {
        let tempArr = [];
        for (let j = 0; j < rowExpansion.length; j++) {
          let obj1 = {};
          obj1['data'] = rowExpansion[j];
          obj1['children'] = null;
          obj1['parent'] = arr[i].id;
          tempArr.push(obj1);
        }
        obj['children'] = tempArr;
      } else {
        obj['children'] = null;
      }
      result.push(obj);
    }
    return result;
  }
}

//将秒转化为时分秒
export function formateSeconds(second, float) {
  let secondTime = typeof second === 'number' ? second : parseInt(second); //将传入的秒的值转化为Number 45632
  let min = 0; //初始化分
  let h = 0; //初始化小时
  let result = '';
  if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
    min = secondTime / 60; //获取分钟，除以60取整数，得到整数分钟
    secondTime = secondTime % 60; //获取秒数，秒数取佘，得到整数秒数
    if (min > 60) { //如果分钟大于60，将分钟转换成小时
      h = min / 60; //获取小时，获取分钟除以60，得到整数小时
      min = min % 60; //获取小时后取佘的分，获取分钟除以60取佘的分
    }
  }
  if (float) {
    h = parseInt(String(h));
    min = Math.round(min);
    result = `${h}.${min}`;
  } else {
    result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`;
  }
  return result;
}

//RSA加密
export function rsaStr(str) {
  let publicKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7HBk3dyphA1xbuihg/5TVUARTGyv+ikY3yKCxmfoskvXi4eicy4D1eTfPrkir2WYHpeqD2/JPw2ttZzgStW5Zx9NHAdds+GJ0v8BP8l9m2JqxmTw7S3F7GfI1AKJ8w0PFgRc7qSJMCm1NJOrjP+nQhvCN9ishYFx4/Gm9ocKRQwIDAQAB-----END PUBLIC KEY-----';
  let encrypt = new JSEncrypt({});
  encrypt.setPublicKey(publicKey);
  let signature = encrypt.encrypt(str);
  return signature;
}
