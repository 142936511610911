import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';

export interface ForwardJob {
  jobName: any;
  protocolId: any;
  templateId: any;
  username: any;
  password: any;
  endDate: any;
  description: any;
}

@Injectable()
export class VehicleMonitoringService extends BaseService {

  moduleName = this.baseUrl + `/vehicles`;

  constructor(private _http: _HttpClient) {
    super();
  }

  pageQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/realTimeInfo`, query, { observe: "response" });
  }

  queryAllModelList(): Observable<any> {
    return this._http.get(`${this.moduleName}/model/dropDownListInfo`);
  }

  queryAllOrgan(): Observable<any> {
    return this._http.get('/system/organizations/dropDownListInfo');
  }

  pageQueryRealtime(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/realtime`, query, { observe: "response" });
  }

  pageQueryList(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/list`, query, { observe: "response" });
  }

  getMonitorList(query): Observable<any> {
    return this._http.get(`${this.moduleName}/monitorList`, query, { observe: "response" });
  }

  queryAllRealtime(): Observable<any> {
    return this._http.get(`${this.moduleName}/realtime/all`);
  }

  queryAllForwardData(): Observable<any> {
    return this._http.get(`${this.moduleName}/data/all`);
  }

  pageQueryUnboundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/unbound/${id}`, query, { observe: "response" });
  }

  pageQueryBoundVehicles(id, query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(`${this.moduleName}/vehicles/bound/${id}`, query, { observe: "response" });
  }

  get(id): Observable<any> {
    return this._http.get(`${this.moduleName}/${id}`)
  }

  getDictionary(code): Observable<any> {
    return this._http.get(`${this.baseUrl}/dictionary/${code}`)
  }

  create(obj: ForwardJob): Observable<any> {
    obj = this.trimFormData(obj, ["jobName", "protocolId", "templateId", "username", "password", "endDate", "description"]);
    return this._http.post(`${this.moduleName}`, obj)
  }

  update(id, obj: ForwardJob): Observable<any> {
    obj = this.trimFormData(obj, ["jobName", "protocolId", "templateId", "username", "password", "endDate", "description"]);
    return this._http.put(`${this.moduleName}/${id}`, obj);
  }

  delete(ids): Observable<any> {
    return this._http.delete(`${this.moduleName}/${ids}`);
  }

  run(id): Observable<any> {
    return this._http.get(`${this.moduleName}/run/${id}`, {});
  }

  stop(id): Observable<any> {
    return this._http.get(`${this.moduleName}/stop/${id}`, {});
  }

  bind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/bind/${id}`, vehicleIds);
  }

  unbind(id, vehicleIds): Observable<any> {
    return this._http.post(`${this.moduleName}/unbind/${id}`, vehicleIds);
  }

}
