import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  forwardRef,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { convertTil, convertVal } from '@shared/utils/util.lang';
import { rule } from '@shared/utils/util.const.ts';
import { convertDate } from '@shared/utils/util.lang.ts';


@Component({
  selector: 'extreme-value',
  templateUrl: './extreme-value.component.html',
  styleUrls: ['./extreme-value.component.less'],
})
export class ExtremeValueComponent implements OnInit, OnDestroy, OnChanges {
  jzshData: any = [
    [
      { title: '电池单体电压最高值(V)', content: '' },
      { title: '最高电压电池子系统号', content: '' },
      { title: '最高电压电池单体代号', content: '' },
    ],
    [
      { title: '电池单体电压最低值(V)', content: '' },
      { title: '最低电压电池子系统号', content: '' },
      { title: '最低电压电池单体代号', content: '' },
    ],
    [
      { title: '最高温度值(℃)', content: '' },
      { title: '最高温度子系统号', content: '' },
      { title: '最高温度探针序号', content: '' },
    ],
    [
      { title: '最低温度值(℃)', content: '' },
      { title: '最低温度子系统号', content: '' },
      { title: '最低温度探针序号', content: '' },
    ],
  ];
  @Input() vin: any;
  @Input() vdata: any;
  @Input() map: any;

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  tag = 'MDT_P_DA_TYPE';
  timer = null;

  ngOnInit() {

  }

  getContent(oriDate) {
    this.jzshData = [
      [
        { title: '电池单体电压最高值(V)', content: convertDate(oriDate.MDT_P_MAX_VOL_CEL_VLU,'MDT_P_MAX_VOL_CEL_VLU',rule) },
        { title: '最高电压电池子系统号', content: convertDate(oriDate.MDT_P_MAX_VOL_SYS_SN,'MDT_P_MAX_VOL_SYS_SN',rule) },
        { title: '最高电压电池单体代号', content: convertDate(oriDate.MDT_P_MAX_VOL_CEL_SN,'MDT_P_MAX_VOL_CEL_SN',rule) },
      ],
      [
        { title: '电池单体电压最低值(V)', content: convertDate(oriDate.MDT_P_MIN_VOL_CEL_VLU,'MDT_P_MIN_VOL_CEL_VLU',rule) },
        { title: '最低电压电池子系统号', content: convertDate(oriDate.MDT_P_MIN_VOL_SYS_SN,'MDT_P_MIN_VOL_SYS_SN',rule) },
        { title: '最低电压电池单体代号', content: convertDate(oriDate.MDT_P_MIN_VOL_CEL_SN,'MDT_P_MIN_VOL_CEL_SN',rule) },
      ],
      [
        { title: '最高温度值(℃)', content: convertDate(oriDate.MDT_P_MAX_TEM,'MDT_P_MAX_TEM',rule) },
        { title: '最高温度子系统号', content: convertDate(oriDate.MDT_P_MAX_TEM_SYS_SN,'MDT_P_MAX_TEM_SYS_SN',rule) },
        { title: '最高温度探针序号', content: convertDate(oriDate.MDT_P_MAX_TEM_NDL_SN,'MDT_P_MAX_TEM_NDL_SN',rule) },
      ],
      [
        { title: '最低温度值(℃)', content: convertDate(oriDate.MDT_P_MIN_TEM,'MDT_P_MIN_TEM',rule) },
        { title: '最低温度子系统号', content: convertDate(oriDate.MDT_P_MIN_TEM_SYS_SN,'MDT_P_MIN_TEM_SYS_SN',rule) },
        { title: '最低温度探针序号', content: convertDate(oriDate.MDT_P_MIN_TEM_NDL_SN,'MDT_P_MIN_TEM_NDL_SN',rule) },
      ],
    ]
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    // clearInterval(this.timer)
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.vdata) {
      if (this.vdata == null) {
        return;
      }
      ;
      if(this.vdata[this.tag]==null){
        this.jzshData= [
          [
            { title: '电池单体电压最高值(V)', content: '' },
            { title: '最高电压电池子系统号', content: '' },
            { title: '最高电压电池单体代号', content: '' },
          ],
          [
            { title: '电池单体电压最低值(V)', content: '' },
            { title: '最低电压电池子系统号', content: '' },
            { title: '最低电压电池单体代号', content: '' },
          ],
          [
            { title: '最高温度值(℃)', content: '' },
            { title: '最高温度子系统号', content: '' },
            { title: '最高温度探针序号', content: '' },
          ],
          [
            { title: '最低温度值(℃)', content: '' },
            { title: '最低温度子系统号', content: '' },
            { title: '最低温度探针序号', content: '' },
          ],
        ];
        return
      }
      let oriDate=this.vdata[this.tag];
      this.getContent(oriDate);
    }
    ;
  }

}
