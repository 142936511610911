import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';

@Injectable()
export class AnalyseService extends BaseService {

  constructor(private _http: _HttpClient) {
    super();
  }

  chargingDataAnalysis(query): Observable<any> {
    return this._http.post(this.baseCharging + `/connectors/statistic/data`, query, { observe: 'response' })
  }

  chargingFaultAnalysis(query): Observable<any> {
    return this._http.get(this.baseCharging + `/connectors/statistic/fault`, query, { observe: 'response' });
  }

  chargingOperationAnalysis(query): Observable<any> {
    return this._http.post(this.baseCharging + `/connectors/statistic/pile`, query, { observe: 'response' });
  }

  // 充电分析-图表
  chartChargeStatisticsQuery(query): Observable<any> {
    const startDate = query.startDate;
    const endDate = query.endDate;
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();
    query.startDate = startTimestamp;
    query.endDate = endTimestamp;
    return this._http.get(this.baseUrl + `/report/chargingAnalysis`, query, { observe: 'response' });
  }

  // 里程分析-图表
  chartMileageStatisticsQuery(query): Observable<any> {
    return this._http.get(this.baseUrl + `/report/mileageAnalysis`, query, { observe: 'response' });
  }

  // 运营趋势分析-图表
  chartOperationTendencyQuery(query): Observable<any> {
    return this._http.get(this.baseUrl + `/report/operationTrend`, query, { observe: 'response' });
  }

  // 充电汇总-分页
  pageChargeQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/chargingCollect`, query, { observe: 'response' });
  }

  // 充电汇总详情-分页
  pageChargeDetailQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/chargingDetail`, query, { observe: 'response' });
  }

  // 运行情况分析-分页
  pageOperationSituationQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/operationSituation`, query, { observe: 'response' });
  }

  // 车辆长期离线统计-分页
  pageLongTermOfflineQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/offLineVehicle`, query, { observe: 'response' });
  }

  // 车辆定位异常统计-分页
  pagePositionExceptionQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/locationExceptionVehicle`, query, { observe: 'response' });
  }

  // 车辆CAN数据异常统计-分页
  pageCanDataExceptionQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/report/canDateExceptionVehicle`, query, { observe: 'response' });
  }

  /** 数据质量核查 统计纬度 车辆
   */
  pageQueryByVehicle(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/dataQuality/pageQueryByVehicle`, query, { observe: 'response' });
  }

  /** 根据客户类型和输入 模糊查询 客户下拉选
   */
  customerNameDrop(type, customerName): Observable<any> {
    return this._http.get(this.baseUrl + `/dataQuality/customerNameDrop/${type}/${customerName}`);
  }

  //单车异常汇总
  groupByVehicleAndAlarmItem(vin, param): Observable<any> {
    return this._http.get(this.baseUrl + `/dataQuality/groupByVehicleAndAlarmItem/${vin}`, param);
  }

  //单车 日 异常汇总
  groupByVehicleAndDay(vin, param): Observable<any> {
    return this._http.get(this.baseUrl + `/dataQuality/groupByVehicleAndDay/${vin}`, param);
  }

  //单车 日 异常项 异常汇总
  groupByVehicleAndDayAndItem(vin, day): Observable<any> {
    return this._http.get(this.baseUrl + `/dataQuality/groupByVehicleAndDayAndItem/${vin}/${day}`);
  }


  /** 单车异常汇总 报文
   */
  boListByVehicle(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/dataQuality/boListByVehicle`, query, { observe: 'response' });
  }

  /** 单车 日异常统计  报文
   */
  boListByVehicleAndDayAndItem(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/dataQuality/boListByVehicleAndDayAndItem`, query, { observe: 'response' });
  }

  /** 数据质量核查 统计纬度 异常项
   */
  pageQueryByItem(query): Observable<any> {
    return this._http.get(this.baseUrl + `/dataQuality/pageQueryByItem`, query, { observe: 'response' });
  }

  /** 据异常项 分页查询 车辆信息
   */
  pageQueryVehiclesByValidityCode(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/dataQuality/pageQueryVehiclesByValidityCode`, query, { observe: 'response' });
  }


  /** 据异常项 分页查询 博文
   */
  boListByUseItem(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseUrl + `/dataQuality/boListByUseItem`, query, { observe: 'response' });
  }

  /**
   * 充电运营数据统计 充电站累计电量 图表
   */
  chartStationQuery(query) {
    return this._http.post(this.baseCharging + `/operationStatistics/stationChart`, query, { observe: 'response' });
  }

  /**
   * 充电运营数据统计 运营商累计电量 图表
   */
  chartOperatorQuery(query,) {
    return this._http.post(this.baseCharging + `/operationStatistics/operatorChart`, query, { observe: 'response' });
  }

  /**
   * 充电运营数据统计 运营商电量统计 图表
   */
  chartOperatorGroupQuery(query) {
    return this._http.post(this.baseCharging + `/operationStatistics/operatorChartGruopDate`, query, { observe: 'response' });
  }

  /**
   * 故障统计 列表
   */
  pageFaultQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseCharging + `/faultStatistics`, query, { observe: 'response' });
  }

  /**
   * 故障统计 图表
   */
  chartFaultQuery(query, pageIndex, pageSize): Observable<any> {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.post(this.baseCharging + `/faultStatistics/chart`, query, { observe: 'response' });
  }
}
