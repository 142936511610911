import {Component, Input, OnInit, OnChanges} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-mix-line-bar-timeline',
    templateUrl: './mix-line-bar-timeline.component.html',
    styleUrls: ['./mix-line-bar-timeline.component.less']
})
export class MixLineBarTimelineComponent implements OnChanges {

    @Input() serie: any = [];
    @Input() height: number;

    option: any;

    constructor() {
    }

    ngOnChanges() {
        this.initHistogram();
    }

    initHistogram() {
        let tempOption = {
            baseOption: {
                color:this.serie.color,
                timeline: {
                    axisType: 'category',
                    show: true,
                    autoPlay: false,
                    playInterval: 1000,
                    data: this.serie.timeline.data,
                    left: '10%',
                    right: '10%',
                    bottom: 20,
                    height: 30,
                    symbolSize: 9,
                    // currentIndex: this.serie.years.length - 1,
                    itemStyle: {
                        emphasis: {
                            color: '#4FC2F6'
                        }
                    },
                    lineStyle: {
                        color: '#919297',
                        type: 'dashed',
                        width: 1
                    },
                    label: {
                        interval:0,
                        normal: {
                            textStyle: {
                                fontSize: 8,
                                color: '#919297',
                            },
                            position: 'bottom'
                        },
                        emphasis: {
                            textStyle: {
                                color: '#919297',
                            }
                        }
                    },
                    controlStyle: {
                        show: false,
                    },
                    checkpointStyle: {
                        color: '#4FC2F6',
                        borderWidth: 0
                    },
                },
                grid: {containLabel: true},
                xAxis: [{
                    data:this.serie.xAxis,
                    type: 'category',
                    name: '',
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#3C5076',
                        }
                    },
                    axisLabel: {
                        color: '#F9FAFA',
                        fontSize: 10,
                        interval: 0
                    }
                }],
                yAxis: [
                    {
                        type: 'value',
                        name: this.serie.yAxis[0].name,
                        min: this.serie.yAxis[0].min,
                        max: this.serie.yAxis[0].max,
                        interval: this.serie.yAxis[0].interval,
                        axisLabel: {
                            color: '#F9FAFA',
                            fontSize: 10,
                            formatter: this.serie.yAxis[0].axisLabel?this.serie.yAxis[0].axisLabel.formatter:null
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#3C5076',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#3C5076',
                                width: 1
                            }
                        },
                        nameTextStyle: {
                            color:'#fff'
                        }
                    },
                    {
                        type: 'value',
                        name: this.serie.yAxis[1].name,
                        min: this.serie.yAxis[1].min,
                        max: this.serie.yAxis[1].max,
                        interval: this.serie.yAxis[1].interval,
                        axisLabel: {
                            color: '#F9FAFA',
                            fontSize: 10,
                            formatter: this.serie.yAxis[1].axisLabel?this.serie.yAxis[1].axisLabel.formatter:null
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#3C5076',
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#3C5076',
                                width: 1
                            }
                        },
                        nameTextStyle: {
                            color:'#fff'
                        }
                    }
                ],
                series: [
                    {
                        type: 'bar',
                        barWidth: '35%',
                        itemStyle:{
                            normal: {
                                color: '#54C4F5',
                            }
                        }
                    },
                    {
                        type: 'line',
                        itemStyle:{
                            normal: {
                                color: '#28a4f5',
                            }
                        }
                    }
                ],
                tooltip: {}
            },
            options: this.serie.options
        };
        this.option = _.cloneDeep(tempOption);
    }

}
