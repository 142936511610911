import {
  OnInit,
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  HostListener,
  Input,
  Output,
  OnDestroy,
} from '@angular/core';
declare const echarts: any;

@Directive({
  selector: '[ng2-echarts]',
  exportAs: 'NG2ECharts',
})
export class EchartsDirective implements OnInit, OnDestroy {

  @Output() echartClicked: EventEmitter<any> = new EventEmitter();
  @Output() echartLoaded: EventEmitter<any> = new EventEmitter();
  @Output() echartDblClicked: EventEmitter<any> = new EventEmitter();
  hostElement: ElementRef;
  pChart: any;

  constructor(
    ele: ElementRef,
    private zone: NgZone,
  ) {
    this.hostElement = ele;
  }

  ngOnInit() {
  }

  @Input('repaint') set repaint(value) {
    if ('true' == value) {
      setTimeout(() => {
        if (this.pChart) {
          this.pChart.resize();
        }
      }, 200);
    }
  }

  @Input('data-option') set realtime(opt: any) {
    if (!opt) {
      return;
    } else {
      if (this.pChart) {
        this.pChart.setOption(opt);
      }
    }
  }

  @Input('ng2-echarts') set options(opt: any) {
    if (!opt) {

      return;
    }
    if (opt) {
      if (this.pChart) {
        this.pChart.dispose();
      }
      this.zone.runOutsideAngular(() => {
        this.pChart = echarts.init(this.hostElement.nativeElement);
        this.pChart.setOption(opt);
      });
      this.echartLoaded.emit(this.pChart);
      this.pChart.on('click', (params) => {
        this.echartClicked.emit(params);
      });
      this.pChart.on('dblclick', (params) => {
        this.echartDblClicked.emit(params);
      });
    } else {
    }
  }

  public get chart(): any {
    return this.pChart;
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.pChart.resize();
  }

  ngOnDestroy() {
    if (this.pChart) {
      this.pChart.dispose();
    }
  }

}
