import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '@service/base.service';




@Injectable()
export class OrgManageService extends BaseService{
    moduleName = '/system/organizations';

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    constructor(private http: HttpClient) {
      super();
    }

   /**
   * 组织机构下拉框
   */
  orgOption(): Observable<any> {
    return this.http.get(`/system/organizations/dropDownListInfo`);
  }

   /**
   * 组织机构下拉框
   */
  operatorOption(): Observable<any> {
    return this.http.get(`/charging/operators/names`);
  }

 
}
