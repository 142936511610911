import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  EventEmitter,
  SimpleChanges,
  forwardRef,
  ViewChild,
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { BMonitoringService } from '@service';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'historical-data-realtime',
  templateUrl: './historical-data-realtime.component.html',
  styleUrls: ['./historical-data-realtime.component.less'],
})
export class HistoricalDataRealtimeComponent implements OnInit, OnDestroy {
  context: { map: any, temp: any ,arrIndex: any};

  constructor(
    private http: _HttpClient,
    private bMonitoringService: BMonitoringService,
    private cd: ChangeDetectorRef,
  ) {
  }

  index=0;
  lastIndex=0;
  data;
  map;
  vin;
  ngOnInit() {
    this.index = this.context.arrIndex;
    this.lastIndex=this.context.temp.length-1;
    this.data=JSON.parse(this.context.temp[this.index].body);
    this.map=this.context.map;
  }


  ngOnDestroy() {
  }

  pre(){
    this.index--;
    if(this.index<0){
      this.index=0;
    }
    this.data=_.cloneDeep(JSON.parse(this.context.temp[this.index].body));
  }
  next(){
    console.log(this.data)
    this.index++;
    if(this.index>this.lastIndex){
      this.index=this.lastIndex;
    }
    this.data=_.cloneDeep(JSON.parse(this.context.temp[this.index].body));
  }

}
