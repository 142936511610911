import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { _HttpClient } from '@delon/theme';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AlarmPileInfoService extends BaseService {

  constructor(private _http: _HttpClient) {
    super();
  }

  // 充电桩 实时报警-列表
  pageQueryRealtime(query, pageIndex, pageSize) {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseCharging + `/connectorWarn/realtime`, query, { observe: 'response' });
  }

  // 充电桩 历史报警-列表
  pageQueryHistory(query, pageIndex, pageSize) {
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseCharging + `/connectorWarn/history`, query, { observe: 'response' });
  }

  // 充电桩 报警策略-列表
  pageQueryStrategy(pageIndex, pageSize): Observable<any> {
    let query = { page: null, limit: null };
    query.page = pageIndex;
    query.limit = pageSize;
    return this._http.get(this.baseCharging + `/warnStrategyInfo`, query, { observe: 'response' });
  }

  // 充电桩 报警策略-新增
  addStrategyInfo(context): Observable<any> {
    return this._http.post(this.baseCharging + `/warnStrategyInfo`, context);
  }

  // 充电桩 报警策略-删除
  deleteStrategyInfo(ids): Observable<any> {
    return this._http.delete(this.baseCharging + `/warnStrategyInfo`, { 'ids': ids });
  }

  // 充电桩 报警策略-编辑
  editStrategyInfo(context): Observable<any> {
    return this._http.put(this.baseCharging + `/warnStrategyInfo/${context.id}`, context, { observe: 'response' });
  }

  // 运营商名称
  getOperatorsNames() {
    return this._http.get(this.baseCharging + `/operators/names`);
  }

  // 充电站名称
  getStationsNames() {
    return this._http.get(this.baseCharging + `/stations/names`);
  }

  // 充电桩编码
  getEquipmentsIds() {
    return this._http.get(this.baseCharging + `/equipments/equipmentIds`);
  }

  // 充电枪编码
  getConnectorsNames() {
    return this._http.get(this.baseCharging + `/connectors/names`);
  }

  // 诊断信息
  getDiagnoseInfo(id) {
    return this._http.get(this.baseCharging + `/connectorWarn/${id}`);
  }

  // 诊断列表
  getDiagnoseList(context, pageIndex, pageSize) {
    context.page = pageIndex;
    context.limit = pageSize;
    return this._http.get(this.baseCharging + `/connectorWarn/diagnose`, context,{ observe: 'response' });
  }
}
