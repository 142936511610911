import {SettingsService, _HttpClient} from '@delon/theme';
import {PlatformLocation} from '@angular/common';
import {Component, OnDestroy, Inject, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import { Md5 } from "ts-md5";
import { rsaStr} from '@shared/utils/util.lang';

import {
  SocialService,
  SocialOpenType,
  ITokenService,
  DA_SERVICE_TOKEN,
} from '@delon/auth';
import {ReuseTabService} from '@delon/abc';
import {environment} from '@env/environment';
import {StartupService} from '@core';
import {I18NService} from '../../../../../projects/osp/src/app/core';

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [SocialService],
})
export class UserLoginComponent implements OnDestroy {
  form: FormGroup;
  error = '';
  type = 0;
  context = {
    username: '',
    password: '',
    timestamp: null,
    patchca: ''
  }
  patchcaUrl = null;

  constructor(private sanitizer: DomSanitizer,
              modalSrv: NzModalService,
              private router: Router,
              private location: PlatformLocation,
              private settingsService: SettingsService,
              @Optional()
              @Inject(ReuseTabService)
              private reuseTabService: ReuseTabService,
              @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
              private startupSrv: StartupService,
              public http: _HttpClient,
              public msg: NzMessageService,
              private _i18n: I18NService,) {
    modalSrv.closeAll();
  }

  ngOnInit() {

  }

  // #region get captcha

  usernameChange(e) {
    this.refreshPatchca();
  }

  refreshPatchca() {
    this.patchcaUrl = null;
    let username = this.context.username;
    if (username) {
      this.context.timestamp = Date.now();
      let url = `/auth/patchca/${username}/${this.context.timestamp}?_allow_anonymous=true`;
      this.http.get(url, null, {responseType: "text"}).subscribe(data => {
        // console.log(data);
        this.patchcaUrl = this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64," + data);
      })
      // this.patchcaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`./api/auth/patchca/${username}`);
    }
  }

  count = 0;
  interval$: any;

  getCaptcha() {
    // if (this.mobile.invalid) {
    //   this.mobile.markAsDirty({ onlySelf: true });
    //   this.mobile.updateValueAndValidity({ onlySelf: true });
    //   return;
    // }
    // this.count = 59;
    // this.interval$ = setInterval(() => {
    //   this.count -= 1;
    //   if (this.count <= 0) clearInterval(this.interval$);
    // }, 1000);
  }

  // #endregion

  submit() {
    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    this.http
      .post('/auth/jwt?_allow_anonymous=true', {
        type: this.type,
        username: this.context.username,
        // password: Md5.hashStr(this.context.password).toString(),
        password: rsaStr(this.context.password),
        patchca: this.context.patchca,
        timestamp: this.context.timestamp
      })
      .subscribe((res: any) => {
        // 清空路由复用信息
        this.reuseTabService.clear();
        // 设置用户Token信息
        this.tokenService.set({token: res.accessToken});
        // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        this.startupSrv.load(true).then(() => {
          let url = '/dashboard';
          this.router.navigateByUrl(url);
        });
      }, error => {
        if (error.error.errorCode) {
          this.msg.error(this._i18n.fanyi(error.error.errorCode));
        } else {
          this.msg.error(this._i18n.fanyi(error.error));
        }
        this.refreshPatchca();
      });
  }


  ngOnDestroy(): void {
    if (this.interval$) clearInterval(this.interval$);
  }
}
