import {NgModule} from '@angular/core';

import { EchartsDirective } from './echarts.directive';

@NgModule({
    imports: [],
    declarations: [
        EchartsDirective
    ],
    exports: [
        EchartsDirective
    ]
})
export class EChartsModule {}